import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useDispatch } from "react-redux";
import axios from "axios";
import { loadAllUserPosts } from "../../../Services/Actions/SocialFeed/getAllUserPostsAction";
import { Snackbar } from "@mui/material";
import Throught from "../AllPostTypes/Throught";
import EventsPost from "../AllPostTypes/EventsPost";
import Recommendation from "../AllPostTypes/Recommendation";
import AlertPost from "../AllPostTypes/AlertPost";
import Poll from "../AllPostTypes/Poll";
import CreationMainSection from "../CreationMainSection";
import CreationMainSectionEdit from "../CreationMainSectionEdit";
import { useSelector } from "react-redux";
const PostEditModal = ({ 
  postType, 
  openModal, 
  DeleteModalOpen, 
  feelingCategory, 
  setModal, 
  modal, 
  setSelectedImages, 
  selectedImages, 
  setEditModal, 
  toggle, 
  setPostType, 
  activeTab 
}) => {
  useEffect(() => { }, [activeTab])
  // console.log("in postEditModal ", postType)

  let state
  // let ptype=postType.postType
  // if(ptype==="recomendation"){
  //     state=7.6
  //     activeTab=7.6
  //   }else if(ptype==="thought"){
  //     state=7.1
  //     activeTab=7.1
  //   }else if(ptype==="text"){
  //     state=0
  //     activeTab=0
  //   }else if(ptype==="media"){
  //     state=0.1
  //     activeTab=0.1
  //   }else if(ptype==="event"){
  //      state=7.3
  //     activeTab=7.3
  //   }else if(ptype==="poll"){
  //     state=7.8
  //     activeTab=7.8
  //   }else if(ptype==="feeling"){
  //     state=1
  //     activeTab=1
  //   }else if(ptype==="alert"){
  //     state=7.7
  //     activeTab=7.7
  //   }

  useEffect(() => { setModal(true) })
  const dispatch = useDispatch();
  const [showUndoNotification, setShowUndoNotification] = useState(false);
  const [reqPostId, setReqPostId] = useState("");
  const [ feelingAndActvityTabActive ,setFeelingAndActvityTabActive]=useState(false)




  const snackbarStyle = {
    backgroundColor: "#000000",
    color: "white",
    textAlign: "center",
    width: "200px",
    borderRadius: "4px",
  };

  const buttonStyle = {
    backgroundColor: "#81C14B",
    color: "white",
    marginLeft: "8px",
    padding: "5px",
    borderRadius: "4px",
    border: "none",
  };

  return (
    <>
        <CreationMainSection
          postType={postType.postType}
          activeTab={activeTab}
          modal={modal}
          states={state} 
          feelingCategories={feelingCategory} 
          setModal={setModal}
          selectedImages={selectedImages} 
          setSelectedImages={setSelectedImages} 
          setEditModal={setEditModal} 
          toggle={toggle} 
          setPostType={setPostType} 
          postEditValue={postType} 
          createType ='EditPost'
          feelingAndActvityTabActive ={feelingAndActvityTabActive}
          setFeelingAndActvityTabActive={setFeelingAndActvityTabActive}
          postId = {postType.postId}
        />
      
    </>
  );
};

export default PostEditModal;
