import { combineReducers } from "redux";
// Social Feed
import getAllUserPosts from "../SocialFeed/getAllUserPosts";
import getAllPostsByUserId from "../SocialFeed/getAllPostsByUserId";
import uploadFileReducer from "../SocialFeed/uploadFileReducer";
import getAllReactionsReducer from "../SocialFeed/getAllReactionsReducer";
import getUserProfileByUserIdReducer from "../UserProfile/getUserProfileByUserIdReducer";
import getArticleCategoryReducer from "../SocialFeed/getArticleCategoryReducer";
import postSavedReducer from "../SocialFeed/postSavedReducer";
import getUserSubModulesReducer from "../UserProfile/getUsersSubModulesReducer";
import getEventCategoryReducer from "../SocialFeed/getEventCategoryReducer";
import getAlertDataReducer from "../SocialFeed/getAlertDataReducer";
// import getColorsReducer from '../SocialFeed/getColorsReducer'
// import getForColorsReducer from '../SocialFeed/getColorsReducer'
import getAllCommentsReducer from "../SocialFeed/getAllCommentsReducer";
import AllConnectionsReducer from "../SocialFeed/getConnectionListReducer";
// bizPage
import bizCategoryReducer from "../BizPage/bizCategoryReducer";
import bizSubCategoryReducer from "../BizPage/bizSubCategoryReducer";
import bizPageReducer from "../BizPage/bizPageReducer";

// USER PROFILE
import getAllInterestsReducer from "../UserProfile/getAllInterestsReducer";
import getAllSportsReducer from "../UserProfile/getAllSportsReducer";
import suggestedUsersReducer from "../UserProfile/suggestedUsersReducer";
import userFollowingRequestsReducer from "../UserProfile/userFollowingRequestsReducer";
import UserShotz from "../UserProfile/getAllUsersShotzReducers";
import {getAllPollReducer} from "../UserProfile/getAllPollReducer";
import {updateIsPrimaryReducer} from "../UserProfile/updateUserByUserIdReducer";

import {
  getAllUserHobbiesReducer,
  getAllAvailableHobbiesReducer,
  postAllUserHobbiesReducer,
  getAllAdminInterestReducer,
  getAllUserInterestReducer,
  postAllUserInterestReducer,
  getAllAdminSportsReducer,
  getAllUserSportsReducer,
  postAllUserSportsReducer,
  getAllUserMoviesReducer,
  getAllUserMusicReducer,
  getAllUserBookReducer,
  getAllUserBizPageReducer,
  getAllSchoolPageReducer,
  addAllProfessionReducer,
  addSchoolCollegeReducer,
  addQualificationReducer,
  getQualificationReducer,
  addUserEducationReducer,
  getAllPostsByUserReducer,
  postHideUnHideReducer,
  getUserFollowerReducer,
  postReShareReducer,
  getUserConnectionReducer,
  getEventsReducer,
  getTextPostsReducer,
  getImagePostsReducer,
  getRecommendationPostReducer,
  getMarketPlaceProductsReducer,
  getAllUserOwnPinnedBizPageReducer,
  getAllUserOwnFollowedBizPageReducer,
  getAllUserOwnPinnedGroupPageReducer,
  getPeopleListReducer,
  getCategoryListReducer,
  getSubCategoryListReducer,
  postShowReducer,
  deletePostReducer,
  hideAllPostsFromTimelineReducer,
  getAllCommentsByPostIdReducer,
  postAddAllCommentsByPostIdReducer,
  getAllLikesByPostIdReducer,
  getAllSharesByPostIdReducer,
  inviteFriendsToEventReducer,
  shareShotzReducer,
  turnOnOffNotificationTextPostsReducer,
  createBizPageReducer,
  bizPageNotificationReducer,
  bizPinnedReducer,
  addBizPageCatalogueReducer,
  getBizPageCatalogueReducer,
  getBizPageReviewReducer,
  getBizPageRatingSummaryReducer,
  addBizPageRatingAndReviewsReducer,
  addBizPageQuestionReducer,
  getAllQuestionAndAnswerReducer,
  getAboutUsReducer,
  postPinOrUnpinBizPageReducer,
  deleteBizPageReducer,
  instantBizPageMessageReducer,
  getInstantBizPageMessageReducer,
  getBizPageDetailsByIdReducer,
  postNotificationSettingsReducer,
  getBizPageReducer,
  removePageRoleFromBizPageReducer,
  getBizPageFollowerListsReducer,
  setAssignRoleReducer,
  getFollowedByBizPageUserReducer,
  blockBizPageUserReducer,
  getBlockedBizPageUserReducer,
  getBizPageHistoryReducer,
} from "../userProfileReducer/getAllUserProfileMainPageReducer";

// GROUP
import getAllGroupCategory from "../getAllGroupCategory";
import getAllUserGroups from "../getAllUserGroups";
import getAllGroupsByUserId from "../getAllGroupsByUserId";
import getGroupAdministraterReducer from "../getGroupAdministraterReducer";

import {
  getAllGroupMainPageReducer,
  getAllJoinedGroupReducer,
  getAllGroupInvitationReducer,
  getAllGroupSuggestedGroupReducer,
  getAllReportOptionFromGroupReducer,
  postReportOptionGroupReducer,
  getAllGroupByIdReducer,
  createGroupPostReducer,
  getGroupTrendingHashTagReducer,
  getAllGroupAdminAndMembersReducer,
  getAllAboutGroupReducer,
  getAllGroupMembersReducer,
  postPinToGroupReducer,
  getAllBlockedUsersByGroupReducer,
  getPinFromGroupReducer,
  getJoinedRequestFromGroupReducer,
  getPostRequestFromGroupReducer,
  getAllCategoryFromGroupReducer,
  getInvitedPeopleToModalReducer,
  postInvitationToOwnGroupReducer,
  postUnFollowFromJoinedGroupReducer,
  postFollowGroupFromGroupInvitationReducer,
  postDeclineGroupFromGroupInvitationReducer,
  postCreateNewGroupReducer,
  postToJoinSuggestedGroupReducer,
  deleteGroupByIdReducer,
  deleteGroupByUserReducer,
  groupSettingsAllInOneReducer,
  groupReviewPostReducer,
  groupReviewMemberReducer,
  groupSharedToStoriesReducer,
  updateGroupPostReducer,
  getAllGeneralNotificationReducer,
  socioMeeNotificationSettingsReducer,
  groupActivityHistoryReducer,
  postGroupRolesReducer,
  removeGroupRolesReducer,
  unBlockGroupUserReducer,
  setNotificationToGroupSettingsReducer,
  setNotificationToGroupSettingsTabReducer,
} from "../../Reducers/GroupReducer/getAllGroupMainPageReducer";

// COMMONE
import TrendingHashtagReducer from "../Common/TrendingHashtagReducer";
import TaggedPeopleReducer from "../Common/TaggedPeopleReducer";
import GetUserFollowersReducer from "../Common/GetUserFollowersReducer";
import GetUserFollowingReducer from "../Common/GetUserFollowingReducer";
import GetUserConnectionReducer from "../Common/GetUserConnectionReducer";

// AD MANGER
import getAllAdByStatusReducer from "../AdManager/getAllAdByStatusReducer";
import getTypeReducer from "../AdManager/getTypeReducer";
import {
  getColorsReducer,
  getForColorsReducer,
} from "../SocialFeed/getColorsReducer";

//events
import { getUserEventsReducer } from "../../Reducers/EventsReducer/getAllEventsMainPageReducer";
import getUpcommingCategoryReducer from "../../Reducers/EventsReducer/getUpcommingEventsReducer";
import { getUserOnGoingEventsReducer } from "../../Reducers/EventsReducer/getOnGoingEventReducer";
import { createEventsReducer } from "../../Reducers/EventsReducer/createEventPostReducer";
import { getUserIntrestedEventsReducer } from "../../Reducers/EventsReducer/getAllIntrestedEventReducer";
import { inviteFriendsReducer } from "../../Reducers/EventsReducer/inviteFriendsReducer";
import GetFeelingCategoryReducer from "../Common/GetFeelingCategoryReducer";
import GetFeelingReducer from "../Common/GetFeelingReducer";

//connection
import GetUserSentRequestReducer from "../Connection/GetUserSentRequestReducer";
import GetApproveRequestReducer from "../Connection/GetApproveRequestReducer";
import GetSociomateReducer from "../Connection/GetSociomateReducer";
import GetUserBlockUnblockReducer from "../Connection/GetUserBlockUnblockReducer";
import GetDisapproveRequestReducer from "../Connection/GetDisapproveRequestReducer";
import GetUserReportReducer from "../Connection/GetUserReportReducer";
import GetRemoveFollowerReducer from "../Connection/GetRemoveFollowerReducer";
import GetUserReportOptionReducer from "../Connection/GetUserReportOptionReducer";
import GetWithdrawRequestReducer from "../Connection/GetWithdrawRequestReducer";
import CreateCategoryReducer from "../Connection/CreateCategoryReducer";
import CreateConnectionListReducer from "../Connection/CreateConnectionListReducer";
import getRemainingSociomateListReducer from "../Connection/GetRemainingSociomateListReducer";
import UserGroupReducer from "../SocialFeed/getUserGroupReducer";
import GetFeedReportReducer from "../SocialFeed/GetFeedReportReducer";
import GetPostReportReducer from "../SocialFeed/FeedReportClickReducer";
import mainSearchReducer from "../mainSearchReducer";

//story
import GetAllStoriesReducer from "../Story/GetAllStoriesReducer";
import PostLikesReducer from "../SocialFeed/getAllPostLikesReducer";

//marketPlace
import {
  marketPlaceReducer,
  singleMarketPlaceProductReducer,
  getAllProductCategoryReducer,
  getSuggestedProductListReducer,
  getAllCategoryAndSubcategoryHaveDataReducer,
} from '../MarketPlace/getAllProductsReducer'

const rootReducer = combineReducers({
  // Social Feed
  getAllUserPostsData: getAllUserPosts,
  getAllPostsByUserIdData: getAllPostsByUserId,
  uploadFileData: uploadFileReducer,
  getAllReactionsData: getAllReactionsReducer,
  getUserProfileByUserIdData: getUserProfileByUserIdReducer,
  getArticleCategoryData: getArticleCategoryReducer,
  postSavedData: postSavedReducer,
  getUserSubModulesData: getUserSubModulesReducer,
  getEventCategoryData: getEventCategoryReducer,
  getAlertData: getAlertDataReducer,
  getColorsData: getColorsReducer,
  getAllCommentsData: getAllCommentsReducer,
  getForColors: getForColorsReducer,
  getAllConnectionsData: AllConnectionsReducer,
  getUserGroupsData: UserGroupReducer,
  getUserReportOptionsData: GetFeedReportReducer,
  getPostReportData: GetPostReportReducer,
  getPostLikesData: PostLikesReducer,

  // Biz Page
  bizCategoryData: bizCategoryReducer,
  bizSubCategoryData: bizSubCategoryReducer,
  bizPageData: bizPageReducer,

  // USER PROFILE
  isPrimarySuccess:updateIsPrimaryReducer,
  getAllInterestsData: getAllInterestsReducer,
  getAllSportsData: getAllSportsReducer,
  suggestedUsersData: suggestedUsersReducer,
  userFollowingRequestsData: userFollowingRequestsReducer,
  userShotzData: UserShotz,
  allPollData:getAllPollReducer,

  getAllUserHobbiesData: getAllUserHobbiesReducer,
  getAllAvailableHobbiesData: getAllAvailableHobbiesReducer,
  postAllUserHobbiesData: postAllUserHobbiesReducer,
  getAllAdminInterestData: getAllAdminInterestReducer,
  getAllUserInterestData: getAllUserInterestReducer,
  postAllUserInterestData: postAllUserInterestReducer,
  getAllAdminSportsData: getAllAdminSportsReducer,
  getAllUserSportsData: getAllUserSportsReducer,
  postAllUserSportsData: postAllUserSportsReducer,
  getAllUserMoviesData: getAllUserMoviesReducer,
  getAllUserMusicData: getAllUserMusicReducer,
  getAllUserBookData: getAllUserBookReducer,
  getAllUserBizPageData: getAllUserBizPageReducer,
  getAllSchoolPageData: getAllSchoolPageReducer,
  addAllProfessionData: addAllProfessionReducer,
  addSchoolCollegeData: addSchoolCollegeReducer,
  addQualificationData: addQualificationReducer,
  getQualificationData: getQualificationReducer,
  addUserEducationData: addUserEducationReducer,
  getAllPostsByUserData: getAllPostsByUserReducer,
  postHideUnHideData: postHideUnHideReducer,
  postShowData: postShowReducer,
  deletePostData: deletePostReducer,
  getUserFollowerData: getUserFollowerReducer,
  postReShareData: postReShareReducer,
  getUserConnectionData: getUserConnectionReducer,
  getEventsData: getEventsReducer,
  getTextPostsData: getTextPostsReducer,
  getImagePostsData:getImagePostsReducer,
  getRecommendPostsData: getRecommendationPostReducer,
  getMarketPlaceProductsData: getMarketPlaceProductsReducer,
  hideAllPostsFromTimelineData: hideAllPostsFromTimelineReducer,
  getAllCommentsByPostIdData: getAllCommentsByPostIdReducer,
  postAddAllCommentsByPostIdData: postAddAllCommentsByPostIdReducer,
  getAllLikesByPostIdData: getAllLikesByPostIdReducer,
  getAllSharesByPostIdData:getAllSharesByPostIdReducer,
  inviteFriendsToEventData: inviteFriendsToEventReducer,
  getAllUserOwnPinnedBizPageData: getAllUserOwnPinnedBizPageReducer,
  getAllUserOwnFollowedBizPageData: getAllUserOwnFollowedBizPageReducer,
  getPeopleListData: getPeopleListReducer,
  getCategoryListData: getCategoryListReducer,
  getSubCategoryListData: getSubCategoryListReducer,
  turnOnOffNotificationTextPostsData: turnOnOffNotificationTextPostsReducer,
  createBizPageData: createBizPageReducer,
  bizPageNotificationData: bizPageNotificationReducer,
  bizPinnedData: bizPinnedReducer,
  addBizPageCatalogueData: addBizPageCatalogueReducer,
  getBizPageCatalogueData: getBizPageCatalogueReducer,
  getBizPageReviewData: getBizPageReviewReducer,
  getBizPageRatingSummaryData: getBizPageRatingSummaryReducer,
  addBizPageRatingAndReviewsData: addBizPageRatingAndReviewsReducer,
  addBizPageQuestionData: addBizPageQuestionReducer,
  getAllQuestionAndAnswerData: getAllQuestionAndAnswerReducer,
  getAboutUsData: getAboutUsReducer,
  postPinOrUnpinBizPageData: postPinOrUnpinBizPageReducer,
  deleteBizPageData: deleteBizPageReducer,
  instantBizPageMessageData: instantBizPageMessageReducer,
  getInstantBizPageMessageData: getInstantBizPageMessageReducer,
  getBizPageDetailsByIdData: getBizPageDetailsByIdReducer,
  postNotificationSettingsData: postNotificationSettingsReducer,
  getBizPageData: getBizPageReducer,
  removePageRoleFromBizPageData: removePageRoleFromBizPageReducer,
  getBizPageFollowerListsData: getBizPageFollowerListsReducer,
  setAssignRoleData: setAssignRoleReducer,
  getFollowedByBizPageUserData: getFollowedByBizPageUserReducer,
  blockBizPageUserData: blockBizPageUserReducer,
  getBlockedBizPageUserData: getBlockedBizPageUserReducer,
  getBizPageHistoryData: getBizPageHistoryReducer,

  //Events
  getUserEventsData: getUserEventsReducer,
  getUserOnGoingEventsData: getUserOnGoingEventsReducer,
  getUserIntrestedEventsData: getUserIntrestedEventsReducer,
  getUpcommingCategoryData: getUpcommingCategoryReducer,
  createEventsData: createEventsReducer,
  inviteFriendsData: inviteFriendsReducer,

  // GROUP
  getAllUserGroupsData: getAllUserGroups,
  getAllGroupsByUserData: getAllGroupsByUserId,
  getAllGroupCategoryData: getAllGroupCategory,
  getGroupAdministraterData: getGroupAdministraterReducer,
  getAllGroupMainPageData: getAllGroupMainPageReducer,
  getAllJoinedGroupData: getAllJoinedGroupReducer,
  getAllGroupInvitationData: getAllGroupInvitationReducer,
  getAllGroupSuggestedGroupData: getAllGroupSuggestedGroupReducer,
  getAllReportOptionFromGroupData: getAllReportOptionFromGroupReducer,
  postReportOptionGroupData: postReportOptionGroupReducer,
  getAllGroupByIdData: getAllGroupByIdReducer,
  createGroupPostData: createGroupPostReducer,
  getGroupTrendingHashTagData: getGroupTrendingHashTagReducer,
  getAllGroupAdminAndMembersData: getAllGroupAdminAndMembersReducer,
  getAllAboutGroupData: getAllAboutGroupReducer,
  getAllGroupMembersData: getAllGroupMembersReducer,
  postPinToGroupData: postPinToGroupReducer,
  getAllBlockedUsersByGroupData: getAllBlockedUsersByGroupReducer,
  getPinFromGroupData: getPinFromGroupReducer,
  getJoinedRequestFromGroupData: getJoinedRequestFromGroupReducer,
  getPostRequestFromGroupData: getPostRequestFromGroupReducer,
  getAllCategoryFromGroupData: getAllCategoryFromGroupReducer,
  getInvitedPeopleToModalData: getInvitedPeopleToModalReducer,
  postInvitationToOwnGroupData: postInvitationToOwnGroupReducer,
  postUnFollowFromJoinedGroupData: postUnFollowFromJoinedGroupReducer,
  postFollowGroupFromGroupInvitationData:
    postFollowGroupFromGroupInvitationReducer,
  postDeclineGroupFromGroupInvitationData:
    postDeclineGroupFromGroupInvitationReducer,
  postCreateNewGroupData: postCreateNewGroupReducer,
  postToJoinSuggestedGroupData: postToJoinSuggestedGroupReducer,
  deleteGroupByIdData: deleteGroupByIdReducer,
  deleteGroupByUserData: deleteGroupByUserReducer,
  groupSettingsAllInOneData: groupSettingsAllInOneReducer,
  groupReviewPostReducerData: groupReviewPostReducer,
  groupReviewMemberData: groupReviewMemberReducer,
  groupSharedToStoriesData: groupSharedToStoriesReducer,
  updateGroupPostData: updateGroupPostReducer,
  getAllGeneralNotificationData: getAllGeneralNotificationReducer,
  socioMeeNotificationSettingsData: socioMeeNotificationSettingsReducer,
  groupActivityHistoryData: groupActivityHistoryReducer,
  postGroupRolesData: postGroupRolesReducer,
  removeGroupRolesData: removeGroupRolesReducer,
  unBlockGroupUserData: unBlockGroupUserReducer,
  setNotificationToGroupSettingsData: setNotificationToGroupSettingsReducer,
  setNotificationToGroupSettingsTabData:
    setNotificationToGroupSettingsTabReducer,

  //  COMMON
  TrendingHashtagData: TrendingHashtagReducer,
  TaggedPeopleData: TaggedPeopleReducer,
  FeelingCategoryData: GetFeelingCategoryReducer,
  FeelingData: GetFeelingReducer,
  GetUserFollowersData: GetUserFollowersReducer,
  GetUserFollowingData: GetUserFollowingReducer,
  GetUserConnectionData: GetUserConnectionReducer,

  //  Connection

  userSentRequestsData: GetUserSentRequestReducer,
  userApproveRequestData: GetApproveRequestReducer,
  userDisapproveRequestData: GetDisapproveRequestReducer,
  userSociomateData: GetSociomateReducer,
  userBlockUnblockData: GetUserBlockUnblockReducer,
  userReportData: GetUserReportReducer,
  userRemoveData: GetRemoveFollowerReducer,
  userReportOptionData: GetUserReportOptionReducer,
  userWithdrawRequest: GetWithdrawRequestReducer,
  createCategoryData: CreateCategoryReducer,
  connectionListData: CreateConnectionListReducer,
  remianingSociomateData:getRemainingSociomateListReducer,

  // AD MANAGER
  getAllAdByStatusData: getAllAdByStatusReducer,
  getTypeData: getTypeReducer,

  //SHOTZ
  shareShotzData: shareShotzReducer,
  mainSearchReducer,

  //Story
  getAllStoryData: GetAllStoriesReducer,


  //SEARCH
  getSearchUserData: mainSearchReducer,

  //marketPlace
  marketPlace: marketPlaceReducer,
  getSingleMarketPlaceProduct :singleMarketPlaceProductReducer,
  getAllProductCategory :getAllProductCategoryReducer,
  getSuggestedProductList : getSuggestedProductListReducer,
  getAllCategoryAndSubcategoryHaveData: getAllCategoryAndSubcategoryHaveDataReducer
});

export default rootReducer;
