import * as types from "../../Constants/Connection/index.js";

const initialState = { 
    remainingSociomateList: [],
    loading: true,
};

 const getRemainingSociomateListReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_REMAINING_SOCIOMATE_LIST:
            return {
                ...state,
                remainingSociomateList: action.payload,
                loading: false,
            };

        default:
            return state;
    }
};

export default getRemainingSociomateListReducer;