import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { loadAllUserConnection } from '../../../Services/Actions/Common/getUserConnectionAction';

const ConnectionEditModal = (props) => {
  const dispatch = useDispatch();

  // State to track the input value and button state
  const [inputValue, setInputValue] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // Effect to set initial input value when categoryName changes
  useEffect(() => {
    if (props.categoryName) {
      setInputValue(props.categoryName);
    }
  }, [props.categoryName]);

 // Enable button only if input value is different from the original category name (case-insensitive)
 useEffect(() => {
  // Compare the trimmed lowercase versions of both inputValue and categoryName
  if (inputValue.trim().toLowerCase() !== props.categoryName.trim().toLowerCase()) {
    setIsButtonDisabled(false);
  } else {
    setIsButtonDisabled(true);
  }
}, [inputValue, props.categoryName]);

  const editRelationalCategory = async (name, id) => {
    try {
      const body = {
        name,
        categoryId:id,
      };
      let user = JSON.parse(localStorage.getItem('sociomeeUser'));
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/hamBurgerMenu/editRelationalCategory`,
        body,
        config
      );
      dispatch(loadAllUserConnection());
    } catch (error) {
      console.log('Error getting edit relational categories:', error);
    }
  };

  return (
    <>
      {props.openModal && (
        <Modal isOpen={props.openModal} toggle={props.editModal} className="modal-dialog-centered">
          <div className="modal-header">
            <h3 className="modal-title" id="exampleModalLongTitle">
              Edit Category Name
            </h3>
            <div role="button" onClick={() =>{props.editModal();}} >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="icon-dark close-btn"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>
          </div>
          <ModalBody className="mt-2">
            <form className="theme-form">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="connectionName"
                  placeholder="Enter Category Name"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                />
                <Button
                  className="mt-3 w-100"
                  onClick={() => {
                    editRelationalCategory(inputValue, props.connectionId);
                    props.editModal();
                  }}
                  disabled={isButtonDisabled}
                  variant={isButtonDisabled ? "light" : "primary"}
                  style={{
                    backgroundColor: isButtonDisabled ? '#E6F3DB' : '',
                    color: isButtonDisabled ? '#000' : '#fff',
                    cursor: isButtonDisabled ? 'not-allowed' : 'pointer',
                  }}
                >
                  Save
                </Button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default ConnectionEditModal;
