
import * as types from '../../Constants/Connection/index';
import axios from 'axios';

const createConnectionList = (connectionList) => ({
    type: types.CREATE_ALL_CONNECTION_LIST,
    payload: connectionList,
});

const getRemainingSociomateList = (remainingSociomateList) => ({
    type: types.GET_REMAINING_SOCIOMATE_LIST,
    payload: remainingSociomateList,
});

// get all listing of sociomate on the behalf of filteredBy
export const getCreatetConnectionListAction = ( filteredBy ='All' ) => {
    //console.log("categoryName============>",categoryName)
    return function (dispatch) {
        const user = JSON.parse(localStorage.getItem('sociomeeUser'));
        if (user) {
            axios.post(`${process.env.REACT_APP_IPURL}/hamBurgerMenu/getAllConnectionList `, {
                    searchKey:"",
                    pageIndex:0,
                    pageSize:"",
                    sortBy:"",
                    filteredBy:filteredBy
            
            }, {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then((res) => {
                //console.log("getAllConnectionList=======>", res.data.data.successResult.data.rows);
                dispatch(createConnectionList(res.data.data.successResult.data))
            })
            .catch((error) => {
                console.log(error);
            });
        }
    };
};

//get listing of remaining sociomate listing on the behalf of particular relational category id.
export const getRemainingSociomateFromSociomateList = ( connectionId="" ) => {
    return function (dispatch) {
        const user = JSON.parse(localStorage.getItem('sociomeeUser'));
        if (user) {
            axios.post(`${process.env.REACT_APP_IPURL}/hamBurgerMenu/getFriendUserList `, {
                    searchKey:"",
                    pageIndex:0,
                    pageSize:"",
                    sortBy:"",
                    connectionId:connectionId
            
            }, {
                headers: { Authorization: `Bearer ${user.token}` }
            })
            .then((res) => {
                dispatch(getRemainingSociomateList(res.data.data.successResult.data.rows))
            })
            .catch((error) => {
                console.log(error);
            });
        }
    };
};