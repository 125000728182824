// Get following data
// Tranding hashtag
export const GET_TRENDING_HASHTAG = "GET_TRENDING_HASHTAG";

export const GET_TAGGED_PEOPLE = 'GET_TAGGED_PEOPLE';

export const GET_FEELING_CATEGORY = 'GET_FEELING_CATEGORY';

export const GET_FEELING = 'GET_FEELING';

export const GET_USER_FOLLOWERS = "GET_USER_FOLLOWERS";



export const GET_USER_CONNECTION = "GET_USER_CONNECTION";

export const GET_ADD_USER_CONNECTION = "GET_ADD_USER_CONNECTION";


// get following data
export const GET_USER_FOLLOWING = "GET_USER_FOLLOWING";


// GET USER following request
export const GET_FOLLOWING_REQUESTS = "GET_FOLLOWING_REQUESTS";

// GET USER sent request
export const GET_USER_SENT_REQUESTS = "GET_USER_SENT_REQUESTS";

//GET USER APPROVE REQUEST,
export const GET_USER_APPROVE_REQUESTS = "GET_USER_APPROVE_REQUESTS";

//GET USER DISAPPROVE REQUEST
export const  GET_USER_DISAPPROVE_REQUESTS = "GET_USER_DISAPPROVE_REQUESTS";

//GET SOCIOMATE
export const GET_SOCIOMATE ="GET_SOCIOMATE";

//block unblock
export const GET_USER_BLOCK_UNBLOCK ="GET_USER_BLOCK_UNBLOCK"

//follow and unfolow
export const GET_FOLLOW_UNFOLLOW = "GET_FOLLOW_UNFOLLOW"

// get report option
export const GET_USER_REPORT_OPTION = "GET_USER_REPORT_OPTION"

//REPORT USER
export const GET_USER_REPORT = "GET_USER_REPORT"

//Remove follower

export const GET_REMOVE_FOLLOWER ="GET_REMOVE_FOLLOWER"

// withdraw sent request

export const GET_USER_SENT_REQUEST_WITHDRAW ="GET_USER_SENT_REQUEST_WITHDRAW"

// create category

export const CREATE_CATEGORY="CREATE_CATEGORY"

// get all connection list

export const CREATE_ALL_CONNECTION_LIST= "CREATE_ALL_CONNECTION_LIST"

// get all remaining sociomate list

export const GET_REMAINING_SOCIOMATE_LIST = "GET_REMAINING_SOCIOMATE_LIST"