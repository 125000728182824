import React, { useEffect, useState } from "react";
import {
  Copy,
  CornerLeftDown,
  Flag,
  MoreVertical,
  Share,
  MinusCircle,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { AllUserSentRequest } from "../../../Services/Actions/Connection/getUserSentRequestAction";
import { withDrawRequestAction } from "../../../Services/Actions/Connection/getSentRequestWithdrawAction";
import { getActionBlockUnblock } from "../../../Services/Actions/Connection/getBlockUnblockAction";
import india_svg from "../../../NewComponents/IMG/india_svg.svg";
import group_profile from "../../../NewComponents/IMG/group_profile.png";
import { BlockUserModal } from "../HoverComponents/BlockUserModal";
import { Button } from "react-bootstrap";
import ConnectionReportModal from "../ConnectionModal/ConnectionReportModal";
import BlockModal from "../ConnectionModal/BlockModal";
import ConnectionShareModal from "../ConnectionModal/ConnectionShareModal";
import { GroupMembers } from "../HoverComponents/GroupMembers";
import PublicAccount from "../ConnectionModal/PublicAccount";
import "../Style.css";
import { NavLink } from "react-router-dom";

export const AllRequestedUser = () => {
  const { userSentRequests } = useSelector(
    (state) => state.userSentRequestsData
  );
  const dispatch = useDispatch();
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [shareOpen, setShareOpen] = useState(false);
  const toggleShareOpen = () => {
    setShareOpen((prevState) => !prevState);
  };

  const [userId, setUserId] = useState("");
  const [reportOpen, setReportOpen] = useState(false);
  const toggleReport = (id) => {
    setReportOpen((preState) => !preState);
    setUserId(id);
  };

  // withdraw request

  const handleRequestWithDraw = (id) => {
    dispatch(withDrawRequestAction(id));
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  useEffect((id) => {
    dispatch(withDrawRequestAction(id));
  }, []);

  const toggleBlock = (id) => {
    dispatch(getActionBlockUnblock(id));
    console.log("block");
  };

  useEffect(() => {
    dispatch(AllUserSentRequest());
  }, []);

  //block
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const toggleBlockModalOpen = (request) => {
    setBlockModalOpen((prevState) => !prevState);
    setSelectedRequest(request);
  };
  // console.log("All people and group request", userSentRequests)
  return (
    <>
      <div className="mppage-heading mt-4">
        <div className="group-custom-block">
          <div className="heading-podcast-blk">
            <h3 className="connection-sub-heading">Requested All </h3>
          </div>

          <Row className="connections_PeopleThisGrid">
            {userSentRequests &&
              userSentRequests?.data?.rows
                ?.slice(0, 12)
                .map((request, index) => {
                  return (
                    <Col lg="4" className="w-100" key={index}>
                      <div className="connection-card connection-card-new box-shadow-style content-mp-block d-flex justify-content-between">
                        <div className="media-body d-md-block">
                          <div class="media media-new d-flex align-items-start">
                            <div class="user-img rounded-circle d-flex align-items-start popover___wrapper">
                              <img
                                  src={request?.profileImage}
                                  className="rounded-circle "
                                  height={45}
                                  width={45}
                                  alt={request?.name || "User"}
                              />
                              <span class="available-stats online"></span>
                              <PublicAccount request={request} />
                            </div>

                            <div class="media-body d-md-block pl-1">
                              <NavLink to="/MyProfile">
                                <h4 className="m-0 name-style d-inline-block text-truncate">
                                  {request?.name || "NA"}
                                </h4>
                              </NavLink>
                              <div className="d-flex my-1">
                                <p className="user-name-style">
                                  {request?.userName || "NA"} |
                                </p>
                                <span><img src={request?.countryData[0]?.flagURL} className='ml-1 rem-mob-view-small' alt='...' width={19} height={13} /></span>
                              </div>
                              <div className="people-likes matual-friend-sec d-flex justify-content-between">
                                <p
                                  className="m-0 professional-style d-inline-block text-truncate"
                                  style={{ width: "180px" }}
                                >
                                  {request?.profession || "No Profession"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-end flex-column">
                          <UncontrolledButtonDropdown
                            className="d-flex align-items-end flex-column justify-content-end"
                            direction="left"
                          >
                            <DropdownToggle
                              color="light"
                              className="connection-cutomize-dropdown-toggle"
                            >
                              <MoreVertical size={22} />
                            </DropdownToggle>
                            <DropdownMenu className="Connection-dropdown">
                              <DropdownItem className="border-bottom py-2 menu-hover-style">
                                <CornerLeftDown size={15} className="mr-2" />
                                Withdraw Request
                              </DropdownItem>
                              <DropdownItem
                                className="border-bottom py-2 menu-hover-style"
                                onClick={toggleShareOpen}
                              >
                                <Share size={15} className="mr-2" />
                                Share Profile
                              </DropdownItem>
                              <DropdownItem className="border-bottom py-2 menu-hover-style">
                                <Copy size={15} className="mr-2" />
                                Copy Profile URL
                              </DropdownItem>
                              <DropdownItem
                                className="border-bottom py-2 menu-hover-style"
                                onClick={() => {
                                  toggleBlockModalOpen(request);
                                }}
                              >
                                <MinusCircle
                                  size={15}
                                  className="mr-2 icon-color-style"
                                />
                                Block
                              </DropdownItem>
                              <DropdownItem
                                className="py-2 menu-hover-style"
                                onClick={() => {
                                  toggleReport(request.id);
                                }}
                              >
                                <Flag size={15} className="mr-2" />
                                Report User
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                          <div className="mt-3">
                            <Button
                              color="flat-success"
                              className="btn-transparent pr-0 pb-0"
                            >
                              <CornerLeftDown size={15} color="#2F80ED" />
                              &nbsp;
                              <span
                                className="connection-blue-text rem-mob-view"
                                onClick={() => {
                                  handleRequestWithDraw(request.id);
                                }}
                              >
                                Withdraw
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
            {userSentRequests.length === 0 && (
              <h3 className="text-center connection-sub-heading">
                No Followers Found
              </h3>
            )}
          </Row>
        </div>
      </div>
      <ConnectionReportModal
        modalOpen={reportOpen}
        reportModalOpen={toggleReport}
        setUserId={userId}
      />
      <ConnectionShareModal
        openModal={shareOpen}
        shareModalOpen={toggleShareOpen}
      />
      {/*------------------------- block modal----------------------- */}

      <BlockModal
        request={selectedRequest}
        openModal={blockModalOpen}
        removeModalOpen={toggleBlockModalOpen}
      />
    </>
  );
};
