import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { Button } from 'react-bootstrap'
import axios from 'axios'
import { loadAllUserConnection } from '../../../Services/Actions/Common/getUserConnectionAction'

const DeleteModal = (props) => {

    const dispatch = useDispatch();

    const deleteCategory = async (id) => {
        try {
            let user = JSON.parse(localStorage.getItem("sociomeeUser"));
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                },
                data: { connectionId: id }, // Place the body data here
            };
            const response = await axios.delete(
                `${process.env.REACT_APP_IPURL}/hamBurgerMenu/relationCategory`,
                config
            );
            dispatch(loadAllUserConnection())
        } catch (error) {
            console.log("Error getting delete relational category:", error);
        }
    };
    
    return (
        <>
            {
                props.openModal !== false &&
                <Modal isOpen={props.openModal} toggle={props.openDeleteModal} className='modal-dialog-centered'>
                    <ModalHeader  color='light-denger' className='d-flex  justify-content-center Media_header' >
                        <h3>Delete Category</h3>
                    </ModalHeader>
                    <ModalBody className='text-center'>
                        <h4 className='py-2'>Do you really want to delete category</h4>
                    </ModalBody>
                    <ModalFooter className='d-flex align-items-center border-0 justify-content-center '>
                        <Button variant='outline-secondary' className='w-25' onClick={props.openDeleteModal}>
                            <span className='ml-1'>Cancel</span>
                        </Button>
                        <Button variant='danger' className='w-25' 
                        onClick={() => {
                            props.openDeleteModal(); 
                            deleteCategory(props.deleteId);
                        }}>
                            <span className='ml-1'>Delete</span>
                        </Button>
                    </ModalFooter>
                </Modal>
            }
        </>
    )
}

export default DeleteModal