import React, { useEffect ,useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom'
import { Col, Row } from 'reactstrap';
import { loadAllUserConnection } from '../../../Services/Actions/Common/getUserConnectionAction';
import { loadProfileByUserId } from '../../../Services/Actions/UserProfile/getUserProfileByUserIdAction';
import { loadAllUserFollowers } from '../../../Services/Actions/Common/getUserFollowersAction';
import { loadAllUserFollowing } from '../../../Services/Actions/Connection/getUserFollowingAction';
import { getUserFollowingRequests } from "../../../Services/Actions/Connection/getUserFollowingRequest";
import { loadAllUserSciomate } from '../../../Services/Actions/Connection/getSociomateAction';
import '../Style.css'

const ConnectionPlaceMenu = () => {
    
    const { getUserSociomate } = useSelector(state => state.userSociomateData);
    const { userFollowingRequests } = useSelector(state => state.userFollowingRequestsData);
    const { getUserFollowing } = useSelector(state => state.GetUserFollowingData)
    const { getUserFollowers } = useSelector(state => state.GetUserFollowersData)
    const { getUserConnection } = useSelector(state => state.GetUserConnectionData)

  
return (
        <>
            <Row className="marketplace-menu">
                <Col xl='12' lg='12'>
                    <ul className="mp-left-menu">
                        <li>
                            <NavLink to="/Connection">
                                <h5 className='conention-tab-text'>All</h5>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/FollowRequests">
                                <h5 className='conention-tab-text'>Follow Request ({userFollowingRequests?.data?.rows?.length})</h5>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/SocioMateScreens">
                                <h5 className='conention-tab-text'>SocioMates ({getUserSociomate?.rows?.length} )</h5>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/FollowingScreen">
                                <h5 className='conention-tab-text'>Following ({getUserFollowing?.rows?.length})</h5>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/FollowersScreen">
                                <h5 className='conention-tab-text'>Followers ({getUserFollowers?.rows?.length})</h5>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/ConnectionMenuScreen" >
                                <h5 className='conention-tab-text'>Categories ({getUserConnection?.length})</h5>
                            </NavLink>
                        </li>
                    </ul>
                </Col>
            </Row>
        </>
    )
}

export default ConnectionPlaceMenu