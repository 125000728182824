
import React, { useState, useEffect, useCallback } from 'react';
import { Fragment } from 'react';
import { ArrowLeft, X } from 'react-feather';
import { Col, Row } from 'reactstrap';
import { Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { loadTaggedPeople } from '../../../Services/Actions/Common/taggedPeopleAction';

const TagPeople = ({ postType, activeTab, toggle, onSelectedMentionChange, postEditValue }) => {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [tagSuggestData, setTagSuggestData] = useState([]);
  const [check, setCheck] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredTagSuggestData, setFilteredTagSuggestData] = useState([]);

  const onSelectedMentionChangeMemoized = useCallback(
    (selectedIds) => {
      onSelectedMentionChange(selectedIds);
    },
    [onSelectedMentionChange]
  );
  useEffect(() => {
    if (postEditValue && postEditValue.taggedPeoples.length > 0) {
      let userIds = [];
      let userObjects = [];
  
      // Iterate over the taggedPeoples from postEditValue
      postEditValue.taggedPeoples.forEach((item) => {
        userIds.push(item.id); 
        userObjects.push(item); 
      });
  
      // Set the selected list and check states to reflect backend values
      setSelectedList(userObjects); 
      setCheck(userIds);             
      onSelectedMentionChangeMemoized(userIds); 
      onSelectedMentionChange(userIds) 
    }
  }, [postEditValue, onSelectedMentionChangeMemoized]);
  

  useEffect(() => {
    const filteredData = tagSuggestData
      .filter(item =>
        item.fullName && item.fullName.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .map(item => ({ ...item, connectionType: 'sociomate' }));
    setFilteredTagSuggestData(filteredData);
  }, [searchQuery, tagSuggestData]);

  const handleCheck = (id, item) => {
    setCheck(prevCheck => {
      // Determine if the item is being checked or unchecked
      const isChecked = prevCheck.includes(id);
  
      // Update the `check` state based on whether the item is being checked or unchecked
      const newCheck = isChecked
        ? prevCheck.filter(x => x !== id) // Remove id if it's checked
        : [...prevCheck, id]; // Add id if it's not checked
  
      // Update `selectedList` based on the new `check` array
      setSelectedList(prevSelectedList => {
        if (isChecked) {
          // Remove item from `selectedList` if it's being unchecked
          return prevSelectedList.filter(selectedItem => selectedItem.id !== id);
        } else {
          // Add item to `selectedList` if it's being checked
          const isAlreadyInList = prevSelectedList.some(selectedItem => selectedItem.id === id);
          return isAlreadyInList
            ? prevSelectedList
            : [...prevSelectedList, item];
        }
      });
  
      // Notify parent component of the updated selection
      onSelectedMentionChangeMemoized(newCheck);
  
      return newCheck;
    });
  };
  

  const dispatch = useDispatch();
  const { taggedPeople } = useSelector((state) => state.TaggedPeopleData);

  useEffect(() => {
    dispatch(loadTaggedPeople(searchQuery));
  }, [searchQuery, dispatch]);

  useEffect(() => {
    setTagSuggestData(taggedPeople);
  }, [taggedPeople]);










  //   <<<<<<<<<<<<<<================================================================>>>>>>>>>>>>>>>



  
  return (
    <Fragment>
      <div className='border-bottom w-100'>
        <div className={`mx-3 py-3  FellingActivity_main_blk `}>
          <div onClick={() => {    if(postType==="recomendation"){
              toggle(`/modal/${7.6}`);
            }else if(postType==="thought"){
              toggle(`/modal/${7.1}`);
            }else if(postType==="text"){
              toggle(`/modal/${0}`);
            }else if(postType==="event"){
              toggle(`/modal/${7.3}`);
            }else if(postType==="media"){
              toggle(`/modal/${0.1}`);
            } ;
            setSelectedList([]);
            setCheck([])}} 
            style={{ cursor: "pointer" }} 
            className='Arrow_main_blk_section rounded-circle'>
            <ArrowLeft />
          </div>
          <div>
            <h3>Tag people</h3>
          </div>
          <div></div>
        </div>
      </div>
      <div className='mx-3'>
        <div className="right-setting pb-3 pt-3 d-flex align-items-center">
          <div className="profile-search-blk  w-100 mr-4">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
            <input
              type="text"
              className="form-control Feeling-control"
              name="search"
              placeholder="Search ..."
              data-bs-toggle="modal"
              data-bs-target="#peopleSearch"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)} 
            />
          </div>
          <p className='Suggest_tag_btn' 
             onClick={() => {    
              if(postType==="recomendation"){
              toggle(`/modal/${7.6}`);
            }else if(postType==="thought"){
              toggle(`/modal/${7.1}`);
            }else if(postType==="text"){
              toggle(`/modal/${0}`);
            }else if(postType==="event"){
              toggle(`/modal/${7.3}`);
            }else if(postType==="media"){
              toggle(`/modal/${0.1}`);
            }
            }}>Done</p>
        </div>
      </div>
      <div className='Feeling_scroll_box'>
        <div className='mx-3'>
          <Row className="mb-2">
            <Col>
              <div className='Update_Tag_suggest_blk d-flex'>
                {selectedList.map(item => (
                  <div key={item.id} className='Update_Image'>
                    <img 
                      src={item.profileImage || item.profileThumb} 
                      width="50px" 
                      height="50px" 
                      className="rounded-circle border" 
                      onError={(e) => e.currentTarget.src = 'https://sociomee-pro.s3.eu-central-1.amazonaws.com/logo/X0mSUU7CQMExa.png'} 
                      alt="Profile"
                    />
                    <div className='delete_img' onClick={() => handleCheck(item.id, item)}>
                      <X color='#555555' size={18} />
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <h3>Suggestion</h3>
            </Col>
          </Row>
          <Row>
              {filteredTagSuggestData.length > 0 ? (
                filteredTagSuggestData.map(item => (
                  <Col sm={12} className="mb-2" key={item.id}>
                    <div className='tag_ppl_main_blk_section' onClick={() => handleCheck(item.id, item)}>
                      <div className='Tag_ppl_main_blk'>
                        <img 
                          src={item.profileImage} 
                          onError={(e) => e.currentTarget.src = 'https://sociomee-pro.s3.eu-central-1.amazonaws.com/logo/X0mSUU7CQMExa.png'} 
                          width="50px" 
                          height="50px" 
                          className="rounded-circle border" 
                          alt="Profile"
                        /> 
                        <div className='ml-3'>
                          <h4>{item.fullName}</h4>
                          <p>{item.connectionType}</p>
                        </div>
                      </div>
                      <Checkbox checked={check.includes(item.id)} {...label} defaultChecked className='Check_Input_section' />
                    </div>
                  </Col>
                ))
              ) : (
                <Col sm={12}>
                  <h2><center>No people available</center></h2><br></br>
                </Col>
              )}
           </Row>

        </div>
      </div>
    </Fragment>
  );
};

export default TagPeople;

