import React from "react";
import { useState, Fragment, useEffect } from "react";
import axios from "axios";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import { Calendar, Image, Info, XCircle } from "react-feather";
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { loadProfileByUserId } from "../../../Services/Actions/UserProfile/getUserProfileByUserIdAction";
import { loadEventCategory } from "../../../Services/Actions/SocialFeed/getEventCategoryAction";

const EventsPost = ({
  onEventCategoryChange,
  onEventTitleChange,
  onEventCoverPhotoChange,
  eventCoverPhoto,
  onEventDescriptionChange,
  onEventStartDateChange,
  onEventEndDateChange,
  onEventAddressChange,
  eventTitleMain,
  eventDescriptionMain,
  eventStartDateMain,
  eventEndDateMain,
  eventAddressMain,
  eventCategoryMain,
  setEventCategoryName,
  eventCategoryName,
  setEventImage,
  eventImage,
  setPostButtonDisable
}) => {
  const [eventCategoryData, setEventCategoryData] = useState([]);
  const [event, setEvent] = useState(eventCategoryName);
  const [eventTitle, setEventTitle] = useState(eventTitleMain);
  const [eventDescription, setEventDescription] = useState(eventDescriptionMain);
  const [eventStartDate, setEventStartDate] = useState(eventStartDateMain);
  const [eventEndDate, setEventEndDate] = useState(eventEndDateMain);
  const [eventAddress, setEventAddress] = useState(eventAddressMain);
  const [error, setError] = useState("");

  const { userProfileByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );
  const { eventCategory } = useSelector((state) => state.getEventCategoryData);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadProfileByUserId());
    dispatch(loadEventCategory());
    setEventCategoryData(eventCategory);
  }, []);


  const handleChngImg = async (e) => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("files", file);
      formData.append("uploadFor", "eventCoverImage");

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/admin/uploadFile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.data && response.data.data.successResult) {
        // Get the link from the API response
        const uploadedImageLink = response.data.data.successResult;
        onEventCoverPhotoChange(uploadedImageLink[0]);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const toggleTheFile = (e) => {
    document.querySelector(".InputFile").click();
  };

  const toggleTheDate = (e) => {
    document.querySelector(".InputDate").click();
  };
  const date = new Date();
  const [dateData, setDateData] = useState(date);
  const DateGetData = (val) => {
    console.warn(val.target.value);
    setDateData(val.target.value);
  };

  const handleEventCategoryChange = (e) => {
    const categoryId = e.target.value;
    setEvent(categoryId);
    for(let i=0;i<eventCategoryData.length;i++){
      if(eventCategoryData[i].id===categoryId){
        setEventCategoryName(eventCategoryData[i].name)
      }
    }
    onEventCategoryChange(categoryId);
  };

  const handleEventTitleChange = (e) => {
    const value = e.target.value;
    setEventTitle(value);
    onEventTitleChange(value);
  };
  const handleEventDescriptionChange = (e) => {
    const value = e.target.value;
    setEventDescription(value);
    onEventDescriptionChange(value);
  };


  const handleEventStartDateChange = (e) => {
    const value = e.target.value;
    const currentDateTime = moment().format('YYYY-MM-DDTHH:mm');
    
    if (moment(value).isBefore(currentDateTime)) {
      setError('Event Start Date/Time cannot be in the past.');
      setPostButtonDisable(true);
      setEventStartDate(''); 
      onEventStartDateChange('');
    } else {
      setEventStartDate(value);
      onEventStartDateChange(value);
      setError('');
      setPostButtonDisable(false);
      
      // Update end date if it is before the new start date
      if (eventEndDate && moment(value).isAfter(eventEndDate)) {
        setEventEndDate(''); 
        setPostButtonDisable(true);
        setError('Event Start Date cannot be later than Event End Date.');
      }
    }
  };
  

  
  const handleEventEndDateChange = (e) => {
    const value = e.target.value;
    const currentDateTime = moment().format('YYYY-MM-DDTHH:mm');
    
    if (moment(value).isBefore(currentDateTime)) {
      setError('Event End Date/Time cannot be in the past.');
      setPostButtonDisable(true);
      setEventEndDate(''); 
      onEventEndDateChange('');
    } else if (eventStartDate && moment(value).isBefore(eventStartDate)) {
      setError('Event End Date/Time cannot be before the Event Start Date/Time.');
      setPostButtonDisable(true);
      setEventEndDate(''); 
      onEventEndDateChange('');
    } else {
      setEventEndDate(value);
      onEventEndDateChange(value);
      setError('');
      setPostButtonDisable(false);
    }
  };
  


  // Get today's date in YYYY-MM-DD format
  const today = moment().format('YYYY-MM-DD');

  const handleEventAddressChange = (e) => {
    const value = e.target.value;
    setEventAddress(value);
    onEventAddressChange(value);
  };

  const removeCoverImage = () =>{
   onEventCoverPhotoChange("")
  }
  
  // ================== Main_return_function ================//
  return (
    <Fragment>
      <CardBody>
        <div className="d-flex align-items-center my-2 py-2">
          <img
            className="rounded-circle border"
            width={30}
            height={30}
            src={userProfileByUserId.profileImage}
          />
          &nbsp;
          <h5 style={{ color: "#4D4D4D", fontWeight: "600", fontSize: "14px" }}>
            &nbsp; {userProfileByUserId.fullName}
          </h5>
        </div>
        <Form className="my-2">
          <FormGroup>
            <Label for="Biz-Catagory">
              <div className=" BizInput_heading_section ">
                <b>Event Catagory</b>&nbsp;
                <Info
                  id="BizCatagoryId"
                  fill="#C4C4C4"
                  color="#fff"
                  size={17}
                />
                <UncontrolledTooltip placement="right" target="BizCatagoryId">
                  enter the event catagory
                </UncontrolledTooltip>
              </div>
            </Label>
            <div className="custom-select-container">
            <select
              id="Biz-Catagory"
              name="select"
              className="custom-select"
              value={eventCategoryName || ""} 
              onChange={handleEventCategoryChange}
            >
              <option value="" disabled>
                Select an event category
              </option>
              {eventCategoryData &&
                eventCategoryData.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
            </select>
          </div>

          </FormGroup>
          <FormGroup>
            <Label for="Biz-Catagory">
              <div className=" BizInput_heading_section ">
                <b>Event Title*</b>&nbsp;
                <Info id="eventTitleId" fill="#C4C4C4" color="#fff" size={17} />
                <UncontrolledTooltip placement="right" target="eventTitleId">
                  enter the event title
                </UncontrolledTooltip>
              </div>
            </Label>
            <Input
              placeholder="Enter the  event title"
              id="Biz-Catagory"
              name="select"
              type="text"
              className="Biz_form_input_field"
              value={eventTitle}
              onChange={handleEventTitleChange}
              maxLength="64"
            />
            <p className="Post_creation_text_limt mt-1 text-end">
              Max 64 characters
            </p>
          </FormGroup>
          <FormGroup>
            <Label for="Biz-Catagory">
              <div className=" BizInput_heading_section ">
                <b>Upload Event Cover Photo </b>&nbsp;
                <Info id="coverId" fill="#C4C4C4" color="#fff" size={17} />
                <UncontrolledTooltip placement="right" target="coverId">
                  please upload cover photo
                </UncontrolledTooltip>
              </div>
            </Label>
            <div className="AddMedia_photo_section">
              <Card className="Addmedia_sub_section">
                {!eventCoverPhoto ? (
                  <div
                    onClick={(e) => {
                      toggleTheFile(e);
                    }}
                  >
                    <div className="text-center">
                      <Image />
                      <h3 className="py-2"> Add Photo/ Videos</h3>
                      <p>or drag and drop</p>
                    </div>
                    <input
                      type="file"
                      name=""
                      accept="image/*" // Restrict to image files
                      onChange={handleChngImg}
                      className="InputFile d-none"
                      id=""
                    />
                  </div>
                ) : (
                  
                  <div className="Media_Img_section">
                    <img
                      src={`${process.env.REACT_APP_IMAGEURL}${eventCoverPhoto}`}
                      className="img-fluid"
                    />
                    <div
                      className="Img_right_section "
                      onClick={() => onEventCoverPhotoChange("")}
                    >
                      <XCircle color="#ffff" onClick={removeCoverImage}/>
                    </div>
                  </div>
                )}
              </Card>
            </div>
          </FormGroup>
          <FormGroup>
            <Label for="Biz-Catagory">
              <div className=" BizInput_heading_section ">
                <b>Description</b>&nbsp;
                <Info id="despId" fill="#C4C4C4" color="#fff" size={17} />
                <UncontrolledTooltip placement="right" target="despId">
                  write the some description
                </UncontrolledTooltip>
              </div>
            </Label>
            <Input
              type="textarea"
              cols="15"
              rows="5"
              placeholder="Enter the  event description"
              value={eventDescription}
              onChange={handleEventDescriptionChange}
              maxLength="600"
            />
            <p className="Post_creation_text_limt mt-1 text-end">
              Max 600 characters
            </p>
          </FormGroup>

          <FormGroup>
            <Label for="Event-Start-Date">
              <div className="BizInput_heading_section">
                <b>Event Start Date/Time</b>
              </div>
            </Label>
            <Input
              id="Event-Start-Date"
              type="datetime-local"
              className="Biz_form_input_field"
              value={eventStartDate}
              onChange={handleEventStartDateChange}
              min={today + 'T00:00'}
            />
            <p className="Post_creation_text_limit mt-1 text-end">
              {moment(eventStartDate).isValid()
                ? moment(eventStartDate).format('DD MMM YYYY hh:mm:ss a')
                : ''}
            </p>
         </FormGroup>
         <FormGroup>
            <Label for="Event-End-Date">
              <div className="BizInput_heading_section">
                <b>Event End Date/Time</b>
              </div>
            </Label>
            <Input
              id="Event-End-Date"
              type="datetime-local"
              className="Biz_form_input_field"
              value={eventEndDate}
              onChange={handleEventEndDateChange}
              min={eventStartDate ? eventStartDate : today + 'T00:00'}
            />
            <p className="Post_creation_text_limit mt-1 text-end">
                  {moment(eventEndDate).isValid()
                    ? moment(eventEndDate).format("DD MMM YYYY hh:mm:ss a")
                    : ""}
                </p>
              {error && (<p className="text-danger mt-2">{error} </p>)}
          </FormGroup>
          <FormGroup>
            <Label for="Event-Address">
              <div className="BizInput_heading_section">
                <b>Address or Link to Event</b>
                &nbsp;
                <Info id="addressId" fill="#C4C4C4" color="#fff" size={17} />
                <UncontrolledTooltip placement="right" target="addressId">
                  Put the event link/address
                </UncontrolledTooltip>
              </div>
            </Label>
            <Input
              id="Event-Address"
              type="text"
              className="Biz_form_input_field"
              value={eventAddress}
              onChange={handleEventAddressChange}
              placeholder="https://www.thaiembassy.com/thailand/thailand-festival/324"
            />
          </FormGroup>
        </Form>
      </CardBody>
    </Fragment>
  );
};

export default EventsPost;
