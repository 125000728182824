import React, { useEffect, useState } from 'react';
import { Copy, Flag, MinusCircle, MoreVertical, PlusCircle, Share } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { loadAllUserFollowers } from '../../../Services/Actions/Common/getUserFollowersAction';
import { getCreatetConnectionListAction } from '../../../Services/Actions/Connection/getCreatedCategoryAction';
import india_svg from '../../../NewComponents/IMG/india_svg.svg'
import  BlockUserModal  from '../HoverComponents/BlockUserModal';
import ConnectionReportModal from '../ConnectionModal/ConnectionReportModal';
import AddCategoryModal from '../ConnectionModal/AddCategoryModal';
import RemoveModal from '../ConnectionModal/RemoveModal';
import ConnectionShareModal from '../ConnectionModal/ConnectionShareModal';
import '../Style.css'
import PublicAccount from '../ConnectionModal/PublicAccount';

export const MemberList = (props) => {
    //const { getUserFollowers } = useSelector(state => state.GetUserFollowersData)
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [categoryName, setCategoryName] = useState("");
    const [userId, setUserId] = useState("");
    const [connectionListId, setConnectionListId] = useState("")
    const [fullName, setFullName] = useState("");
    const dispatch = useDispatch();

    //useEffect(() => {
    //    dispatch(loadAllUserFollowers())
    //}, [])

    // ------------- share Modal open ------------- 
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }

    // ----------- Remove Followers Modal state -----------
    const [removeModalOpen, setRemoveModalOpen] = useState(false)
    const toggleRemoveModalOpen = () => {
        setRemoveModalOpen((prevState) => !prevState)
    }

    //  -------------  Start Add RelationShip Status ------------- 
    const [addRelationShipOptions, setAddRelationShipOptions] = useState(false)
    const toggleAddRelationShip = () => {
        setAddRelationShipOptions((preState) => !preState)
    }

    //  -------------  Start ReportedOption  ------------- 
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = (request) => {
        setReportOpen((preState) => !preState);
        setSelectedRequest(request);
    }

     //------------Block User Modal State------------ 
     const [blockModalOpen, setBlockModalOpen] = useState(false);
     const toggleBlockModalOpen = () => {
         setBlockModalOpen((prevState) => !prevState);
     };

    const[reportConfirm,setReportConfirm] = useState(false)
    useEffect(()=>{
        if(reportConfirm){
            setTimeout(() => {
               dispatch(getCreatetConnectionListAction(categoryName));
               setReportConfirm(false)
             }, 1000);
        }
    },[reportConfirm])

    return (
        <>
            {
                props.filteredData && props.filteredData?.map((request,index) => {
                    return <div className="connection-card  box-shadow-style content-mp-block cus-cur-pointer nav-flex-container position-relative mx-2 p-2 mt-3 "  key={index} >
                        <div className="nav-flex-container">
                            <div class="user-img d-flex align-items-start popover___wrapper " >
                                <img src={request?.profileImage}
                                    className="rounded-circle " height={45} width={45} alt="shivam singh" />
                                <span class="available-stats online"></span>
                                <PublicAccount request={request} />
                            </div>
                            <div className="media-body pl-1">
                                <h4 className='m-0 name-style' >{request?.fullName || 'NA'}</h4>
                                <div className='d-flex'>
                                    <p className='user-name-style'>{request?.userName || 'NA'} | </p>
                                    <div className='d-flex'>
                                        <span><img src={request?.countryData[0]?.flagURL}   
                                            className="ml-1 rem-mob-view-small"
                                            alt="..."
                                            width={19}
                                            height={13} 
                                        /></span>
                                        <span className='professional-style ml-1'>{request?.countryData[0]?.name}</span>
                                    </div>
                                </div>
                                <h6 className='professional-style d-inline-block text-truncate' >{request?.profession || "No Profession"}</h6>
                            </div>
                        </div>
                        <div className='pt-2 ml-1'>
                            <ul id="counter" className='d-flex align-items-center justify-content-around '>
                                <li className='text-center '>
                                    <h3 className="counter-value green-text-style" data-count="546">{request?.followingCount}</h3>
                                    <h5 className='professional-style'>Following</h5>
                                </li>
                                <li className='mx-3'>
                                    <hr style={{ height: '30px', width: '1px', color: '#000000' }} />
                                </li>
                                <li className='text-center'>
                                    <h3 className="counter-value green-text-style" data-count="26335">{request?.totalPostCount}</h3>
                                    <h5 className='professional-style'>Post</h5>
                                </li>
                                <li className='mx-3'>
                                    <hr style={{ height: '30px', width: '1px', color: '#000000' }} />
                                </li>
                                <li className='text-center'>
                                    <h3 className="counter-value green-text-style" data-count="6845">{request?.followersCount}</h3>
                                    <h5 className='professional-style'>Followers</h5>
                                </li>
                            </ul>
                        </div>
                        <div className=' ml-sm-4 ml-xs-4'>
                            <ul id="counter" className='d-flex align-items-center justify-content-around  '>
                                <div className="d-flex align-items-center">
                                    <UncontrolledButtonDropdown className='d-flex align-items-end flex-column justify-content-end' direction='left'>
                                        <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle'>
                                            <MoreVertical size={22} />
                                        </DropdownToggle>
                                        <DropdownMenu className='Connection-dropdown'>
                                            <DropdownItem className='border-bottom py-2 menu-hover-style' 
                                                onClick={() => {
                                                    toggleRemoveModalOpen();
                                                    setFullName(request?.fullName);
                                                    setConnectionListId(request?.connectionListId);
                                                    setCategoryName(request?.ConnectionName);
                                                }}>
                                                    <MinusCircle size={15} className='mr-2' />
                                                    Remove From List
                                            </DropdownItem>
                                            {/*<DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleAddRelationShip}><PlusCircle size={15} className='mr-2 icon-color-style' />Add To Category</DropdownItem>*/}
                                            <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleShareOpen}><Share size={15} className='mr-2' />Share Profile</DropdownItem>
                                            <DropdownItem className='border-bottom py-2 menu-hover-style'>
                                                <Copy size={15} className='mr-2' />Copy Profile URL</DropdownItem>
                                            <DropdownItem
                                                className="border-bottom py-2 menu-hover-style"
                                                onClick={() => {
                                                setCategoryName(request.ConnectionName);
                                                setUserId(request.id);
                                                toggleBlockModalOpen();
                                                }}
                                            >
                                                <MinusCircle
                                                    size={15}
                                                    className="mr-2 icon-color-style"
                                                />
                                                Block
                                            </DropdownItem>
                                            <DropdownItem className='py-2 menu-hover-style' 
                                                onClick={() => {
                                                    toggleReport(request);
                                                    setCategoryName(request.ConnectionName);
                                                }}>
                                                <Flag size={15} className='mr-2' />Report User
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            </ul>
                        </div>

                    </div>
                })
            }
            {
                props.filteredData?.length <= 0 && <h3 className='text-center connection-sub-heading'>No Sociomate Found</h3>
            }
            {/*  ------------------ Add Report Option  ------------------ */}

            {reportOpen && (
                <ConnectionReportModal
                    request={selectedRequest}
                    modalOpen={reportOpen}
                    reportModalOpen={toggleReport}
                    setReportConfirm ={setReportConfirm}
                />
            )}

            {/*  ------------------ Add CategoryModal Status  ------------------ */}

            <AddCategoryModal
                openModal={addRelationShipOptions}
                cateModalOpen={addRelationShipOptions}
            />

            {/*  ------------------ Remove SocioMate Modal  ------------------ */}

            <RemoveModal
                openModal={removeModalOpen}
                removeModalOpen={toggleRemoveModalOpen}
                fullName={fullName}
                connectionListId={connectionListId}
                categoryName={categoryName}
            />

            {/* ------------------ Start Share Modal  ------------------*/}
            <ConnectionShareModal
                openModal={shareOpen}
                shareModalOpen={toggleShareOpen}
            />
            {/*------------------------- block modal----------------------- */}

           { blockModalOpen && (
                <BlockUserModal
                    userId={userId}
                    openModal={blockModalOpen}
                    blockModalOpen={toggleBlockModalOpen}
                    categoryName={categoryName}
                /> 
            )}
        </>
    )
}

