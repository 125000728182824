import * as types from "../../Constants/Connection/index";
import axios from "axios";

const getBlockUnblock = (getUserBlockUnblock) => ({
  type: types.GET_USER_BLOCK_UNBLOCK,
  payload: getUserBlockUnblock,
});

export const getActionBlockUnblock = (userId) => {
  let user = JSON.parse(localStorage.getItem("sociomeeUser"));
  return function (dispatch) {
    if (user) {
      axios
        .post(
          `${process.env.REACT_APP_IPURL}/user/setBlockUser`,
          {
            blockedUserId: userId,
            isBlocked: true,
          },
          {
            headers: { Authorization: `Bearer ${user?.token}` },
          }
        )
        .then((res) => {
          dispatch(getBlockUnblock(res.data.data.successResult));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};
