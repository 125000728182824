import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Button } from 'react-bootstrap'
import { loadAllRemoveFollower } from '../../../Services/Actions/Connection/getRemoveFollowerAction'
import { useDispatch } from 'react-redux'
import { getCreatetConnectionListAction } from '../../../Services/Actions/Connection/getCreatedCategoryAction'
import axios from 'axios'


const RemoveModal = ({fullName,connectionListId, removeModalOpen, openModal,categoryName} ) => {
    const dispatch = useDispatch();

    const removeUserFromList = async (connectionListId) => {
        try {
            const body = {
                ConnectionListId:connectionListId
            };
            let user = JSON.parse(localStorage.getItem("sociomeeUser"));
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                },
            };
            const response = await axios.put(
                `${process.env.REACT_APP_IPURL}/hamBurgerMenu/removeUserFromList`,
                body,
                config
            );
        } catch (error) {
            console.log("Error getting add user to connection:", error);
        }
      };

    const handleRemove = ()=>{
        removeUserFromList(connectionListId)
        removeModalOpen();
        setTimeout(() => {
            dispatch(getCreatetConnectionListAction(categoryName));
          }, 1000); 
    }
    return (
     
        <>
            {
                openModal !== false &&
                <Modal isOpen={openModal} toggle={removeModalOpen} className='modal-dialog-centered'>
                    <ModalHeader onClick={removeModalOpen} className='d-flex justify-content-center Media_header' >
                        <h3>Remove SocioMate</h3>
                    </ModalHeader>
                    <ModalBody className='text-center'>
                        <h4 className='py-2'>Do you really want to remove {fullName}</h4>
                    </ModalBody>
                    <ModalFooter className='d-flex border-0 align-items-center justify-content-center '>
                        <Button variant='outline-secondary' className='w-25' onClick={removeModalOpen}>
                            <span className='ml-1 w-25'>Cancel</span>
                        </Button>
                        <Button variant='danger' className='w-25' onClick={handleRemove}>
                            <span className='ml-1 w-25'>Remove</span>
                        </Button>
                    </ModalFooter>
                </Modal>
            }
        </>
    )
}

export default RemoveModal