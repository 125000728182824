import { Fragment, useState, useEffect } from "react";
import SingleMedia from "../../../NewComponents/IMG/SingleMedia.png";
import PlayVideo from "../../../NewComponents/IMG/playVideo.svg";
import LeftquotationIcon from "../../../NewComponents/IMG/LeftquotationIcon.svg";
import RightquotationIcon from "../../../NewComponents/IMG/RightquotationIcon.svg";
import RecommendationIconImg from "../../../NewComponents/IMG/Exampleimagerecc.svg";
import CaptionImg from "../../../NewComponents/IMG/CaptioniMG.png";
import pollIcon from "../../../NewComponents/IMG/pollIcon.svg";
import CpImg from "../../../NewComponents/IMG/CpImg.png";
import CpImg1 from "../../../NewComponents/IMG/CpImg1.png";
import CpImg2 from "../../../NewComponents/IMG/CpImg2.png";
import EvntImg from "../../../NewComponents/IMG/EvntImg.png";
import Mic from "../../../NewComponents/IMG/Mic.png";
import MainIcon from "../../../NewComponents/IMG/podcastIcon.svg";
import Wings from "../../../NewComponents/IMG/PocastSideWing.svg";
import banner from "../../../NewComponents/IMG/Adpreview.svg";
import VideoImg from "../../../NewComponents/IMG/sngleImg.png";
import PodcastImg from "../../../NewComponents/IMG/PaidPodcastImg.png";
import paidEpImg from "../../../NewComponents/IMG/paidEpImg.svg";
import pdTag from "../../../NewComponents/IMG/pdTag.svg";
import pdMic from "../../../NewComponents/IMG/pdMic.svg";
import snglePcImg from "../../../NewComponents/IMG/snglePcImg.svg";
import PaidSeriesPcImg from "../../../NewComponents/IMG/PaidSeriesPcImg.svg";
import SeriesPcImg from "../../../NewComponents/IMG/SeriesPcImg.svg";
import FreeSeriesImg from "../../../NewComponents/IMG/FreeSeriesImg.svg";
import EpPcImg from "../../../NewComponents/IMG/EpPcImg.svg";
import EpPd1Img from "../../../NewComponents/IMG/EpPd1Img.svg";
import UpLoadPodcastImg from "../../../NewComponents/IMG/UpLoadPodcastImg.svg";
import MpImg1 from "../../../NewComponents/IMG/MpImg.svg";
import ReShreImg from "../../../NewComponents/IMG/ReShreImg.svg";
import moment from "moment-timezone";
import InviteModal from '../../biz-components/AllTypesModal/InviteModal'

import DefaultpodcastImg from "../../../NewComponents/IMG/DefaultpodcastImg.svg";
import {
  CheckCircle,
  ChevronDown,
  Circle,
  Mail,
  MapPin,
  MessageCircle,
  Pause,
  PhoneCall,
  Play,
  Star,
  X,
} from "react-feather";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  Row,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { loadAllUserPosts } from "../../../Services/Actions/SocialFeed/getAllUserPostsAction";
import { useDispatch, useSelector } from "react-redux";
import ShareToModal from "../../top-trending-components/ShareToModal";
import MediaPreviewModal from "../../Universal-components/post-creations/sub-components/MediaPreviewModal";
import { TextColor } from "../DummyArray";

//================== Start_Single_Media_Post ===================//
// export const SingleMediaPost = ({ mediaURL, text, hashtags }) => {
//    const [showFullText, setShowFullText] = useState(false);
//   const toggleText = () => {
//     setShowFullText(!showFullText);
//   };
//   return (
//     <Fragment>
//       <div>
//         <div className="SingleMedia_blk">
//           <img src={mediaURL} alt="singleMedia" className="img-fluid" />
//         </div>
//         <div style={{ padding: "20px" }}>
//           <div className="SingleMedia_Content_blk">
//             <h3>
//               {text.length > 500 ? `${text.slice(0, 500)}...` : text}
//               {text.length > 500 && (
//                 <span style={{ color: "#81C14B", cursor: "pointer" }}>
//                   ...Read more
//                 </span>
//               )}
//             </h3>
//             <p className="my-2">
//               <b style={{ color: "#91BC81" }}>
//                 {hashtags &&
//                   hashtags.map((tags) => {
//                     return <span key={tags.id}>#{tags.name} </span>;
//                   })}
//               </b>
//             </p>
//           </div>
//         </div>
//       </div>
//     </Fragment>
//   );
// };

export const SingleMediaPost = ({ mediaURL, text, hashtags, onImageClick }) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const displayText = showFullText ? text : text.slice(0, 500);

  return (
    <div className="SingleMedia_blk_1">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          objectFit: "cover",
        }}
      >
        <img
          src={mediaURL}
          alt="singleMedia"
          className="borderForgrid"
          style={{ height: "500px", objectFit: "cover" }}
          onClick={onImageClick}
        />
      </div>
      <div>
        <div className="SingleMedia_Content_blk">
          <h3>
            {displayText}
            {text.length > 500 && (
              <span
                style={{ color: "#81C14B", cursor: "pointer" }}
                onClick={toggleText}
              >
                {showFullText ? " Show less" : " ...Read more"}
              </span>
            )}
          </h3>
          {hashtags && hashtags.length > 0 && (
            <p className="my-2">
              <b style={{ color: "#91BC81" }}>
                {hashtags.map((tags) => (
                  <a>
                    <span key={tags.id}>#{tags.name} </span>
                  </a>
                ))}
              </b>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
// export const SingleMediaPost = ({ mediaURL, text, hashtags }) => {
//   const [showFullText, setShowFullText] = useState(false);

//   const toggleText = () => {
//     setShowFullText(!showFullText);
//   };

//   const displayText = showFullText ? text : text.slice(0, 500);

//   return (
//     <div className="SingleMedia_blk">
//       <div style={{ display: "flex", justifyContent: "center" }}>
//         <div
//           className="borderForgrid"
//           style={{
//             height: "400px",
//             overflow: "hidden",
//           }}
//         >
//           <img
//             src={mediaURL}
//             alt="singleMedia"
//             style={{
//               objectFit: "cover",
//               height: "100%",
//               width: "100%",
//             }}
//           />
//         </div>
//       </div>
//       <div>
//         <div className="SingleMedia_Content_blk">
//           <h3>
//             {displayText}
//             {text.length > 500 && (
//               <span
//                 style={{ color: "#81C14B", cursor: "pointer" }}
//                 onClick={toggleText}
//               >
//                 {showFullText ? " Show less" : " ...Read more"}
//               </span>
//             )}
//           </h3>
//           {hashtags && hashtags.length > 0 && (
//             <p className="my-2">
//               <b style={{ color: "#91BC81" }}>
//                 {hashtags.map((tags) => (
//                   <span key={tags.id}>#{tags.name} </span>
//                 ))}
//               </b>
//             </p>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };
// export const SingleMediaPost = ({ mediaURL, text, hashtags }) => {
//   const [showFullText, setShowFullText] = useState(false);

//   const toggleText = () => {
//     setShowFullText(!showFullText);
//   };

//   const displayText = showFullText ? text : text.slice(0, 500);

//   return (
//     <div className="SingleMedia_blk">
//       <div style={{ display: "flex", justifyContent: "center" }}>
//         <div
//           className="borderForgrid"
//           style={{
//             height: "400px",
//             overflow: "hidden",
//           }}
//         >
//           <img
//             src={mediaURL}
//             alt="singleMedia"
//             style={{
//               objectFit: "contain",
//               height: "100%",
//               width: "100%",
//             }}
//           />
//         </div>
//       </div>
//       <div>
//         <div className="SingleMedia_Content_blk">
//           <h3>
//             {displayText}
//             {text.length > 500 && (
//               <span
//                 style={{ color: "#81C14B", cursor: "pointer" }}
//                 onClick={toggleText}
//               >
//                 {showFullText ? " Show less" : " ...Read more"}
//               </span>
//             )}
//           </h3>
//           {hashtags && hashtags.length > 0 && (
//             <p className="my-2">
//               <b style={{ color: "#91BC81" }}>
//                 {hashtags.map((tags) => (
//                   <span key={tags.id}>#{tags.name} </span>
//                 ))}
//               </b>
//             </p>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };
// export const SingleMediaPost = ({ mediaURL, text, hashtags,onImageClick }) => {

//   const [showFullText, setShowFullText] = useState(false);
//   var w,h;
//   const toggleText = () => {
//     setShowFullText(!showFullText);
//   };
//   function getMeta(url) {
//     return new Promise((resolve, reject) => {
//         let img = new Image();
//         img.onload = () => resolve(img);
//         img.onerror = () => reject();
//         img.src = url;
//     });
// }

// async function run() {

//   let img = await getMeta(mediaURL);

//    w = img.width;
//    h = img.height;
// }

// run();
//   const displayText = showFullText ? text : text.slice(0, 500);
//   var hh;
// if(h>"400px"){
// hh="400px";
// }else{
//   hh=h;
// }
//   return (
//     <>

//     <div className="SingleMedia_blk" >
//       <div style={{ display: "flex", justifyContent: "center" }}>
//         <div
//           className="borderForgrid"
//           style={{
//             width:w,
//             height: hh,
//             overflow: "hidden",
//             // resizeMode:"contain",
//           }}
//         >
//           <img
//           onClick={onImageClick}
//             src={mediaURL}
//             alt="singleMedia"
//             style={{
//               // objectFit: "contain",
//               objectFit:"cover",
//               height: "100%",
//               width: "100%",
//               maxWidth: "100%", // Ensure the image doesn't exceed the container width
//               maxHeight:"100%"
//             }}
//           />
//           <h3>{mediaURL.height}</h3>
//         </div>
//       </div>
//       <div>
//         <div className="SingleMedia_Content_blk">
//           <h3>
//             {displayText}
//             {text.length > 500 && (
//               <span
//                 style={{ color: "#81C14B", cursor: "pointer" }}
//                 onClick={toggleText}
//               >
//                 {showFullText ? " Show less" : " ...Read more"}
//               </span>
//             )}
//           </h3>
//           {hashtags && hashtags.length > 0 && (
//             <p className="my-2">
//               <b style={{ color: "#91BC81" }}>
//                 {hashtags.map((tags) => (
//                   <span key={tags.id}>#{tags.name} </span>
//                 ))}
//               </b>
//             </p>
//           )}
//         </div>

//       </div>
//     </div>

//     </>
//   );
// };

//================== End_Single_Media_Post ===================//

//============== Start_Single_MediaWithCapstion_Post =========//

// export const SingleMediaCaptionPost = ({
//   mediaURL,
//   text,
//   caption,
//   hashtags,
// }) => {
//   const [showFullText, setShowFullText] = useState(false);

//   const toggleText = () => {
//     setShowFullText(!showFullText);
//   };

//   const displayText = showFullText ? text : text.slice(0, 500);

//   return (
//     <Fragment>
//       <div>
//         <div className="SingleCaptionMedia_blk" style={{ marginTop: "12px" }}>
//           <img src={mediaURL} alt="singleMedia" />
//           <div className="Caption_box_section">
//             <h3>{caption}</h3>
//           </div>
//         </div>
//         <div style={{ padding: "10px" }}>
//           <div className=" Text_Card_blk">
//             <h3>
//               {displayText}
//               {text.length > 500 && (
//                 <span
//                   style={{ color: "#81C14B", cursor: "pointer" }}
//                   onClick={toggleText}
//                 >
//                   {showFullText ? " Show less" : " ...Read more"}
//                 </span>
//               )}
//             </h3>
//             <div className="mt-2">
//               <p className="my-2">
//                 <b style={{ color: "#91BC81" }}>
//                   {hashtags &&
//                     hashtags.map((tags) => {
//                       return <span key={tags.id}>#{tags.name} </span>;
//                     })}
//                 </b>
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </Fragment>
//   );
// };

export const SingleMediaCaptionPost = ({
  mediaURL,
  text,
  caption,
  hashtags,
  onImageClick,
}) => {
  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const displayText = showFullText ? text : text.slice(0, 500);

  return (
    <div>
      <div
        className="SingleCaptionMedia_blk SingleCaptionMedia_blk_1 centered-image-container"
        style={{ marginTop: "12px" }}
      >
        <div
          // style={{ display: "flex", justifyContent: "center" }} // Center the image vertically
          style={{
            display: "flex",
            justifyContent: "center",
            objectFit: "cover",
          }}
        >
          <img
            src={mediaURL}
            alt="singleMedia"
            className="borderForgrid"
            // style={{ height: "400px" ,objectFit: "cover"}} // Set the fixed height here
            onClick={onImageClick}
            style={{ height: "500px", objectFit: "cover" }}
          />
        </div>
        <div className="Caption_box_section">
          <h3>{caption}</h3>
        </div>
      </div>
      <div style={{ padding: "10px" }}>
        <div className="Text_Card_blk">
          <h3>
            {displayText}
            {text.length > 500 && (
              <span
                style={{ color: "#81C14B", cursor: "pointer" }}
                onClick={toggleText}
              >
                {showFullText ? " Show less" : " ...Read more"}
              </span>
            )}
          </h3>
          <div className="mt-2">
            <p className="my-2">
              <b style={{ color: "#91BC81" }}>
                {hashtags &&
                  hashtags.map((tags) => (
                    <a>
                      <span key={tags.id}>#{tags.name} </span>
                    </a>
                  ))}
              </b>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const SingleMediaNoDespPost = () => {
  return (
    <Fragment>
      <div>
        <div className="SingleCaptionMedia_blk" style={{ marginTop: "12px" }}>
          <img
            src={CpImg}
            alt="singleMedia"
            className="img-fluid Caption_Img"
            style={{ height: "400px", objectFit: "cover" }}
          />
          <div className="Caption_box_section">
            <h3>Today Our Three Cute Puppy Dog Birthday !!!!</h3>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export const DoubleMediaNoDespPost = () => {
  return (
    <Fragment>
      <div className="d-flex w-100">
        <div className="SingleCaptionMedia_blk w-100">
          <img
            // src={CpImg1}
            alt="singleMedia"
            className="img-fluid Caption_Img"
          />
          <div className="Caption_box_section">
            <h3>Today Our Three Cute Puppy Dog Birthday !!!!</h3>
          </div>
        </div>
        <div
          className="SingleCaptionMedia_blk w-100"
          style={{ marginLeft: "2px" }}
        >
          <img src={CpImg2} alt="singleMedia" className="" />
          <div className="Caption_box_section">
            <h3>
              a detailed explanation of an image that provides textual access to
              ...
            </h3>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
//============== End_Single_MediaWithCapstion_Post ===========//

//================== Start_Single_Text_Post ==================//
export const TextPostDisplay = ({ text, hashtags, txtColor, postType }) => {
  const [showFullText, setShowFullText] = useState(false);
  const toggleText = () => {
    setShowFullText(!showFullText);
  };
  const [forwardOpen, setForwardOpen] = useState(false);
  const toggleForward = () => {
    setForwardOpen((preState) => !preState);
  };

  return (
    <Fragment>
      {/* <ShareToModal forwardOpen={forwardOpen} toggleForward={toggleForward} postType={postType} /> */}
      <div>
        {/* <div style={{ paddingLeft: "20px", paddingRight: "20px" }}> */}
        <div className="Text_Card_blk" style={{ minHeight: '95px' }}> {/* applied here  style={{ minHeight: '90px' } if text value min  */}
          {/* <p className="my-2">
            <b style={{ color: "#91BC81" }}>
              {hashtags &&
                hashtags.map((tags) => {
                  return <span key={tags.id}>#{tags.name} </span>;
                })}
            </b>
          </p> */}
          <div className="Text_Card_blk" style={{ minHeight: 'inherit' }}>  {/* applied here  style={{ minHeight: '90px' } if text value min  */}
            {showFullText ? (
              <div>
                <h3 style={{ color: txtColor }}>{text}</h3>
                <button
                  onClick={toggleText}
                  style={{
                    color: "#81C14B",
                    cursor: "pointer",
                    border: "none",
                  }}
                >
                  Read Less
                </button>
              </div>
            ) : (
              <div>
                <h3 style={{ color: txtColor }}>{text.slice(0, 500)}</h3>
                {text.length > 500 && (
                  <button
                    onClick={toggleText}
                    style={{
                      color: "#81C14B",
                      cursor: "pointer",
                      border: "none",
                    }}
                  >
                    Read More...
                  </button>
                )}
                <div className="mt-2">
                  <p className="my-2">
                    <b style={{ color: "#91BC81" }}>
                      {hashtags &&
                        hashtags.map((tags) => (
                          <a key={tags.id}>
                            <span>#{tags.name} </span>
                          </a>
                        ))}
                    </b>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};


export const TextTagPostDisplay = () => {
  return (
    <Fragment>
      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <div className=" Text_Card_blk">
          <h3>Celebration new album song launched</h3>
          <div className="mt-2">
            <p className="my-2">
              <b style={{ color: "#81c14b" }}>
                #Musiccelebration, #Musiccelebration, #music, #party, #music{" "}
              </b>
            </p>
            <div className="Text_Card_blk">
              <h3>
                {" "}
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. has been the industry's standard dummy text ever since
                the 1500s...
              </h3>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
//================== End_Single_Text_Post ==================//

//================== Start_MultiMedia_Post (working)==================//
export const MediaPostDisplay = ({
  postType,
  text,
  hashtags,
  date,
  onImageClick,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };

  const createdAt = new Date(date);
  const options = { year: "numeric", month: "long", day: "2-digit" };
  const formattedDate = createdAt.toLocaleDateString("en-US", options);

  return (
    <Fragment>
      <div
        data-bs-toggle="modal"
        data-bs-target="#imageModel"
        className="mediaForGridContainer"
      >
        <div style={{ height: "282px", width: "100%" }}>
          {postType[0].fileType == "image" ? (
            <img
              className="h-100 w-100"
              src={`${postType[0]?.fileURL}?fit=fill&w=800&h=300`}
              alt={postType[0]?.caption}
              onClick={onImageClick}
            />
          ) : (
            <video
              className="h-100 w-100"
              src={`${postType[0]?.fileURL}?fit=fill&w=800&h=300`}
              alt={postType[0]?.caption}
              onClick={onImageClick}
            />
          )}
        </div>
        <div className="">
          {postType.length > 1 && (
            <div>
              {postType[1].fileType == "image" ? (
                <img
                  height={94}
                  className="w-100"
                  src={`${postType[1]?.fileURL}?fit=fill&w=800&h=300`}
                  alt={postType[1]?.caption}
                  style={{ objectFit: "cover" }}
                  onClick={onImageClick}
                />
              ) : (
                <video
                  height={94}
                  className="w-100"
                  src={`${postType[1]?.fileURL}?fit=fill&w=800&h=300`}
                  alt={postType[1]?.caption}
                  style={{ objectFit: "cover" }}
                  onClick={onImageClick}
                />
              )}
            </div>
          )}
          {postType.length > 2 && (
            <div>
              {postType[2].fileType == "image" ? (
                <img
                  height={94}
                  className="w-100"
                  src={`${postType[2]?.fileURL}?fit=fill&w=800&h=300`}
                  alt={postType[2]?.caption}
                  style={{ objectFit: "cover" }}
                  onClick={onImageClick}
                />
              ) : (
                <video
                  height={94}
                  className="w-100"
                  src={`${postType[2]?.fileURL}?fit=fill&w=800&h=300`}
                  alt={postType[2]?.caption}
                  style={{ objectFit: "cover" }}
                  onClick={onImageClick}
                />
              )}
            </div>
          )}
          {postType.length > 3 && (
            <div>
              <div className="position-relative">
                {postType[3].fileType == "image" ? (
                  <img
                    height={94}
                    className="w-100 imgForShadow"
                    src={`${postType[3]?.fileURL}?fit=fill&w=800&h=300`}
                    alt={postType[3]?.caption}
                    style={{ objectFit: "cover" }}
                    onClick={onImageClick}
                  />
                ) : (
                  <video
                    height={94}
                    className="w-100 imgForShadow"
                    src={`${postType[3]?.fileURL}?fit=fill&w=800&h=300`}
                    alt={postType[3]?.caption}
                    style={{ objectFit: "cover" }}
                    onClick={onImageClick}
                  />
                )}
                {postType.length > 4 && (
                  <div className="mediaForshade">
                    <p style={{ width: "50px", fontSize: "18px" }}>
                      + {postType.length - 4}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={{ padding: "10px" }}>
        <div className="Suggestmember-blk py-2">
          <span
            className="text-capitalize"
            style={{
              color: "#888888",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            {formattedDate}
          </span>
          &nbsp; &nbsp; <Circle fill="#888888" color="#888888" size={6} />
          &nbsp; &nbsp;
          <span
            style={{
              color: "#888888",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            <p className="my-2">
              <b style={{ color: "#91BC81" }}>
                {hashtags &&
                  hashtags.map((tags) => {
                    return (
                      <a>
                        <span key={tags.id}>#{tags.name} </span>
                      </a>
                    );
                  })}
              </b>
            </p>
          </span>
        </div>

        <div className="SingleMedia_Content_blk">
          <h3>
            {expanded ? text : text.slice(0, 300)}
            {text.length > 300 && (
              <span
                style={{ color: "#81C14B", cursor: "pointer" }}
                onClick={toggleReadMore}
              >
                {expanded ? " See less" : " ...Read more"}
              </span>
            )}
          </h3>
        </div>
      </div>
    </Fragment>
  );
};

export const DoubleMediaPostVideoImageType = ({
  postType,
  text,
  hashtags,
  videoURL,
  imageURL,
  caption,
  onImageClick,
}) => {
  const [expanded, setExpanded] = useState(false);
  // const handleHashtagClick = (hashtag) => {
  //   // Handle the click event, e.g., navigate to a specific page or perform an action
  //   console.log('Hashtag clicked:', hashtag);
  // };

  // const renderTextWithClickableHashtags = () => {
  //   const hashtagRegex = /#(\w+)/g;

  //   return text.split(' ').map((word, index) => {
  //     const matches = word.match(hashtagRegex);

  //     if (matches) {
  //       // If the word contains a hashtag, make it clickable
  //       const hashtag = matches[0];
  //       const restOfWord = word.replace(hashtagRegex, '');

  //       return (
  //         <span key={index}>
  //           {restOfWord}
  //           <span
  //             style={{ color: '#007bff', cursor: 'pointer' }}
  //             onClick={() => handleHashtagClick(hashtag)}
  //           >
  //             {hashtag}
  //           </span>{' '}
  //         </span>
  //       );
  //     }

  //     // If the word does not contain a hashtag, render it as is
  //     return <span key={index}>{word} </span>;
  //   });
  // };

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };
  const videoStyle = {
    height: "400px" || "auto", // Use the provided height or let the aspect ratio determine it
  };

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: "pink",
          height: "400px",
          width: "100%",
          gap: "5px",
          objectFit: "cover",
        }}
      >
        <img
          height="100%"
          width="50%"
          src={imageURL}
          alt={caption}
          style={{ objectFit: "cover" }}
          onClick={onImageClick}
        />

        <div className="position-relative" style={videoStyle}>
          <video
            className="h-100 w-100 borderForgrid "
            style={{ objectFit: "cover" }}
            src={videoURL}
            controls // Add controls for video playback
            onClick={onImageClick}
          />
          {/* <div className="mediaForVBshade">
          <img src={PlayVideo} alt="playVideo" />
        </div> */}
        </div>
      </div>

      <div style={{ padding: "10px" }}>
        <span
          style={{
            color: "#888888",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          <p className="my-2">
            <b style={{ color: "#91BC81" }}>
              {hashtags &&
                hashtags.map((tags) => {
                  return (
                    <a>
                      <span key={tags.id}>#{tags.name} </span>
                    </a>
                  );
                })}
            </b>
          </p>
        </span>

        <div className="SingleMedia_Content_blk">
          <h3>
            {expanded ? text : text.slice(0, 300)}
            {text.length > 300 && (
              <span
                style={{ color: "#81C14B", cursor: "pointer" }}
                onClick={toggleReadMore}
              >
                {expanded ? " See less" : " ...Read more"}
              </span>
            )}
          </h3>
        </div>
      </div>
    </Fragment>
  );
};

export const TripleMediaPostVideoImageType = ({
  text,
  hashtags,
  postType,
  caption,
  onImageClick,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };

  let img_len = 0;
  let img_url = [];
  let vid_len = 0;
  let vid_url = [];
  for (let i = 0; i < postType.mediaList.length; i++) {
    if (postType.mediaList[i].fileType === "image") {
      img_len++;
      img_url.push(postType.mediaList[i].fileURL);
    } else {
      vid_len++;
      vid_url.push(postType.mediaList[i].fileURL);
    }
  }
  // console.log("img_url-vid_url", img_url, "  ", vid_url);

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",

          height: "282px",
          width: "100%",
          gap: "5px",
          objectFit: "cover",
        }}
      >
        {img_len === 1 ? (
          <img
            height="100%"
            width="80%"
            src={img_url[0]}
            alt={postType[0]?.caption}
            style={{ objectFit: "cover" }}
            onClick={onImageClick}
          />
        ) : (
          <video
            className="h-100 w-100 borderForgrid"
            src={vid_url[0]}
            onClick={onImageClick}
            controls // Add controls for video playback/////
          />
        )}

        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          {img_len === 2
            ? img_url.map((url, index) => {
                return (
                  <img
                    height="50%"
                    width="100%"
                    src={url}
                    style={{ objectFit: "cover" }}
                    onClick={onImageClick}
                  />
                );
              })
            : vid_url.map((url, index) => {
                return (
                  <video
                    height="50%"
                    width="100%"
                    src={url}
                    style={{ objectFit: "cover" }}
                    onClick={onImageClick}
                  />
                );
              })}
        </div>
      </div>

      <div style={{ padding: "10px" }}>
        <span
          style={{
            color: "#888888",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          <p className="my-2">
            <b style={{ color: "#91BC81" }}>
              {hashtags &&
                hashtags.map((tags) => {
                  return (
                    <a>
                      {" "}
                      <span key={tags.id}>#{tags.name} </span>{" "}
                    </a>
                  );
                })}
            </b>
          </p>
        </span>

        <div className="SingleMedia_Content_blk">
          <h3>
            {expanded ? text : text.slice(0, 300)}
            {text.length > 300 && (
              <span
                style={{ color: "#81C14B", cursor: "pointer" }}
                onClick={toggleReadMore}
              >
                {expanded ? " See less" : " ...Read more"}
              </span>
            )}
          </h3>
        </div>
      </div>
    </Fragment>
  );
};

export const DoubleMediaPostDisplay = ({
  postType,
  text,
  hashtags,
  onImageClick,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: "pink",
          height: "400px",
          width: "100%",
          gap: "5px",
          objectFit: "cover",
        }}
      >
        <img
          height="100%"
          width="50%"
          src={`${postType[0]?.fileURL}`}
          alt={postType[0]?.caption}
          style={{ objectFit: "cover" }}
          onClick={onImageClick}
        />

        <img
          height="100%"
          width="50%"
          src={`${postType[1]?.fileURL}`}
          alt={postType[1]?.caption}
          style={{ objectFit: "cover" }}
          onClick={onImageClick}
        />
      </div>

      <div style={{ padding: "10px" }}>
        <span
          style={{
            color: "#888888",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          <p className="my-2">
            <b style={{ color: "#91BC81" }}>
              {hashtags &&
                hashtags.map((tags) => {
                  return (
                    <a>
                      <span key={tags.id}>#{tags.name} </span>
                    </a>
                  );
                })}
            </b>
          </p>
        </span>

        <div className="SingleMedia_Content_blk">
          <h3>
            {expanded ? text : text.slice(0, 300)}
            {text.length > 300 && (
              <span
                style={{ color: "#81C14B", cursor: "pointer" }}
                onClick={toggleReadMore}
              >
                {expanded ? " See less" : " ...Read more"}
              </span>
            )}
          </h3>
        </div>
      </div>
    </Fragment>
  );
};

export const TripleMediaPostDisplay = ({
  postType,
  text,
  hashtags,
  onImageClick,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",

          height: "282px",
          width: "100%",
          gap: "5px",
          objectFit: "cover",
        }}
      >
        <img
          height="100%"
          width="80%"
          src={`${postType[0]?.fileURL}`}
          alt={postType[0]?.caption}
          style={{ objectFit: "cover" }}
          onClick={onImageClick}
        />

        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <img
            height="50%"
            width="100%"
            src={`${postType[1]?.fileURL}`}
            alt={postType[1]?.caption}
            style={{ objectFit: "cover" }}
            onClick={onImageClick}
          />

          <img
            height="50%"
            width="100%"
            src={`${postType[2]?.fileURL}`}
            alt={postType[2]?.caption}
            style={{ objectFit: "cover" }}
            onClick={onImageClick}
          />
        </div>
      </div>

      <div style={{ padding: "10px" }}>
        <span
          style={{
            color: "#888888",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          <p className="my-2">
            <b style={{ color: "#91BC81" }}>
              {hashtags &&
                hashtags.map((tags) => {
                  return (
                    <a>
                      {" "}
                      <span key={tags.id}>#{tags.name} </span>{" "}
                    </a>
                  );
                })}
            </b>
          </p>
        </span>

        <div className="SingleMedia_Content_blk">
          <h3>
            {expanded ? text : text.slice(0, 300)}
            {text.length > 300 && (
              <span
                style={{ color: "#81C14B", cursor: "pointer" }}
                onClick={toggleReadMore}
              >
                {expanded ? " See less" : " ...Read more"}
              </span>
            )}
          </h3>
        </div>
      </div>
    </Fragment>
  );
};

// export const MediaPostDisplay = ({ postType, text, hashtags }) => {
//   return (
//     <Fragment>
//       <div
//         data-bs-toggle="modal"
//         data-bs-target="#imageModel"
//         className="mediaForGridContainer"
//       >
//         <div style={{ height: "282px", width: "100%" }}>
//           <img
//             className="h-100 w-100"
//             src={`${postType[0]?.fileURL}?fit=fill&w=800&h=300`}
//           />
//         </div>
//         <div className="mediaForGrid">
//           <div>
//             <img
//               height={94}
//               className="w-100"
//               src={`${postType[1]?.fileURL}?fit=fill&w=800&h=300`}
//             />
//           </div>
//           <div>
//             <img
//               height={94}
//               className="w-100"
//               src={`${postType[2]?.fileURL}?fit=fill&w=800&h=300`}
//             />
//           </div>
//           <div>
//             <div className="position-relative">
//               <img
//                 height={94}
//                 className="w-100 imgForShadow"
//                 src={`${postType[3]?.fileURL}?fit=fill&w=800&h=300`}
//               />
//               {/* <div className="mediaForshade">+ 4</div> */}
//             </div>
//           </div>
//         </div>
//       </div>
//       <div style={{ padding: "20px" }}>
//         <div className="SingleMedia_Heading_blk">
//           <h2>Liverpool Win Champions League For 6th Decemb...</h2>
//           <div className="Suggestmember-blk py-2">
//             <span
//               className="text-capitalize"
//               style={{
//                 color: "#888888",
//                 fontSize: "14px",
//                 fontWeight: "400",
//               }}
//             >
//               01 July, 2022
//             </span>
//             &nbsp; &nbsp; <Circle fill="#888888" color="#888888" size={6} />
//             &nbsp; &nbsp;
//             <span
//               style={{
//                 color: "#888888",
//                 fontSize: "14px",
//                 fontWeight: "400",
//               }}
//             >
//               Sports
//             </span>
//           </div>
//         </div>
//         <div className="SingleMedia_Content_blk">
//           <h3>
//             Denmark is a Scandinavian country comprising the Jutland Peninsula
//             and numerous islands. It's linked to nearby Sweden via the Öresund
//             bridge. Copenhagen, its capital, is home to royal palaces and
//             colorful Nyhavn harbor, plus the Tivoli amusement park and penhagen,
//             It's linked to nearby Sweden via the Öresund bridge. Copenhagen, its
//             capital, is home to royal palaces and colorful Nyhavn harbor, plus
//             the Tivoli amusement park and penhagen its capi
//             <span style={{ color: "#81C14B", cursor: "pointer" }}>
//               ...Read more
//             </span>
//           </h3>
//         </div>
//       </div>
//     </Fragment>
//   );
// };
// export const MediaPostDisplay = ({ postType, text, hashtags }) => {
//   const mediaItems = postType.slice(0, 4); // Display up to 4 media items

//   return (
//     <Fragment>
//       <div
//         data-bs-toggle="modal"
//         data-bs-target="#imageModel"
//         className="mediaForGridContainer"
//       >
//         {mediaItems.map((mediaItem, index) => (
//           <div key={index} style={{ width: `${100 / mediaItems.length}%` }}>
//             <img
//               className="h-100 w-100"
//               src={mediaItem.fileURL}
//               alt={mediaItem.caption}
//             />
//           </div>
//         ))}
//         {mediaItems.length === 2 ? (
//           <div className="mediaForGrid">
//             {mediaItems.map((mediaItem, index) => (
//               <div key={index}>
//                 <img
//                   height={94}
//                   className="w-100"
//                   src={mediaItem.fileURL}
//                   alt={mediaItem.caption}
//                 />
//               </div>
//             ))}
//           </div>
//         ) : (
//           <div className="mediaForGrid">
//             {mediaItems.map((mediaItem, index) => (
//               <div key={index}>
//                 <img
//                   height={94}
//                   className="w-100"
//                   src={mediaItem.fileURL}
//                   alt={mediaItem.caption}
//                 />
//               </div>
//             ))}
//             {mediaItems.length > 3 && (
//               <div className="position-relative">
//                 {mediaItems.slice(0, 3).map((mediaItem, index) => (
//                   <div key={index}>
//                     <img
//                       height={94}
//                       className="w-100 imgForShadow"
//                       src={mediaItem.fileURL}
//                       alt={mediaItem.caption}
//                     />
//                   </div>
//                 ))}
//                 <div className="mediaForshade">+ {mediaItems.length - 3}</div>
//               </div>
//             )}
//           </div>
//         )}
//       </div>
//       <div style={{ padding: "20px" }}>
//         <div className="SingleMedia_Heading_blk">
//           <h2>{text}</h2>
//           <div className="Suggestmember-blk py-2">
//             <span
//               className="text-capitalize"
//               style={{
//                 color: "#888888",
//                 fontSize: "14px",
//                 fontWeight: "400",
//               }}
//             >
//               01 July, 2022
//             </span>
//             &nbsp; &nbsp; <Circle fill="#888888" color="#888888" size={6} />
//             &nbsp; &nbsp;
//             <span
//               style={{
//                 color: "#888888",
//                 fontSize: "14px",
//                 fontWeight: "400",
//               }}
//             >
//               {hashtags}
//             </span>
//           </div>
//         </div>
//         <div className="SingleMedia_Content_blk">
//           <h3>
//             {text}
//             <span style={{ color: "#81C14B", cursor: "pointer" }}>
//               ...Read more
//             </span>
//           </h3>
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// export const MediaPostDisplay = ({ postType, text, hashtags }) => {
//   const mediaItems = postType.slice(0, 4); // Display up to 4 media items

//   return (
//     <Fragment>
//       <div
//         data-bs-toggle="modal"
//         data-bs-target="#imageModel"
//         className="mediaForGridContainer"
//       >
//         {mediaItems.map((mediaItem, index) => (
//           <div key={index} style={{ width: `${100 / mediaItems.length}%` }}>
//             <img
//               className="h-100 w-100"
//               src={mediaItem.fileURL}
//               alt={mediaItem.caption}
//             />
//           </div>
//         ))}
//         {/* {mediaItems.length === 2 ? (
//           <div className="mediaForGrid">
//             {mediaItems.map((mediaItem, index) => (
//               <div key={index}>
//                 <img
//                   height={94}
//                   className="w-100"
//                   src={mediaItem.fileURL}
//                   alt={mediaItem.caption}
//                 />
//               </div>
//             ))}
//           </div>
//         ) : (
//           null
//           // <div className="mediaForGrid">
//           //   {mediaItems.map((mediaItem, index) => (
//           //     <div key={index}>
//           //       <img
//           //         height={94}
//           //         className="w-100"
//           //         src={mediaItem.fileURL}
//           //         alt={mediaItem.caption}
//           //       />
//           //     </div>
//           //   ))}
//           //   {mediaItems.length > 3 && (
//           //     <div className="position-relative">
//           //       {mediaItems.slice(0, 3).map((mediaItem, index) => (
//           //         <div key={index}>
//           //           <img
//           //             height={94}
//           //             className="w-100 imgForShadow"
//           //             src={mediaItem.fileURL}
//           //             alt={mediaItem.caption}
//           //           />
//           //         </div>
//           //       ))}
//           //       <div className="mediaForshade">+ {mediaItems.length - 3}</div>
//           //     </div>
//           //   )}
//           // </div>
//         )} */}
//       </div>
//       <div style={{ padding: "20px" }}>
//         <div className="SingleMedia_Heading_blk">
//           <h2>{text}</h2>
//           <div className="Suggestmember-blk py-2">
//             <span
//               className="text-capitalize"
//               style={{
//                 color: "#888888",
//                 fontSize: "14px",
//                 fontWeight: "400",
//               }}
//             >
//               01 July, 2022
//             </span>
//             &nbsp; &nbsp; <Circle fill="#888888" color="#888888" size={6} />
//             &nbsp; &nbsp;
//             <span
//               style={{
//                 color: "#888888",
//                 fontSize: "14px",
//                 fontWeight: "400",
//               }}
//             >
//               {hashtags}
//             </span>
//           </div>
//         </div>
//         <div className="SingleMedia_Content_blk">
//           <h3>
//             {text}
//             <span style={{ color: "#81C14B", cursor: "pointer" }}>
//               ...Read more
//             </span>
//           </h3>
//         </div>
//       </div>
//     </Fragment>
//   );
// };

//================== End_MultiMedia_Post ====================//

//================== Start_Media_Video_Post ==================//

export const MediaPostVideoType = ({ postType, text, hashtags }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };
  return (
    <Fragment>
      <div
        data-bs-toggle="modal"
        data-bs-target="#imageModel"
        className="mediaForGridContainers"
      >
        <div
          className="position-relative"
          style={{ height: "282px", width: "100%" }}
        >
          <video
            className="h-100 w-100 borderForgrid"
            src={`${postType[0]?.fileURL}?fit=fill&w=800&h=300`}
            controls
          />
          {/* <div className="mediaForVBshade">
            <img src={PlayVideo} alt="playVideo" />
          </div> */}
        </div>
        <div className="mediaForGrid">
          {postType.length > 1 && (
            <div>
              <div className="position-relative">
                <video
                  height={94}
                  className="w-100 borderForgrid"
                  src={`${postType[1]?.fileURL}?fit=fill&w=800&h=300`}
                  controls
                />
                {/* <div className="mediaForVshade">
                <img src={PlayVideo} alt="playVideo" />
              </div> */}
              </div>
            </div>
          )}
          {postType.length > 2 && (
            <div>
              <div className="position-relative">
                <video
                  height={94}
                  className="w-100 borderForgrid"
                  src={`${postType[2]?.fileURL}?fit=fill&w=800&h=300`}
                  controls
                />
                {/* <div className="mediaForVshade">
                <img src={PlayVideo} alt="playVideo" />
              </div> */}
              </div>
            </div>
          )}
          {postType.length > 3 && (
            <div>
              <div className="position-relative">
                <video
                  height={94}
                  className="w-100 borderForgrid"
                  src={`${postType[3]?.fileURL}?fit=fill&w=800&h=300`}
                  controls
                />
                {/* <div className="mediaForVshade">
                <img src={PlayVideo} alt="playVideo" />
              </div> */}
                {postType.length > 4 && (
                  <div className="mediaForshade">
                    <p style={{ width: "50px", fontSize: "18px" }}>
                      + {postType.length - 4}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={{ padding: "10px" }}>
        <div className="SingleMedia_Content_blk">
          <p className="my-2">
            <b style={{ color: "#91BC81" }}>
              {hashtags &&
                hashtags.map((tags) => {
                  return (
                    <a>
                      {" "}
                      <span key={tags.id}>#{tags.name} </span>
                    </a>
                  );
                })}
            </b>
          </p>
          <h3>
            {expanded ? text : text.slice(0, 300)}
            {text.length > 300 && (
              <span
                style={{ color: "#81C14B", cursor: "pointer" }}
                onClick={toggleReadMore}
              >
                {expanded ? " See less" : " ...Read more"}
              </span>
            )}
          </h3>
        </div>
      </div>
    </Fragment>
  );
};

export const SingleVideoType = ({
  videoURL,
  caption,
  text,
  hashtags,
  height,
  onImageClick,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };
  const videoStyle = {
    height: height || "auto", // Use the provided height or let the aspect ratio determine it
  };

  return (
    <Fragment>
      <div className="position-relative" style={videoStyle}>
        <video
          className="h-100 w-100 borderForgrid"
          src={videoURL}
          onClick={onImageClick}
          controls // Add controls for video playback/////
        />
        {/* <div className="mediaForVBshade">
          <img src={PlayVideo} alt="playVideo" />
        </div> */}
      </div>
      <div style={{ padding: "10px" }}>
        <div className="SingleMedia_Content_blk">
          <p className="my-2">
            <b style={{ color: "#91BC81" }}>
              {hashtags &&
                hashtags.map((tags) => {
                  return (
                    <a>
                      <span key={tags.id}>#{tags.name} </span>
                    </a>
                  );
                })}
            </b>
          </p>
          <h3>
            {expanded ? text : text.slice(0, 300)}
            {text.length > 300 && (
              <span
                style={{ color: "#81C14B", cursor: "pointer" }}
                onClick={toggleReadMore}
              >
                {expanded ? " See less" : " ...Read more"}
              </span>
            )}
          </h3>
        </div>
      </div>
    </Fragment>
  );
};

export const DoubleMediaPostVideoType = ({
  postType,
  text,
  hashtags,
  onImageClick,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };
  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: "pink",
          height: "282px",
          width: "100%",
          gap: "5px",
        }}
      >
        <video
          height="100%"
          width="50%"
          src={`${postType[0]?.fileURL}`}
          alt={postType[0]?.caption}
          onClick={onImageClick}
          controls
        />

        <video
          height="100%"
          width="50%"
          src={`${postType[1]?.fileURL}`}
          alt={postType[1]?.caption}
          onClick={onImageClick}
          controls
        />
      </div>

      <div style={{ padding: "10px" }}>
        <span
          style={{
            color: "#888888",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          <p className="my-2">
            <b style={{ color: "#91BC81" }}>
              {hashtags &&
                hashtags.map((tags) => {
                  return (
                    <a>
                      {" "}
                      <span key={tags.id}>#{tags.name} </span>{" "}
                    </a>
                  );
                })}
            </b>
          </p>
        </span>

        <div className="SingleMedia_Content_blk">
          <h3>
            {expanded ? text : text.slice(0, 300)}
            {text.length > 300 && (
              <span
                style={{ color: "#81C14B", cursor: "pointer" }}
                onClick={toggleReadMore}
              >
                {expanded ? " See less" : " ...Read more"}
              </span>
            )}
          </h3>
        </div>
      </div>
    </Fragment>
    // <Fragment>
    //   <div
    //     data-bs-toggle="modal"
    //     data-bs-target="#imageModel"
    //     className="mediaForGridContainers"
    //   >
    //     <div
    //       className="position-relative"
    //       style={{ height: "282px", width: "100%" }}
    //     >
    //       <video
    //         className="h-100 w-100 borderForgrid"
    //         src={`${postType[0]?.fileURL}?fit=fill&w=800&h=300`}
    //         controls
    //       />
    //       {/* <div className="mediaForVBshade">
    //         <img src={PlayVideo} alt="playVideo" />
    //       </div> */}
    //     </div>
    //     <div className="mediaForGrid">
    //       {postType.length > 1 && (
    //         <div>
    //           <div className="position-relative">
    //             <video
    //               height={94}
    //               className="w-100 borderForgrid"
    //               src={`${postType[1]?.fileURL}?fit=fill&w=800&h=300`}
    //               controls
    //             />
    //             {/* <div className="mediaForVshade">
    //             <img src={PlayVideo} alt="playVideo" />
    //           </div> */}
    //           </div>
    //         </div>
    //       )}
    //       {postType.length > 2 && (
    //         <div>
    //           <div className="position-relative">
    //             <video
    //               height={94}
    //               className="w-100 borderForgrid"
    //               src={`${postType[2]?.fileURL}?fit=fill&w=800&h=300`}
    //               controls
    //             />
    //             {/* <div className="mediaForVshade">
    //             <img src={PlayVideo} alt="playVideo" />
    //           </div> */}
    //           </div>
    //         </div>
    //       )}
    //       {postType.length > 3 && (
    //         <div>
    //           <div className="position-relative">
    //             <video
    //               height={94}
    //               className="w-100 borderForgrid"
    //               src={`${postType[3]?.fileURL}?fit=fill&w=800&h=300`}
    //               controls
    //             />
    //             {/* <div className="mediaForVshade">
    //             <img src={PlayVideo} alt="playVideo" />
    //           </div> */}
    //             {postType.length > 4 && (
    //               <div className="mediaForshade">
    //                 <p style={{ width: "50px", fontSize: "18px" }}>
    //                   + {postType.length - 4}
    //                 </p>
    //               </div>
    //             )}
    //           </div>
    //         </div>
    //       )}
    //     </div>
    //   </div>
    //   <div style={{ padding: "10px" }}>
    //     <div className="SingleMedia_Content_blk">
    //       <p className="my-2">
    //         <b style={{ color: "#91BC81" }}>
    //           {hashtags &&
    //             hashtags.map((tags) => {
    //               return <span key={tags.id}>#{tags.name} </span>;
    //             })}
    //         </b>
    //       </p>
    //       <h3>
    //         {expanded ? text : text.slice(0, 300)}
    //         {text.length > 300 && (
    //           <span
    //             style={{ color: "#81C14B", cursor: "pointer" }}
    //             onClick={toggleReadMore}
    //           >
    //             {expanded ? " See less" : " ...Read more"}
    //           </span>
    //         )}
    //       </h3>
    //     </div>
    //   </div>
    // </Fragment>
  );
};

export const TripleMediaPostVideoType = ({
  postType,
  text,
  hashtags,
  onImageClick,
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "282px",
          width: "100%",
          gap: "5px",
        }}
      >
        <video
          height="100%"
          width="80%"
          src={`${postType[0]?.fileURL}`}
          alt={postType[0]?.caption}
          onClick={onImageClick}
          controls
        />

        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <video
            height="50%"
            width="100%"
            src={`${postType[1]?.fileURL}`}
            alt={postType[1]?.caption}
            onClick={onImageClick}
            controls
          />

          <video
            height="50%"
            width="100%"
            src={`${postType[2]?.fileURL}`}
            alt={postType[2]?.caption}
            onClick={onImageClick}
            controls
          />
        </div>
      </div>

      <div style={{ padding: "10px" }}>
        <span
          style={{
            color: "#888888",
            fontSize: "14px",
            fontWeight: "400",
          }}
        >
          <p className="my-2">
            <b style={{ color: "#91BC81" }}>
              {hashtags &&
                hashtags.map((tags) => {
                  return (
                    <a>
                      {" "}
                      <span key={tags.id}>#{tags.name} </span>{" "}
                    </a>
                  );
                })}
            </b>
          </p>
        </span>

        <div className="SingleMedia_Content_blk">
          <h3>
            {expanded ? text : text.slice(0, 300)}
            {text.length > 300 && (
              <span
                style={{ color: "#81C14B", cursor: "pointer" }}
                onClick={toggleReadMore}
              >
                {expanded ? " See less" : " ...Read more"}
              </span>
            )}
          </h3>
        </div>
      </div>
    </Fragment>
  );
};

//================== End_Media_Video_Post ====================//

//================== Start_Alert_Post =======================//
export const AlertPostDisplayModerate = ({ alertDescription }) => {
  return (
    <div
      className="pvt-event-cont-blk text-dark m-2"
      style={{ background: "#FF822E", fontWeight: "700" }}
    >
      <div
        className="pvt-eventcont"
        style={{ background: "#FFD600", color: "#000000" }}
      >
        #Moderate
      </div>
      <img src={LeftquotationIcon} alt="icon" />
      <div>
        <div className="px-4 sizeForText" style={{ textAlign: "center" }}>
          {alertDescription}
        </div>
        <div className="text-right">
          <img src={RightquotationIcon} alt="icon" />
        </div>
      </div>
    </div>
  );
};

export const AlertPostDisplayMild = ({ alertDescription, alertName }) => {
  return (
    <Fragment>
      <div
        className="pvt-event-cont-blk text-dark m-2"
        style={{ background: "#FFD600", fontWeight: "700" }}
      >
        <div className="pvt-eventcont" style={{ background: "#FF822E" }}>
          #Mild
        </div>
        <img src={LeftquotationIcon} alt="icon" />
        <div>
          <div className="px-4 sizeForText" style={{ textAlign: "center" }}>
            {alertDescription}
          </div>
          <div className="text-right">
            <img src={RightquotationIcon} alt="icon" />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export const AlertPostDisplaySevere = ({ alertDescription }) => {
  return (
    <div
      className="pvt-event-cont-blk text-white m-2"
      style={{ background: "#FF1010", fontWeight: "700" }}
    >
      <div className="pvt-eventcont" style={{ background: "#FF822E" }}>
        #Severe
      </div>
      <img src={LeftquotationIcon} alt="icon" />
      <div>
        <div className="px-4 sizeForText" style={{ textAlign: "center" }}>
          {alertDescription}
        </div>
        <div className="text-right">
          <img src={RightquotationIcon} alt="icon" />
        </div>
      </div>
    </div>
  );
};
//================== End_Alert_Post ========================//



//================== Start_Recommendation_Post ==============//

export const RecommendationPostDisplay = ({
  recommendation,
  recommendationCoverImageURL,
  totalRecommended,
  hashtags,
  txtColor,
  setCommentData,
  commentData,
  setRecommendPostStatus
}) => {


  function action() {
    setCommentData("I recommend ");
    setRecommendPostStatus(true)
  }


  return (
    <Fragment>
      <Row>
        <Col xl="12">
          <Card style={{ boxShadow: "none" }}>
            <div className="RecommendationMedia_blk">
              <img
                src={recommendationCoverImageURL}
                alt="singleMedia"
                className="img-fluid Caption_Img"
              />
              <div className="Recommendation_box_section ">
                <h3 className="ml-3">{totalRecommended} Recommendations </h3>
                <Button 
                  onClick={() => { action()}}
                color="white" className="btn btn-outline mr-3" outline>
                  Recommend
                </Button>
              </div>
            </div>
            <div
              className="SingleMedia_Heading_blk ml-3  on-hover-bg"
              style={{ paddingTop: "20px" }}
            >
              <h2>{recommendation}</h2>
            </div>
            <div className="mx-4 text-center">
              <div className="text-center">
                <h2 className="sizeForText" style={{ color: txtColor }}>
                  {hashtags &&
                    hashtags.map((tags) => {
                      return (
                        <a>
                          {" "}
                          <span key={tags.id}>#{tags.name} </span>{" "}
                        </a>
                      );
                    })}
                </h2>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};



export const RecmndNoImgPostDisplay = ({
  recommendation,
  totalRecommended,
  postId,
  userId,
  hashtags,
  txtColor,
  setCommentData,
  commentData,
  setRecommendPostStatus
}) => {

  let user = JSON.parse(localStorage.getItem("sociomeeUser"));

  function action() {
    setCommentData("I recommend ");
    setRecommendPostStatus(true)
  }

  useEffect(() => {}, [totalRecommended]);
 



  return (
    <Fragment>
      <Row>
        <Col xl="12">
          <Card style={{ boxShadow: "none" }}>
            <div className="RecmndNo_Imgbox_section">
              <h3 className="ml-3">{totalRecommended} Recommendations </h3>
              <Button
                onClick={() => {action()}}
                color="white"
                className="btn btn-outline mr-3"
                outline
              >
                Recommend
              </Button>
            </div>
            <div
              className="SingleMedia_Heading_blk ml-3"
              style={{ paddingTop: "20px" }}
            >
              <h2>{recommendation}</h2>
            </div>
            <div className="mx-4 text-center">
              <div className="text-center">
                <h3 className="sizeForText" style={{ color: "#91BC81" }}>
                  {hashtags &&
                    hashtags.map((tags) => {
                      return (
                        <a>
                          {" "}
                          <span key={tags.id}>#{tags.name} </span>{" "}
                        </a>
                      );
                    })}
                </h3>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
//================== End_Recommendation_Post =================//

//================== Start_ThroughtPostDisplay_Post ==========//
export const ThroughtPostDisplay = ({
  text,
  authorName,
  bgColor,
  txtColor,
  hashtags,
}) => {
  // const handleHashtagClick = (hashtag) => {
  //   // Handle the click event, e.g., navigate to a specific page or perform an action
  //   console.log('Hashtag clicked:', hashtag);
  // };

  // const renderTextWithClickableHashtags = () => {
  //   const hashtagRegex = /#(\w+)/g;

  //   return text.split(' ').map((word, index) => {
  //     const matches = word.match(hashtagRegex);

  //     if (matches) {
  //       // If the word contains a hashtag, make it clickable
  //       const hashtag = matches[0];
  //       const restOfWord = word.replace(hashtagRegex, '');

  //       return (
  //         <span key={index}>
  //           {restOfWord}
  //           <span
  //             style={{ color: '#007bff', cursor: 'pointer' }}
  //             onClick={() => handleHashtagClick(hashtag)}
  //           >
  //             {hashtag}
  //           </span>{' '}
  //         </span>
  //       );
  //     }

  //     // If the word does not contain a hashtag, render it as is
  //     return <span key={index}>{word} </span>;
  //   });
  // };

  return (
    <div
      style={{
        background: "#E0E0E0",
        borderRadius: "5px",
        paddingTop: "7px",
        paddingLeft: "35px",
        paddingRight: "35px",
        paddingBottom: "7px",
      }}
    >
      <div
        style={{
          marginTop: "2rem",
          marginBottom: "1rem",
          borderRadius: "10px",
          background: bgColor,
          color: "#F8DF88",
        }}
        className="p-3 position-relative"
      >
        <img
          src={LeftquotationIcon}
          className="LeftIconforthrought"
          alt="icon"
        />
        <div className="mx-4 text-center">
        <h3 style={{color:txtColor}}>{text}</h3>

          <div className="text-center">
            <h2 className="sizeForText" style={{ color: "#91BC81" }}>
              {hashtags &&
                hashtags.map((tags) => {
                  return (
                    <a>
                      {" "}
                      <span key={tags.id}>#{tags.name} </span>{" "}
                    </a>
                  );
                })}
            </h2>
          </div>
        </div>
        <div className="text-right">
          <img
            src={RightquotationIcon}
            alt="icon"
            className="RightIconforthrought"
          />
        </div>
      </div>
      <div className="text-right m-2 mb-3 ">
        <h3 className="text-white mr-0" style={{ fontSize: "14px" }}>
          {" "}
          - {authorName}
        </h3>
        <h4 className="mr-0" style={{ color: "#FF27C3", fontSize: "12px" }}>
          #thoughtoftheday
        </h4>
      </div>
    </div>
  );
};
//================== End_ThroughtPostDisplay_Post =============//

//================== End_All_Poll_Post ========================//

export const PollPostDisplayUnfilled = ({
  totalParticipants,
  question,
  pollOptions,
  postType,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isPolled, setIsPolled] = useState(postType.ispolled);
  const dispatch = useDispatch();
  const handleOptionSelect = async (postId, pollOptionId) => {
    // If the user has already voted (ispolled is 1), do nothing
    if (postType.ispolled === 1) {
      return;
    }

    try {
      const requestBody = {
        postId: postId,
        pollOptionId: pollOptionId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/selectUserPollOption`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("sociomeeUser"))?.token
            }`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setIsPolled(1);

        setSelectedOption(pollOptionId);
        dispatch(loadAllUserPosts());
      }
      console.log(response);
    } catch (error) {
      console.error("Error selectUserPollOption on poll post:", error);
    }
  };

  return (
    <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
      <div className="d-flex align-items-center">
        <img src={pollIcon} className="mr-1" alt="poll" />
        <div className="SingleMedia_Heading_blk ">
          <h3 style={{ fontSize: "14px" }}>{question}</h3>
        </div>
      </div>

      <h4 className="my-2 mb-3" style={{ color: "#999999", fontSize: "12px" }}>
        Total Participant: {totalParticipants}
      </h4>
      {pollOptions &&
        pollOptions
          .sort((a, b) => a.sequence - b.sequence)
          .map((item, index) => (
            <div
              key={item.pollOptionId}
              style={{ borderRadius: "20px", background: "#E5F0E0" }}
              className="p-2 mb-2 w-100"
              onClick={() =>
                handleOptionSelect(postType.postId, item.pollOptionId)
              }
            >
              <div className="d-flex justify-content-start align-items-center">
                <input
                  className="radio_animated ml-2"
                  type="radio"
                  id={`inputpoll_${item.pollOptionId}`}
                  checked={selectedOption === item.pollOptionId}
                  disabled={postType.ispolled === 1}
                />
                <label htmlFor={`inputpoll_${item.pollOptionId}`}>
                  <h3 style={{ fontSize: "14px" }}>{item.optionText}</h3>
                </label>
              </div>
            </div>
          ))}
    </div>
  );
};

export const PollPostDisplayfilled = ({
  question,
  totalParticipants,
  pollOptions,
  postType,
}) => {
  const { userProfileByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );
  let user = JSON.parse(localStorage.getItem("sociomeeUser"));

  //================== Mian_return_function ======================//
  return (
    <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
      <div className="d-flex align-items-center">
        <img src={pollIcon} className="mr-1" alt="poll" />
        <div className="SingleMedia_Heading_blk">
          <h2>{question}</h2>
        </div>
      </div>
      <h6 className="my-2 mb-3" style={{ color: "#999999", fontSize: "14px" }}>
        Total Participants: {totalParticipants}
      </h6>
      {pollOptions &&
        pollOptions
          .sort((a, b) => a.sequence - b.sequence)
          .map((item, index) => {
            const percentage =
              (item.totalVotesOnOption / item.totalVotesOnPoll) * 100;
  
            return (
              <div key={item.sequence} style={{ borderRadius: "20px", background: "#E5F0E0", position: "relative",  }} className="mb-2 w-100" >
                <div className="p-2"style={{ borderRadius: "20px", background: "#FFCC00", width: `${percentage}%`, }} >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-start align-items-center">
                      <input className="radio_animated ml-2"  type="radio" id={`inputpoll${item.sequence}`} checked={item.polled === 1} />
                      <h3 style={{ fontSize: "14px" }}>{item.optionText}</h3>
                    </div>
                  </div>
                </div>
                {user?.id !== postType?.userId ? (
                  <h3 style={{ position: "absolute",  right: "15px", top: "13px", fontSize: "14px", }} >
                    {percentage.toFixed(1)}% {/* Display the percentage */}
                  </h3>
                ) : (
                  <h3 style={{  position: "absolute", right: "15px", top: "13px", fontSize: "14px", }} >
                    {item.totalVotesOnOption} / {item.totalVotesOnPoll}
                  </h3>
                )}
              </div>
            );
          })}
    </div>
  );
};

//================== End_All_Poll_Post ========================//

//================== Start_Events_Post ========================//

export const EventPostDisplay = ({
  eventCoverImageURL,
  eventTitle,
  eventCategory,
  eventDate,
  eventStartTime,
  eventEndTime,
  eventLocation,
  eventDescription,
  postType,
  hashtags,
}) => {
 
 
 
  const [active, setActive] = useState("1");
  const toggle = (tab) => {
    setActive(tab);
  };

  const [forwardOpen, setForwardOpen] = useState(false);
  const toggleForward = () => {
    setForwardOpen((preState) => !preState);
  };

  // Custom format date function
  function formatCustomDate(date) {
    const options = {
      weekday: "short",
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  }
  
  // Custom format time function
  function formatCustomTime(date) {
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleTimeString("en-US", options);
  }
  
  // Parse dates assuming they are already in local time
  const startDate = moment(eventStartTime, "YYYY-MM-DD HH:mm:ss");
  const endDate = moment(eventEndTime, "YYYY-MM-DD HH:mm:ss");
  
  // Format the date and time using custom functions
  const formattedStartDate = formatCustomDate(startDate.toDate());
  const formattedEndDate = formatCustomDate(endDate.toDate());
  const formattedStartTime = formatCustomTime(startDate.toDate());
  const formattedEndTime = formatCustomTime(endDate.toDate());
  
  const [expanded, setExpanded] = useState(false);
  const [bgColor, setBgColor] = useState(""); // Initialize the background color state
  const [eventStatus, setEventStatus] = useState(null);
  const [eventGoingStatus,setEventGoingStatus] = useState(0)


useEffect(()=>{
  if(postType?.postEventGoingStatus==1){
    setEventStatus("Select an option")
    setEventGoingStatus(postType?.postEventGoingStatus)
  }
  else{
    setEventStatus(postType?.postEventStatus || "Select an option" )
  }

},[postType?.postEventStatus])


  const toggleReadMore = () => {
    setExpanded(!expanded);
  };

  // Function to handle selection
  const handleSelect = (selectedOption) => {
    setEventStatus(selectedOption);
    // Set the background color based on the selection
    setBgColor(selectedOption === "Interested" ? "#81C14B" : "orange");
  };


  //invite people modal open
  const [InviteOpen, setInviteOpen] = useState(false);
  const toggleInvite = () => {
    setInviteOpen(prev => !prev);
  };


    //==================>> api call event status change <<===================
  const changeEventStatus = async (eventStatus) => {
    try {
      const body = { id: postType?.postId, status: eventStatus };
      let user = JSON.parse(localStorage.getItem("sociomeeUser"));
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/post/changeEventStatus`,
        body,
        config
      );
      if (response?.data?.data?.successResult) {
        if (eventStatus === 'Going') {
          setEventStatus('Select an option');
          setEventGoingStatus(prev => !prev);
        } else {
          setEventStatus(eventStatus);
          setEventGoingStatus(false);
        }
              }
    } catch (error) {
      console.log("Error during Event status change:", error);
    }
  };

//stying for the button event status
  const goingbuttonStyle = {
    backgroundColor: eventGoingStatus ? "#81c14b" : "#f8f9fa",
    color: eventGoingStatus ? "#f8f9fa" : "#81c14b", // Changed TextColor to color
  };
  const selectOptionStyle = {
    backgroundColor: "#ffffff", // White background for "Select an option"
    color: "#81c14b", // Green text color for "Select an option"
    border: "1px solid #c4c4c4", // Light gray border for "Select an option"
  };

  const interestedStyle = {
    backgroundColor: "#81c14b", // Green background for "Interested"
    color: "#ffffff", // White text color for "Interested"
    border: "none", // No border for "Interested"
  };

  const notInterestedStyle = {
    backgroundColor: "orange", // Orange background for "Not Interested"
    color: "#ffffff", // White text color for "Not Interested"
    border: "none", // No border for "Not Interested"
  };

  const defaultStyle = {
    backgroundColor: "#81c14b", // Default green background
    color: "#f8f9fa", // Default light gray text color
    border: "1px solid #c4c4c4", // Light gray border
  };

  const dropdownStyle =
    eventStatus === "Select an option"
      ? selectOptionStyle
      : eventStatus === "Interested"
      ? interestedStyle
      : eventStatus === "notInterested"
      ? notInterestedStyle
      : defaultStyle;




  //================== Main_return_function ===================//
  const EventData = [
    {
      id: "1",
      Count: 10,
      typeOfEvnt: "Going",
    },
    {
      id: "2",
      Count: 48,
      typeOfEvnt: "Interested",
    },
    {
      id: "3",
      Count: 300,
      typeOfEvnt: "Not Interested ",
    },
    {
      id: "4",
      Count: 25,
      typeOfEvnt: "Invited",
    },
  ];
  return (
    <Fragment>
    <div className="Events_Card_blk">
      <img src={eventCoverImageURL} alt="Event Cover" />
    </div>
    <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "10px" }}>
      <div className="friend-request-btn text-center my-2 d-flex">
        <Button
          outline
          color="primary py-2 ml-3 px-4 FollowRequest_Reject_btn w-100"
          onClick={() => changeEventStatus("Going")}
          style={goingbuttonStyle}
        >
          <CheckCircle size={14} />
          &nbsp;Going
        </Button>

        <UncontrolledButtonDropdown className="w-100 ml-3">
          <DropdownToggle className="p-0 w-100" color="orange" style={dropdownStyle}>
            &nbsp; {eventStatus === 'notInterested' ? "Not Interested" : eventStatus} &nbsp;
            <ChevronDown size={14} />
          </DropdownToggle>
          <DropdownMenu className="socialFeed_Dropdown_EventMenu">
            <DropdownItem onClick={() => { handleSelect("Interested"); changeEventStatus("Interested"); }}>
              <Star size={14} />
              &nbsp;Interested
            </DropdownItem>
            <DropdownItem onClick={() => { handleSelect("Not Interested"); changeEventStatus("notInterested"); }}>
              <X size={14} />
              &nbsp;Not Interested
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>

        <Button
          outline
          color="primary py-2 ml-3 px-4 FollowRequest_Reject_btn w-100"
          onClick={toggleInvite}
        >
          <Mail size={14} />
          &nbsp;Invite
        </Button>
      </div>
      <div className="SingleMedia_Heading_blk">
        <h2>{eventTitle}</h2>
      </div>
      <h3 style={{ fontSize: "14px", color: "#808080", fontWeight: "400", margin: "0 0 4px" }}>
        {eventCategory}
      </h3>
      <div className="d-flex justify-content-between" style={{ margin: "0 0 4px" }}>
        <h3 style={{ fontSize: "14px", color: "#81C14B", fontWeight: "400" }}>
          {formattedStartDate} - {formattedEndDate}
        </h3>
        <h3 style={{ fontSize: "14px", color: "#81C14B", fontWeight: "400" }}>
          {formattedStartTime} - {formattedEndTime}
        </h3>
      </div>
      <a
        href={`https://www.google.com/maps/place/${eventLocation}`}
        style={{ cursor: "pointer", textDecoration: "underline", margin: "0 0 4px" }}
        className="text-muted"
      >
        <MapPin size={15} />
        &nbsp;{" "}
        <span className="Profile_Location" style={{ fontWeight: "400" }}>
          {eventLocation}
        </span>
      </a>
      <hr className="text-muted" />
      <div className="SingleMedia_Content_blk">
        <h3>
          {eventDescription.length > 300 ? 
            (expanded ? eventDescription : eventDescription.slice(0, 300)) :
            eventDescription
          }
          {eventDescription.length > 300 && (
            <span
              style={{ color: "#81C14B", cursor: "pointer" }}
              onClick={toggleReadMore}
            >
              {expanded ? " See less" : " ...Read more"}
            </span>
          )}
        </h3>
      </div>
    </div>
    <div className="mx-4 text-center">
      <div className="text-center">
        <h2 className="sizeForText" style={{ color: "#91BC81" }}>
          {hashtags && hashtags.map(tags => (
            <a key={tags.id}>
              <span>#{tags.name} </span>
            </a>
          ))}
        </h2>
      </div>
    </div>
    {InviteOpen && <InviteModal
                isOpen={InviteOpen}
                updateOpen={toggleInvite}
                eventId = {postType?.postId}

            />}
  </Fragment>
  );
};

{
  /* Events, by their very nature, are about connections. Through your
            event description, you
            {seemore
              ? "can boost attendance Events, by their very nature, are about connections. Through your"
              : null}{" "}
            bright event description, you can boost attendance or hosting of
            <span
              style={{ cursor: "pointer" }}
              className="text-primary"
              onClick={() => setSeeMore(!seemore)}
            >
              {seemore ? "...Read less" : "... Read more"}
            </span> */
}
//================== End_Events_Post ========================//

//==================article post display=====================//
export const ArticlePostDisplay = ({
  text,
  description,
  category,
  articleImg,
  createdDate,
}) => {
  const dateString = createdDate;

  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  const formattedDate = `${day} ${month}, ${year}`;

  const [forwardOpen, setForwardOpen] = useState(false);
  const toggleForward = () => {
    setForwardOpen((preState) => !preState);
  };

  const [expanded, setExpanded] = useState(false);

  const toggleReadMore = () => {
    setExpanded(!expanded);
  };

  // const EventData = [
  //   {
  //     id: "1",
  //     Count: 10,
  //     typeOfEvnt: "Going",
  //   },
  //   {
  //     id: "2",
  //     Count: 48,
  //     typeOfEvnt: "Interested",
  //   },
  //   {
  //     id: "3",
  //     Count: 300,
  //     typeOfEvnt: "Not Interested ",
  //   },
  //   {
  //     id: "4",
  //     Count: 25,
  //     typeOfEvnt: "Invited",
  //   },
  // ];
  return (
    <Fragment>
      <div className="Events_Card_blk">
        <img src={articleImg} alt="Evnt_iMG" />
      </div>
      <div
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "10px",
        }}
      >
        <div className="SingleMedia_Heading_blk">
          <h2>{text}</h2>
        </div>
        <div className="Suggestmember-blk py-2">
          <span
            className="text-capitalize"
            style={{
              color: "#888888",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            {formattedDate}
          </span>
          &nbsp; &nbsp; <Circle fill="#888888" color="#888888" size={6} />
          &nbsp; &nbsp;
          <span
            style={{
              color: "#888888",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            {category}
          </span>
        </div>

        <div className="SingleMedia_Content_blk">
          <h3>
            {expanded ? description : description.slice(0, 300)}
            {description.length > 300 && (
              <span
                style={{ color: "#81C14B", cursor: "pointer" }}
                onClick={toggleReadMore}
              >
                {expanded ? " See less" : " ...Read more"}
              </span>
            )}
          </h3>
        </div>
      </div>
    </Fragment>
  );
};

//========================article post end====================================//

//================== Start_Podcast_post =====================//

export const EpisodePodcast = () => {
  const [playbutton, setplaybutton] = useState(false);
  return (
    <Fragment>
      <div className="Paid_PodcastImg_blk">
        <img src={EpPd1Img} alt="paid" className="Paid_Img img-fluid" />
        <img
          className="SinglePodacastIcon"
          height={50}
          src={MainIcon}
          alt="PodcastIcon"
        />
        <img
          className=" d-flex justify-content-center align-items-center positionForWings"
          height={50}
          src={Wings}
          alt="Wings"
        />
        <div className="Paid_podcast_Top_section">
          <div className="d-flex justify-content-between align-items-start">
            <div className="Paid_Content_inner">
              <h3>International Work-life Podcast...</h3>
              <h4>
                International Politics &nbsp;{" "}
                <Circle fill="#D9D9D9" color="#D9D9D9" size={6} /> &nbsp; Total
                episodes (16)
              </h4>
              <h3>
                <img src={Mic} width={25} height={28} alt="micicon" />
                &nbsp;20.0k
              </h3>
            </div>
            <Button
              color="primary"
              className="px-4 py-2 FollowRequest_Accept_btn"
            >
              All episodes
            </Button>
            {/* <Button color="primary">All episodes</Button> */}
          </div>
        </div>
        <div className="Paid_podcast_bottom_section">
          <div className="paid_bottom_content_inner">
            <img src={paidEpImg} alt="userImg   " className="Ep_thum_img" />
            <div className="EpisodePc_User_dscrpn">
              <h3>
                The transition to the hybrid era has not only affected our was a
                Roaring Success of the story...
                <span style={{ color: "#81C14B", cursor: "pointer" }}>
                  see more
                </span>
              </h3>
              <div className="Eplist-blk-btn my-2">Episode - 5</div>
              <div className="d-flex justify-content-between ">
                <h4>12th Apr 2021, 5 mins</h4>
                <h3>
                  <img src={pdMic} alt="mic" width={22} height={22} />
                  &nbsp;5k
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
        }}
      >
        <div className="d-flex align-items-center justify-content-between">
          {playbutton ? (
            <Play onClick={() => setplaybutton(!playbutton)} fill="#000000" />
          ) : (
            <Pause fill="#000000" onClick={() => setplaybutton(!playbutton)} />
          )}
          &nbsp;&nbsp;
          <Progress
            className="sizeForpro progressFortimeLine progress-bar-success w-100"
            value={85}
          />
          &nbsp;&nbsp;
          <small>02:45</small>
        </div>
        <div className="SingleMedia_Content_blk mt-2">
          <h3>
            Episodes explore real and imagined horror, with a typical runtime of
            one to two hours. The show releases two episodes weekly, a main
            episode and a side story episode. Main episodes, hosted by Ben
            Kissel, Marcus Parks and Henry Zebrowski, are focused on a singular
            topic and multiple episodes may be dedicated to one topic.
            <span style={{ color: "#81C14B", cursor: "pointer" }}>
              ...Read more
            </span>
          </h3>
        </div>
      </div>
    </Fragment>
  );
};

export const SngleUploadPcPost = () => {
  const [playbutton, setplaybutton] = useState(false);
  return (
    <Fragment>
      <div className="Paid_PodcastImg_blk">
        <img src={FreeSeriesImg} alt="paid" className="Paid_Img img-fluid" />
        <img
          className="SinglePodacastIcon"
          height={50}
          src={MainIcon}
          alt="PodcastIcon"
        />
        <img
          className=" d-flex justify-content-center align-items-center positionForWings"
          height={50}
          src={Wings}
          alt="Wings"
        />
        <div className="Paid_podcast_bottom_section">
          <div className="paid_bottom_content_inner">
            <div className="SingleUpload_User_dscrpn">
              <h3>
                The transition to the hybrid era has not only affected our ways
                of viewing leader throughout the prison to ...
                <span style={{ color: "#81C14B", cursor: "pointer" }}>
                  see more
                </span>
              </h3>
              <div className="Eplist-blk-btn my-2">Uploaded Media</div>
              <div className="d-flex justify-content-between ">
                <h4>12th Apr 2021, 5 mins</h4>
                <h3>
                  <img src={pdMic} alt="mic" width={22} height={22} />
                  &nbsp;20.0k
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
        }}
      >
        <div className="d-flex align-items-center justify-content-between">
          {playbutton ? (
            <Play onClick={() => setplaybutton(!playbutton)} fill="#000000" />
          ) : (
            <Pause fill="#000000" onClick={() => setplaybutton(!playbutton)} />
          )}
          &nbsp;&nbsp;
          <Progress
            className="sizeForpro progressFortimeLine progress-bar-success w-100"
            value={75}
          />
          &nbsp;&nbsp;
          <small>02:45</small>
        </div>
        <div className="SingleMedia_Content_blk mt-2">
          <h3>
            The Indian Ocean is the third-largest of the world's five oceanic
            divisions, covering 70,560,000 km2 (27,240,000 sq mi) or ~19.8% of
            the water on Earth's surface.[4] It is bounded by Asia to the north,
            Africa to the west and Australia to the east. To the south it is
            bounded by the Southern Ocean or Antarctica, depending on the
            definition in use.[5] Along its core, the Indian Ocean has some
            large marginal or regional seas such as the Arabian Sea, Laccadive
            Sea, Bay of Bengal and Andaman Sea.
            <span style={{ color: "#81C14B", cursor: "pointer" }}>
              ...Read more
            </span>
          </h3>
        </div>
      </div>
    </Fragment>
  );
};

export const DfltUploadPcPost = () => {
  const [playbutton, setplaybutton] = useState(false);
  return (
    <Fragment>
      <div className="Paid_PodcastImg_blk">
        <img
          src={DefaultpodcastImg}
          alt="paid"
          className="Paid_Img img-fluid"
        />
        <img
          className="SinglePodacastIcon"
          height={50}
          src={MainIcon}
          alt="PodcastIcon"
        />
        <img
          className=" d-flex justify-content-center align-items-center positionForWings"
          height={50}
          src={Wings}
          alt="Wings"
        />
        <div className="Paid_podcast_bottom_section">
          <div className="paid_bottom_content_inner">
            <div className="SingleUpload_User_dscrpn">
              <h3>
                The transition to the hybrid era has not only affected our ways
                of viewing leader throughout the prison to ...
                <span style={{ color: "#81C14B", cursor: "pointer" }}>
                  see more
                </span>
              </h3>
              <div className="Eplist-blk-btn my-2">Default Media</div>
              <div className="d-flex justify-content-between ">
                <h4>12th Apr 2021, 5 mins</h4>
                <h3>
                  <img src={pdMic} alt="mic" width={22} height={22} />
                  &nbsp;20.0k
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
        }}
      >
        <div className="d-flex align-items-center justify-content-between">
          {playbutton ? (
            <Play onClick={() => setplaybutton(!playbutton)} fill="#000000" />
          ) : (
            <Pause fill="#000000" onClick={() => setplaybutton(!playbutton)} />
          )}
          &nbsp;&nbsp;
          <Progress
            className="sizeForpro progressFortimeLine progress-bar-success w-100"
            value={75}
          />
          &nbsp;&nbsp;
          <small>02:45</small>
        </div>
        <div className="SingleMedia_Content_blk mt-2">
          <h3>
            The Indian Ocean is the third-largest of the world's five oceanic
            divisions, covering 70,560,000 km2 (27,240,000 sq mi) or ~19.8% of
            the water on Earth's surface.[4] It is bounded by Asia to the north,
            Africa to the west and Australia to the east. To the south it is
            bounded by the Southern Ocean or Antarctica, depending on the
            definition in use.[5] Along its core, the Indian Ocean has some
            large marginal or regional seas such as the Arabian Sea, Laccadive
            Sea, Bay of Bengal and Andaman Sea.
            <span style={{ color: "#81C14B", cursor: "pointer" }}>
              ...Read more
            </span>
          </h3>
        </div>
      </div>
    </Fragment>
  );
};

export const PaidPodcastPost = () => {
  const [playbutton, setplaybutton] = useState(false);
  return (
    <Fragment>
      <div className="Paid_PodcastImg_blk">
        <img src={PodcastImg} alt="paid" className="Paid_Img img-fluid" />
        <img
          className="SinglePodacastIcon"
          height={50}
          src={MainIcon}
          alt="PodcastIcon"
        />
        <img
          className=" d-flex justify-content-center align-items-center positionForWings"
          height={50}
          src={Wings}
          alt="Wings"
        />
        <div className="Paid_podcast_Top_section">
          <div className="d-flex justify-content-between align-items-start">
            <div className="Paid_Content_inner">
              <h3>International Work-life Podcast...</h3>
              <h4>
                International Politics &nbsp;{" "}
                <Circle fill="#D9D9D9" color="#D9D9D9" size={6} /> &nbsp; Total
                episodes (16)
              </h4>
              <h3>
                <img src={Mic} width={25} height={28} alt="micicon" />
                &nbsp;20.0k
              </h3>
            </div>
            <Button
              color="primary"
              className="px-4 py-2 FollowRequest_Accept_btn"
            >
              All episodes
            </Button>
            {/* <Button color="primary">All episodes</Button> */}
          </div>
        </div>
        <div className="Paid_podcast_bottom_section">
          <div className="paid_bottom_content_inner">
            <img src={paidEpImg} alt="userImg   " className="Ep_thum_img" />
            <div className="paid_User_dscrpn">
              <h3>
                The transition to the hybrid era has not only affected our was a
                Roaring Success of the story...
                <span style={{ color: "#81C14B", cursor: "pointer" }}>
                  see more
                </span>
              </h3>
              <div className="Eplist-blk-btn my-2">Episode - 5</div>
              <div className="d-flex justify-content-between ">
                <h4>12th Apr 2021, 5 mins</h4>
                <h3 className="mr-4">
                  <img src={pdMic} alt="mic" width={22} height={22} />
                  &nbsp;20.0k
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="Paid_bottom_tag">
          <img src={pdTag} alt="paid" />
        </div>
      </div>
      <div
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
        }}
      >
        <div className="d-flex align-items-center justify-content-between">
          {playbutton ? (
            <Play onClick={() => setplaybutton(!playbutton)} fill="#000000" />
          ) : (
            <Pause fill="#000000" onClick={() => setplaybutton(!playbutton)} />
          )}
          &nbsp;&nbsp;
          <Progress
            className="sizeForpro progressFortimeLine progress-bar-success w-100"
            value={35}
          />
          &nbsp;&nbsp;
          <small>02:45</small>
        </div>
        <div className="SingleMedia_Content_blk mt-2">
          <h3>
            Psychology is the scientific study of mind and behavior in humans
            and non-humans. Psychology includes the study of conscious and
            unconscious phenomena, including feelings and thoughts. It is an
            academic discipline of immense scope, crossing the boundaries
            between the natural and social sciences. Psychologists seek an
            understanding of the emergent properties of brains, linking the
            discipline to neuroscience. As social scientists, psychologists aim
            to understand the behavior of individuals and groups.[1][2] Ψ (psi),
            the first letter of the Greek word psyche from which the term
            psychology is derived (see below), is commonly associated with the
            science.
            <span style={{ color: "#81C14B", cursor: "pointer" }}>
              ...Read more
            </span>
          </h3>
        </div>
      </div>
    </Fragment>
  );
};

export const PaidSnglePcPost = () => {
  const [playbutton, setplaybutton] = useState(false);
  return (
    <Fragment>
      <div className="Paid_PodcastImg_blk">
        <img src={snglePcImg} alt="paid" className="Paid_Img img-fluid" />
        <img
          className="SinglePodacastIcon"
          height={50}
          src={MainIcon}
          alt="PodcastIcon"
        />
        <img
          className=" d-flex justify-content-center align-items-center positionForWings"
          height={50}
          src={Wings}
          alt="Wings"
        />
        <div className="Paid_podcast_bottom_section">
          <div className="paid_bottom_content_inner">
            <div className="Singlepaid_User_dscrpn">
              <h3>
                The transition to the hybrid era has not only affected our ways
                of viewing leader throughout the prison to ...
                <span style={{ color: "#81C14B", cursor: "pointer" }}>
                  see more
                </span>
              </h3>
              <div className="Eplist-blk-btn my-2">International Politics</div>
              <div className="d-flex justify-content-between ">
                <h4>12th Apr 2021, 5 mins</h4>
                <h3 className="mr-4">
                  <img src={pdMic} alt="mic" width={22} height={22} />
                  &nbsp;20.0k
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="Paid_bottom_tag">
          <img src={pdTag} alt="paid" />
        </div>
      </div>
      <div
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
        }}
      >
        <div className="d-flex align-items-center justify-content-between">
          {playbutton ? (
            <Play onClick={() => setplaybutton(!playbutton)} fill="#000000" />
          ) : (
            <Pause fill="#000000" onClick={() => setplaybutton(!playbutton)} />
          )}
          &nbsp;&nbsp;
          <Progress
            className="sizeForpro progressFortimeLine progress-bar-success w-100"
            value={60}
          />
          &nbsp;&nbsp;
          <small>02:45</small>
        </div>
        <div className="SingleMedia_Content_blk mt-2">
          <h3>
            India, officially the Republic of India (ISO: Bhārat Gaṇarājya),[25]
            is a country in South Asia. It is the seventh-largest country by
            area, the most populous country as of 1 May 2023, and the most
            populous democracy in the world.[26][27] Bounded by the Indian Ocean
            on the south, the Arabian Sea on the southwest, and the Bay of
            Bengal on the southeast, it shares land borders with Pakistan to the
            west;[j] China, Nepal, and Bhutan to the north; and Bangladesh and
            Myanmar to the east.
            <span style={{ color: "#81C14B", cursor: "pointer" }}>
              ...Read more
            </span>
          </h3>
        </div>
      </div>
    </Fragment>
  );
};

export const PaidSeriesPcPost = () => {
  const [playbutton, setplaybutton] = useState(false);
  return (
    <Fragment>
      <div className="Paid_PodcastImg_blk">
        <img src={PaidSeriesPcImg} alt="paid" className="Paid_Img img-fluid" />
        <img
          className="SinglePodacastIcon"
          height={50}
          src={MainIcon}
          alt="PodcastIcon"
        />
        <img
          className=" d-flex justify-content-center align-items-center positionForWings"
          height={50}
          src={Wings}
          alt="Wings"
        />
        <div className="Paid_podcast_Top_section">
          <div className="d-flex justify-content-between align-items-center">
            <div className="Paid_Content_inner d-flex justify-content-between align-items-start">
              <div className="Singlepaid_User_dscrpn ml-4">
                <h3>
                  The transition to the hybrid era has not only affected our
                  ways of viewing leader throughout the prison to ...
                  <span style={{ color: "#81C14B", cursor: "pointer" }}>
                    see more
                  </span>
                </h3>
              </div>
              <h3 className="d-flex">
                <img src={pdMic} alt="mic" width={22} height={22} />
                &nbsp;20.0k
              </h3>
            </div>
          </div>
        </div>
        <div className="SeriesPaid_podcast_Top_section">
          <img src={SeriesPcImg} alt="seriesPd" />
        </div>
        <div className="Paid_podcast_bottom_section">
          <div className="paidSeries_bottom_content_inner">
            <div className="d-flex align-items-center">
              <img
                src={paidEpImg}
                alt="userImg   "
                className="EpSeries_thum_img"
              />
              <div className="PaidSeries_count">+16</div>
              <div className="paidSeries_User_dscrpn">
                <h3>Total episodes (16)</h3>
                <div className="Eplist-blk-btn my-2">
                  International Politics
                </div>
              </div>
            </div>
            <Button
              color="primary"
              className="px-4 py-2 FollowRequest_Accept_btn"
            >
              All episodes
            </Button>
            {/* <Button color="primary">All episodes</Button> */}
          </div>
        </div>
      </div>
      <div
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
        }}
      >
        <div className="d-flex align-items-center justify-content-between">
          {playbutton ? (
            <Play onClick={() => setplaybutton(!playbutton)} fill="#000000" />
          ) : (
            <Pause fill="#000000" onClick={() => setplaybutton(!playbutton)} />
          )}
          &nbsp;&nbsp;
          <Progress
            className="sizeForpro progressFortimeLine progress-bar-success w-100"
            value={60}
          />
          &nbsp;&nbsp;
          <small>02:45</small>
        </div>
        <div className="SingleMedia_Content_blk mt-2">
          <h3>
            Mahendra Singh Dhoni (/məˈheɪndrə ˈsɪŋ dhæˈnɪ/ (listen); born 7 July
            1981), commonly known as MS Dhoni, is a former Indian cricketer and
            captain of the Indian national team in limited-overs formats from
            2007 to 2017 and in Test cricket from 2008 to 2014, who plays as a
            Wicket-keeper-Batsman. He is also the current captain of Chennai
            Super Kings in the Indian Premier League. He is the first cricketer
            to be a winning captain of all three major ICC trophies, as under
            his captaincy, India won the 2007 ICC World Twenty20, the 2011
            Cricket World Cup, and the 2013 ICC Champions Trophy, the most by
            any captain.
            <span style={{ color: "#81C14B", cursor: "pointer" }}>
              ...Read more
            </span>
          </h3>
        </div>
      </div>
    </Fragment>
  );
};

export const FreeSeriesPcPost = () => {
  const [playbutton, setplaybutton] = useState(false);
  return (
    <Fragment>
      <div className="Paid_PodcastImg_blk">
        <img src={FreeSeriesImg} alt="paid" className="Paid_Img img-fluid" />
        <img
          className="SinglePodacastIcon"
          height={50}
          src={MainIcon}
          alt="PodcastIcon"
        />
        <img
          className=" d-flex justify-content-center align-items-center positionForWings"
          height={50}
          src={Wings}
          alt="Wings"
        />
        <div className="Paid_podcast_Top_section">
          <div className="d-flex justify-content-between align-items-center">
            <div className="Paid_Content_inner d-flex justify-content-between align-items-start">
              <div className="Singlepaid_User_dscrpn ">
                <h3>
                  The transition to the hybrid era has not only affected our
                  ways of viewing leader throughout the prison to ...
                  <span style={{ color: "#81C14B", cursor: "pointer" }}>
                    see more
                  </span>
                </h3>
              </div>
              <h3 className="d-flex">
                <img src={pdMic} alt="mic" width={22} height={22} />
                &nbsp;20.0k
              </h3>
            </div>
          </div>
        </div>
        <div className="Paid_podcast_bottom_section">
          <div className="paidSeries_bottom_content_inner">
            <div className="d-flex align-items-center">
              <img
                src={paidEpImg}
                alt="userImg   "
                className="EpSeries_thum_img"
              />
              <div className="PaidSeries_count">+16</div>
              <div className="paidSeries_User_dscrpn">
                <h3>Total episodes (16)</h3>
                <div className="Eplist-blk-btn my-2">
                  International Politics
                </div>
              </div>
            </div>
            <Button
              color="primary"
              className="px-4 py-2 FollowRequest_Accept_btn"
            >
              All episodes
            </Button>
            {/* <Button color="primary">All episodes</Button> */}
          </div>
        </div>
      </div>
      <div
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
        }}
      >
        <div className="d-flex align-items-center justify-content-between">
          {playbutton ? (
            <Play onClick={() => setplaybutton(!playbutton)} fill="#000000" />
          ) : (
            <Pause fill="#000000" onClick={() => setplaybutton(!playbutton)} />
          )}
          &nbsp;&nbsp;
          <Progress
            className="sizeForpro progressFortimeLine progress-bar-success w-100"
            value={60}
          />
          &nbsp;&nbsp;
          <small>02:45</small>
        </div>
        <div className="SingleMedia_Content_blk mt-2">
          <h3>
            The Indian Ocean is the third-largest of the world's five oceanic
            divisions, covering 70,560,000 km2 (27,240,000 sq mi) or ~19.8% of
            the water on Earth's surface.[4] It is bounded by Asia to the north,
            Africa to the west and Australia to the east. To the south it is
            bounded by the Southern Ocean or Antarctica, depending on the
            definition in use.[5] Along its core, the Indian Ocean has some
            large marginal or regional seas such as the Arabian Sea, Laccadive
            Sea, Bay of Bengal and Andaman Sea.
            <span style={{ color: "#81C14B", cursor: "pointer" }}>
              ...Read more
            </span>
          </h3>
        </div>
      </div>
    </Fragment>
  );
};
//================== End_Podcast_post =======================//

//================== start_MarketPlace_Post ==================//
export const MarketPlacePost = () => {
  return (
    <Fragment>
      <div className="Mp_card_blk">
        <img src={MpImg1} alt="mp-img" />
      </div>
      <div
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
        }}
      >
        <div className="SingleMedia_Heading_blk">
          <h2>Wooden Horse Sculpture on sale</h2>
        </div>
        <div className="Mp_Category_blk">
          <h4>Home & Decor | Sculpture </h4>
          <h4 className="mp_main_location py-3">
            <MapPin size={14} color="#808080" />
            &nbsp;
            <span>Danish nagar Bagmugaliya, Bhopal, Madhya Pradesh 462026</span>
          </h4>
        </div>
      </div>
      <div className="Mp_Action_section">
        <h1>$ 41 (USD)</h1>
        <div className="d-flex">
          <Button
            color="primary"
            className="px-4 py-2 FollowRequest_Accept_btn"
          >
            <MessageCircle size={14} /> Message
          </Button>
          <Button color="primary py-2 ml-3 px-4 FollowRequest_Reject_btn ">
            <PhoneCall size={14} /> Call
          </Button>
        </div>
      </div>
      <div
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
        }}
      >
        <div className="SingleMedia_Content_blk">
          <h3>
            Happy brirthday{" "}
            <span style={{ color: "#81C14B" }}>@DwyaneJhonson</span>
            &nbsp;Hi he is my cool dad. Today is his birthday and I gifted him
            this amazing mobile and headphone
            <span style={{ color: "#81C14B", cursor: "pointer" }}>
              ...Read more
            </span>
          </h3>
        </div>
      </div>
    </Fragment>
  );
};

export const MarketPlaceSngleAction = () => {
  return (
    <Fragment>
      <div className="Mp_card_blk">
        <img src={MpImg1} alt="mp-img" />
      </div>
      <div
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
        }}
      >
        <div className="SingleMedia_Heading_blk">
          <h2>Wooden Horse Sculpture on sale</h2>
        </div>
        <div className="Mp_Category_blk">
          <h4>Home & Decor | Sculpture </h4>
          <h4 className="mp_main_location py-3">
            <MapPin size={14} color="#808080" />
            &nbsp;
            <span>Danish nagar Bagmugaliya, Bhopal, Madhya Pradesh 462026</span>
          </h4>
        </div>
      </div>
      <div className="Mp_Action_section">
        <h1>$ 41 (USD)</h1>
        <div className="d-flex">
          {/* <Button color='primary' className='px-4 py-2 FollowRequest_Accept_btn' ><MessageCircle size={14} /> Message</Button> */}
          <Button color="primary py-2 ml-3 px-4 FollowRequest_Reject_btn ">
            <PhoneCall size={14} /> Call
          </Button>
        </div>
      </div>
      <div
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "20px",
        }}
      >
        <div className="SingleMedia_Content_blk">
          <h3>
            Happy brirthday{" "}
            <span style={{ color: "#81C14B" }}>@DwyaneJhonson</span>
            &nbsp;Hi he is my cool dad. Today is his birthday and I gifted him
            this amazing mobile and headphone
            <span style={{ color: "#81C14B", cursor: "pointer" }}>
              ...Read more
            </span>
          </h3>
        </div>
      </div>
    </Fragment>
  );
};
//================== start_MarketPlace_Post ==================//

//================== start_ReShare_Post ======================//
export const ReSharePostType = ({ postType }) => {
  const [reshareFullName, setReshareFullName] = useState(null);
  const [reshareProfileImage, setReshareProfileImage] = useState(null);
  const [resharePostType, setResharePostType] = useState(null);
  const [reshareCaption, setReshareCaption] = useState(null);

  useEffect(() => {
    handleFullname(postType);
  }, []);
  const handleFullname = (postType) => {
    if (postType && postType.reSharedPost && postType.reSharedPost.length > 0) {
      postType.reSharedPost.forEach((reSharedPost) => {
        setReshareFullName(reSharedPost.fullName);
        setReshareProfileImage(reSharedPost.profileImageThumb);
        setReshareCaption(reSharedPost.caption);
      });
    } else {
      console.warn("postType is undefined or doesn't have reSharedPost");
    }
  };

  return (
    <Fragment>
      {/* <div style={{ paddingLeft: "20px", paddingRight: "20px" }}> */}
      <div>
        <div className="SingleMedia_Content_blk">
          <h3>{postType.caption}</h3>
        </div>
        <Card
          className="mt-3"
          style={{ border: "1px solid rgba(0, 0, 0, 0.1)", boxShadow: "none" }}
        >
          <CardHeader className="bg-white border-bottom-0 w-100">
            <div className="d-flex justify-content-between align-items-start py-2 mr-2">
              <Link to={"/MyProfile"} className="d-flex align-items-start">
              <img
                src={reshareProfileImage}
                className="rounded-circle border"
                width={40}
                height={40}
                alt="UserImage"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = 'https://sociomee-pro.s3.eu-central-1.amazonaws.com/logo/O79wh8XUHQ5kv.png'; 
                }}
              />

                <div className="ml-2 PostPreView_User_Info">
                  <div>
                    <h2 className="text-wrap">
                      <span className="UserProfile-Name">
                        {reshareFullName}
                      </span>{" "}
                      &nbsp;
                      <span
                        style={{
                          color: "rgba(77, 77, 77, 0.5)",
                          fontSize: "14px",
                        }}
                      >
                        is 😀 feeling happy with
                      </span>
                      &nbsp;
                      <span style={{ fontSize: "14px" }}>Bessie Coope</span>
                      &nbsp;
                      <span
                        style={{
                          color: "rgba(77, 77, 77, 0.5)",
                          fontSize: "14px",
                        }}
                      >
                        and
                      </span>
                      &nbsp;
                      <span style={{ fontSize: "14px" }}>19 others</span>
                      &nbsp;
                      <span
                        style={{
                          color: "rgba(77, 77, 77, 0.5)",
                          fontSize: "14px",
                        }}
                      >
                        in
                      </span>
                      &nbsp;
                      <span
                        className="Profile_Location"
                        style={{ fontWeight: "400" }}
                      >
                        354 Sector B, Ali Ganj, Vibhuti Khand Indira Nagar,
                        Lucknow, India - 226012...
                      </span>
                    </h2>
                  </div>
                  <h4>20 Min ago</h4>
                </div>
              </Link>
            </div>
          </CardHeader>
          <CardBody className="Post_Dispaly_Card_body">
            <div
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                paddingBottom: "20px",
              }}
            >
              <div className=" Text_Card_blk">
                <h3>Celebration new album song launched</h3>
                <div className="mt-2">
                  <p className="my-2">
                    <b style={{ color: "#81c14b" }}>
                      #Musiccelebration, #Musiccelebration, #music, #party,
                      #music{" "}
                    </b>
                  </p>
                  <div className="Text_Card_blk">
                    <h3> {reshareCaption}</h3>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </Fragment>
  );
};
//================== End_ReShare_Post ========================//
