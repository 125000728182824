import React, { Fragment, useState, useEffect } from 'react';
import { ArrowLeft, XCircle } from 'react-feather';
import Slider from 'react-slick';
import { Col, Input, Media, Row } from 'reactstrap';
import LeftquotationIcon from '../../../NewComponents/IMG/LeftquotationIcon.svg';

const EditAllMedia = ({ toggle, selectedImages ,captions,setCaptions}) => {
  const [filterChoosed, setFilterChoosed] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);



  const handleCaptionChange = (index, e) => {
    const newCaptions = [...captions];
    newCaptions[index] = e.target.value;
    setCaptions(newCaptions);
  };



  useEffect(() => {
    selectedImages.length >0 && selectedImages.forEach((key) => {
      const value = sessionStorage.getItem(key);
      if (value && value.startsWith('data:image')) {
        sessionStorage.removeItem(key);
      }
    });
  }, [selectedImages]);



  //=============== Slider settings ===============//
  let bizSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setCurrentSlide(current), // Update currentSlide on slide change
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  // Filter grid
  const FilterGrid = [
    { filters: 'none', filtersName: 'None' },
    { filters: 'sepia(0.5) saturate(1.2) hue-rotate(-10deg)', filtersName: 'Vintage' },
    { filters: 'contrast(1.2) saturate(1.2)', filtersName: 'Bold' },
    { filters: 'contrast(1.1) brightness(1.1) saturate(1.2) hue-rotate(-10deg)', filtersName: 'Crisp' },
    { filters: 'grayscale(1) contrast(1.2) brightness(0.9) sepia(0.3)', filtersName: 'Noir' },
    { filters: 'contrast(1.2) saturate(1.5) hue-rotate(-20deg)', filtersName: 'Pop' },
    { filters: 'sepia(0.4) saturate(1.5) hue-rotate(20deg)', filtersName: 'Warm' },
    { filters: 'brightness(1.1) contrast(0.9) saturate(0.8) sepia(0.2)', filtersName: 'Pastel' },
    { filters: 'contrast(0.9) brightness(0.9) saturate(0.8) grayscale(1) sepia(0.2)', filtersName: 'Matte' },
    { filters: 'sepia(0.2) hue-rotate(90deg)', filtersName: 'Monochrome' },
    { filters: 'sepia(0.4) saturate(1.5) hue-rotate(-20deg)', filtersName: 'Cool' },
    { filters: 'contrast(0.9) brightness(1.1) saturate(0.9) sepia(0.3) blur(1px)', filtersName: 'Retro' }
  ];


  // const removeFromSession = (index) => {
  //   const updatedImages = selectedImages.filter((_, i) => i !== index);
  // };



  


  // ============================>>> main function <<<============================

  return (
    <Fragment>
      <div className='border-bottom w-100'>
        <div className={`mx-3 py-3 FellingActivity_main_blk`}>
          <div onClick={() => toggle('/modal/0.1')} style={{ cursor: "pointer" }} className='Arrow_main_blk_section rounded-circle'>
            <ArrowLeft />
          </div>
          <div>
            <h3>Photos/Video</h3>
          </div>
          <div></div>
        </div>
      </div>
      <Row className='mx-3'>
        <Col sm={7} className="border-right py-3">
        <Slider {...bizSettings} className="default-space">
          {selectedImages.length > 0 && selectedImages.map((media, index) => {
            // Check the media extension
            const fileExtension = media.split('.').pop().toLowerCase();
            const videoFormats = ["mp4", "mpg", "mp2", "mpeg", "mpe", "mpv", "ogg", "m4p", "m4v"];
            const imageFormats = ["png", "jpg", "jpeg", "gif", "tiff", "psd","pdf", "eps", "ai", "indd", "raw", "jfif"];

            const isVideo = videoFormats.includes(fileExtension);
            const isImage = imageFormats.includes(fileExtension);

            return (
              <div key={index} className="BizPage_Card_section">
                <div className="BizPage_option_menu">
                  {/* <XCircle color='#ffff' onClick={() => removeFromSession(index)} /> */}
                </div>
                <div className='w-100'>
                  {/* Render video or image based on the file extension */}
                  {isVideo ? (
                    <video className="w-100" controls style={{ aspectRatio: "16/9", filter: filterChoosed }}>
                      <source src={process.env.REACT_APP_IMAGEURL + media} type={`video/${fileExtension}`} />
                    </video>
                  ) : isImage ? (
                    <img
                      className="w-100"
                      src={process.env.REACT_APP_IMAGEURL + media}
                      style={{ aspectRatio: "16/9", filter: filterChoosed }}
                      alt={`Media ${index}`}
                    />
                  ) : (
                    <p>Unsupported file format</p>
                  )}
                </div>
                <div className="py-4 px-3">
                  <Input type="text" placeholder="Caption"  value = {captions[index] || null} onChange={ (e) => handleCaptionChange(index, e)} />
                  <p className='Post_creation_text_limt mt-1 text-end'>Max 64 characters</p>
                </div>
              </div>
            );
          })}
        </Slider>

        </Col>
        {/* <Col sm={5} className="py-3">
          <div className='mb-2'>
            <h3>Filter</h3>
          </div>
          <div className='filter_scroll_box ' height={window.innerHeight / 420}>
            <div className='Filter_Img_Blk'>
              {FilterGrid.map((data, index) => (
                <div key={index} className='FilterImages d-flex flex-column'>
                  <div className='' style={{cursor:"pointer"}}>
                    <img
                      src={process.env.REACT_APP_IMAGEURL + selectedImages[currentSlide]} // Apply filter to the current slide's image
                      style={{ filter: data.filters }}
                      onClick={() => setFilterChoosed(data.filters)}
                      alt="filter"
                      cursor="pointer"
                      className='Main_filter_img'
                    />
                  </div>
                  <h3 className='text-center'>{data.filtersName}</h3>
                </div>
              ))}
            </div>
          </div>
        </Col> */}
      </Row>
    </Fragment>
  );
};

export default EditAllMedia;
