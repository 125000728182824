import React, { useEffect, useState } from 'react';
import { Copy, Flag, MoreVertical, PlusCircle, Share, UserMinus, MinusCircle } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown, Row,Col} from 'reactstrap';
import { loadAllUserFollowing } from '../../../Services/Actions/Connection/getUserFollowingAction';
import {loadAllFollowUnFollow} from '../../../Services/Actions/Connection/getFollowUnFollowAction'
import india_svg from '../../../NewComponents/IMG/india_svg.svg';
import BlockModal from '../ConnectionModal/BlockModal';
import AddCategoryModal from '../ConnectionModal/AddCategoryModal';
import ConnectionReportModal from '../ConnectionModal/ConnectionReportModal';
import ConnectionShareModal from '../ConnectionModal/ConnectionShareModal';
import UnfollowModal from '../ConnectionModal/UnfollowModal';
import { MutualFriendList } from '../HoverComponents/MutualFriendList';
import '../Style.css'
import PublicAccount from '../ConnectionModal/PublicAccount';
import { NavLink } from 'react-router-dom';

export const FollowingList = ({followingData}) => {
    //const { getUserFollowing } = useSelector(state => state.GetUserFollowingData)
    const dispatch = useDispatch();
    const [selectedRequest, setSelectedRequest] = useState(null);
    useEffect(() => {
        dispatch(loadAllUserFollowing())
    }, [])

    // ------------- share Modal State -------------U
    const [userId, setUserId] =useState("")
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = (id) => {
        setShareOpen((prevState) => !prevState)
        setUserId(id)
    }
    //  ------------- Start Add Category State -------------
    const [addCategoryOptions, setAddCategoryOptions] = useState(false)
    const toggleAddCategory = () => {
        setAddCategoryOptions((preState) => !preState)
    }
    // ------------- Unfollow Modal State -------------

    const [unfollowModalOpen, setUnfollowModalOpen] = useState(false);
    const toggleUnfollowModal = (request) => {
        setUnfollowModalOpen((prevState) => !prevState);
        setSelectedRequest(request)  
    };
 
     //copy prorofile url
    const copyProfileURL = (userId) => {
         
         const profileURL = window.location.host + `/user/profileinfo?id=${userId}`;
     
         navigator.clipboard.writeText(profileURL)
             .then(() => {
                 console.log('Profile URL copied to clipboard:', profileURL);
                 alert("copied profile url new ",profileURL);
             })
             .catch((error) => {
                 console.error('Failed to copy profile URL:', error);
             });
     };
 
    //  ------------- Start Reported Option State  -------------
    // Reported Option State
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = (request) => {
        setReportOpen((preState) => !preState)
        setSelectedRequest(request)   
    }

    const[reportConfirm,setReportConfirm] = useState(false)
    useEffect(()=>{
        if(reportConfirm){
            setTimeout(() =>{
                dispatch(loadAllUserFollowing());
                setReportConfirm(false);
            }, 1000);
       } 
    },[reportConfirm])
 
    //  block
    const [blockModalOpen, setBlockModalOpen] = useState(false);
    const toggleBlockModalOpen = () => {
      setBlockModalOpen((prevState) => !prevState);
    };
    const[blockConfirm,setBlockConfirm] = useState(false)
    useEffect(()=>{
      if(blockConfirm){
         setTimeout(() => {
            dispatch(loadAllUserFollowing());
           setBlockConfirm(false);
         }, 1000);
      } 
     },[blockConfirm])
 
    // ----------------- End Reported State -----------------
    return (
        <>
            {
                followingData && followingData.length > 0 ? (
                    followingData.map((request, index) => (
               <div key={index} className="connection-card  box-shadow-style content-mp-block nav-flex-container position-relative mx-2 p-2 mt-3 d-flex align-items-center justify-content-center">
                        <div>
                            <div class="media media-new d-flex align-items-start">
                                <div class="user-img d-flex align-items-start popover___wrapper " >
                                    <img src={request?.profileImage}
                                        className="rounded-circle " height={45} width={45} alt="shivam singh" />
                                    <span class="available-stats online"></span>
                                    <PublicAccount request={request} />
                                </div>
                                <div class="media-body d-md-block pl-1">
                                    <NavLink to='/MyProfile' >
                                        <h4 className='m-0 name-style d-inline-block text-truncate'>{request?.fullName?.substring(0, 21) || 'NA'}</h4>
                                    </NavLink>
                                    <div className='d-flex my-1 text-wrap'>
                                        <p className='user-name-style rem-mob-view-small text-wrap'>{request?.userName || 'NA'}  |  </p>
                                        <div className='d-flex ml-1'>
                                            <span>
                                                <img
                                                    src={request?.countryData[0]?.flagURL}
                                                    className="ml-1 rem-mob-view-small"
                                                    alt="..."
                                                    width={19}
                                                    height={13}
                                                />
                                            </span>
                                            <span className='professional-style ml-1'>{request?.countryData[0]?.name}</span>
                                        </div>
                                    </div>
                                    {/* <h6 className='professional-style text-wrap'>I am a freelance mobile app developer who specializes in native, as well as hybrid app development.</h6> */}
                                    <div className="people-likes matual-friend-sec ">
                                        <MutualFriendList />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='pt-2 ml-1'>
                            <ul id="counter" className='d-flex align-items-center justify-content-around '>
                                <li className='text-center'>
                                    <h3 className="counter-value green-text-style" data-count="26335">{request?.totalPostCount}</h3>
                                    <h5 className='professional-style'>Post</h5>
                                </li>
                                <li className='mx-3'>
                                    <hr style={{ height: '30px', width: '1px', color: '#000000' }} />
                                </li>
                                <li className='text-center '>
                                    <h3 className="counter-value green-text-style" data-count="546">{request?.followingCount}</h3>
                                    <h5 className='professional-style'>Following</h5>
                                </li>
                                <li className='mx-3'>
                                    <hr style={{ height: '30px', width: '1px', color: '#000000' }} />
                                </li>
                                <li className='text-center'>
                                    <h3 className="counter-value green-text-style" data-count="6845">{request?.followersCount}</h3>
                                    <h5 className='professional-style'>Followers</h5>
                                </li>
                            </ul>
                        </div>

                        <div className='ml-sm-4 ml-xs-4 d-flex align-items-center justify-content-center'>
                            {/* <ul id="counter" className='d-flex align-items-center justify-content-around align-right-ab '> */}
                            
                             <Button outline className='text-wrap' color="danger" >
                                <UserMinus size={15}  />&nbsp;<span className='font-weight-bold' onClick={()=>{toggleUnfollowModal(request)}}>&nbsp;&nbsp;Unfollow</span>
                            </Button>
                                <div className='d-flex align-items-end flex-column justify-content-end'>
                                    <UncontrolledButtonDropdown className='d-flex align-items-end flex-column justify-content-end px-4' direction='left'>
                                        <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle'>
                                            <MoreVertical size={22} />
                                        </DropdownToggle>
                                        <DropdownMenu className='Connection-dropdown'>
                                                    <DropdownItem onClick={() => toggleUnfollowModal(request)} className='border-bottom py-2 menu-hover-style'>
                                                        <UserMinus size={15} className='mr-2' />Unfollow
                                                    </DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleShareOpen}><Share size={15} className='mr-2' />Share Profile</DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style'><Copy size={15} className='mr-2' onClick={()=>copyProfileURL(request.id)}/>Copy Profile URL</DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style' 
                                                        onClick={() => {
                                                            toggleBlockModalOpen();
                                                            setUserId(request.id);
                                                        }}>
                                                    <MinusCircle size={15} className='mr-2 icon-color-style' />Block</DropdownItem>
                                                    <DropdownItem className='py-2 menu-hover-style' onClick={()=>{toggleReport(request)}}><Flag size={15} className='mr-2' />Report User
                                                    </DropdownItem>
                                                </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            {/* </ul> */}
                        </div>
                    </div>

                  )))
              : 
              <Row>
              <Col xl="12" className="d-flex justify-content-center">
                  {followingData?.length === 0 && <h3 className="text-center connection-sub-heading">No Following Found</h3>}
              </Col>
            </Row>
              
            }

            <AddCategoryModal
                openModal={addCategoryOptions}
                cateModalOpen={toggleAddCategory}
            />
            {/*  ------------------ Add Report Option  ------------------ */}
            { reportOpen && (
                <ConnectionReportModal 
                    modalOpen={reportOpen} 
                    reportModalOpen={toggleReport} 
                    request={selectedRequest}
                    setReportConfirm ={setReportConfirm} 
                />
            )}

            {/*------------------------- block modal----------------------- */}
            { blockModalOpen && (
                <BlockModal
                    request={userId}
                    openModal={blockModalOpen}
                    removeModalOpen={toggleBlockModalOpen}
                    setBlockConfirm={setBlockConfirm}
                />
            )}
            {/* ------------------ Start Share Modal  ------------------*/}
            <ConnectionShareModal
                openModal={shareOpen}
                shareModalOpen={toggleShareOpen}
            />
            {/* ------------- Unfollow Modal Modal  -------------*/}
            <UnfollowModal
                request={selectedRequest}
                openModal={unfollowModalOpen}
                unfollowModalOpen={toggleUnfollowModal}
            />
        </>
    );
}

