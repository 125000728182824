import * as types from "../../Constants/Connection/index";
import axios from "axios";

const getAllUserSociomate = (getUserSociomate) => ({
  type: types.GET_SOCIOMATE,
  payload: getUserSociomate,
});
// get all biz category
export const loadAllUserSciomate = () => {
  let user = JSON.parse(localStorage.getItem("sociomeeUser"));
  return function (dispatch) {
    if (user) {
      axios
        .post(
          `${process.env.REACT_APP_IPURL}/user/getUserSociomates`,
          {
            searchKey: "",
            pageIndex: 0,
            pageSize: "",
            sortBy: "ascending",
            countryIds : [],
          },
          {
            headers: { Authorization: `Bearer ${user?.token}` },
          }
        )
        .then((res) => {
          //console.log("sociomate=======",res.data.data.successResult);
          dispatch(getAllUserSociomate(res.data.data.successResult));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};
