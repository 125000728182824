import React, { useEffect, useState } from 'react';
import { Check, Copy, Flag, MinusCircle, MoreVertical, PlusCircle, Share, UserMinus, X } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
//import { loadAllFollowUnFollow } from "../../../Services/Actions/Connection/getFollowUnFollowAction";
import { loadAllUserSciomate } from '../../../Services/Actions/Connection/getSociomateAction';
import { loadAllFollowUnFollow } from '../../../Services/Actions/Connection/getFollowUnFollowAction';
import { loadAllRemoveFollower } from "../../../Services/Actions/Connection/getRemoveFollowerAction";
import india_svg from '../../../NewComponents/IMG/india_svg.svg'
import { MutualFriendList } from '../HoverComponents/MutualFriendList';
import BlockModal from '../ConnectionModal/BlockModal';
import ConnectionReportModal from '../ConnectionModal/ConnectionReportModal';
import AddCategoryModal from '../ConnectionModal/AddCategoryModal';
import NewRemoveModal from '../ConnectionModal/NewRemoveModal';
import ConnectionShareModal from '../ConnectionModal/ConnectionShareModal';
import UnfollowModal from '../ConnectionModal/UnfollowModal';
import PublicAccount from '../ConnectionModal/PublicAccount';
import '../Style.css'
import { NavLink } from 'react-router-dom';
// import sweetAlertConfirmation from '../'                                                                           

export const SocioMateList = ({followingData}) => {
    //const { getUserFollowers } = useSelector(state => state.GetUserFollowersData)
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [userId, setUserId] = useState("");
    const [fullName, setFullName] = useState("");

    //const { getUserSociomate } = useSelector(state => state.userSociomateData);
    const dispatch = useDispatch();

    //search opration 
    const [searchItem, setSearchItem] = useState("")

    //const filteredData = getUserSociomate?.rows?.filter((item) =>
    //    item.fullName.toLowerCase().includes(searchItem.toLowerCase()));

    //----------------- Unfollow Modal open-----------------
 
    const [unfollowModalOpen, setUnfollowModalOpen] = useState(false)
    const toggleUnfollowModal = (request) => {
    setUnfollowModalOpen((prevState) => !prevState)
    setSelectedRequest(request);
    }

    // ------------- Remove Followers Modal open -------------
    const [removeModalOpen, setRemoveModalOpen] = useState(false)
    const toggleRemoveModalOpen = () => {
        setRemoveModalOpen((prevState) => !prevState)
    }
    const[removeConfirm,setRemoveConfirm] = useState(false)
        useEffect(()=>{
        if(removeConfirm){
            setTimeout(() => {
            dispatch(loadAllUserSciomate());
            setRemoveConfirm(false);
            }, 1000);
        } 
        },[removeConfirm])

    //  ------------- Start ReportedOption  -------------
    const [reportOpen, setReportOpen] = useState(false)
    const toggleReport = (request) => {
        setReportOpen((preState) => !preState)
        setSelectedRequest(request)    
    }

    //----------------- share Modal open-----------------
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState)
    }

    //--------------------------------- block modal--------------------

    const [blockModalOpen, setBlockModalOpen] = useState(false)
    const toggleBlockModalOpen = () => {
      setBlockModalOpen((prevState) => !prevState)
    }
  
    const[blockConfirm,setBlockConfirm] = useState(false)
    useEffect(()=>{
        if(blockConfirm){
        setTimeout(() => {
            dispatch(loadAllUserSciomate());
            setBlockConfirm(false);
        }, 1000);
        } 
    },[blockConfirm])

    //------------------ copy user Profile link-------------------------
    const copyUserProfile = (request) =>{
        // sweetAlertConfirmation("link copied")
        const profileURL = `${process.env.REACT_APP_API_URL}/user/getUserProfileById?id=${request.userId}`;

        navigator.clipboard
          .writeText(profileURL)
          .then(() => {
            console.log("Profile URL copied to clipboard:", profileURL);
            alert("copied profile url", profileURL);
          })
          .catch((error) => {
            console.error("Failed to copy profile URL:", error);
          });
    }

    //  ------------- Start Add Category Status -------------
    const [addCategoryOptions, setAddCategoryOptions] = useState(false)
    const toggleAddCategory = () => {
        setAddCategoryOptions((preState) => !preState)
    }

    useEffect(() => {
        dispatch(loadAllUserSciomate())
    }, [dispatch])
    const[reportConfirm,setReportConfirm] = useState(false)
    useEffect(()=>{
        if(reportConfirm){
            setTimeout(() => {
              dispatch(loadAllUserSciomate());
              setReportConfirm(false);
            }, 1000);
         }  
    },[reportConfirm])

    return (

        <>
            {
                followingData && followingData.map((request, index) => {
                    return(
                        <div className="connection-card  box-shadow-style content-mp-block nav-flex-container position-relative mx-2 p-2 mt-3" key={index}>
                        <div class="media media-new d-flex align-items-start">
                        <div class="user-img d-flex align-items-start popover___wrapper">
                            <img src={request?.profileImage} alt="profileImage" className="rounded-circle" height={45} width={45} />
                            <span class="available-stats online"></span>
                            <PublicAccount request={request} />
                        </div>

                            <div class="media-body d-md-block pl-1">
                            <NavLink to='/MyProfile' ><h4 className='m-0 name-style d-inline-block text-truncate text-wrap'>{request?.fullName?.substring(0, 21) || 'name'}</h4></NavLink>
                                <div className='d-flex'>
                                    <p className='user-name-style rem-mob-view-small'>{request?.userName || "NA"} | </p> 
                                        {/*<span className='professional-style ml-1'>{request.countryData[0].name}</span> &nbsp; &nbsp;*/}
                                        <span>
                                          <img
                                            src={request?.countryData[0]?.flagURL}
                                            className="ml-1 rem-mob-view-small"
                                            alt="..."
                                            width={19}
                                            height={13}
                                          />
                                        </span>
                                </div>
                                <h6 className="professional-style my-1 rem-mob-view-small">
                                        {request?.profession || "No Profession"}
                                </h6>
                                {/* <h6 className='professional-style mr-5'>I am a freelance mobile app developer who specializes in native, as well as hybrid app development.</h6> */}
                                <MutualFriendList />
                            </div>
                        </div>
                        <div className='pt-2 ml-1'>
                            <ul id="counter" className='d-flex align-items-center justify-content-around '>
                                <li className='text-center'>
                                    <h3 className="counter-value green-text-style" data-count="26335">{request?.totalPostCount}</h3>
                                    <h5 className='professional-style'>Post</h5>
                                </li>
                                <li className='mx-3'>
                                    <hr style={{ height: '30px', width: '1px', color: '#000000' }} />
                                </li>
                                <li className='text-center '>
                                    <h3 className="counter-value green-text-style" data-count="546">{request?.followingCount}</h3>
                                    <h5 className='professional-style'>Following</h5>
                                </li>
                                <li className='mx-3'>
                                    <hr style={{ height: '30px', width: '1px', color: '#000000' }} />
                                </li>
                                <li className='text-center'>
                                    <h3 className="counter-value green-text-style" data-count="6845">{request?.followersCount}</h3>
                                    <h5 className='professional-style'>Followers</h5>
                                </li>
                            </ul>
                        </div>
                        <div className='ml-sm-4 ml-xs-4 d-flex align-items-center justify-content-center'>
                            {/* <ul id="counter" className='d-flex align-items-center justify-content-around align-right-ab '> */}
                                <Button color='secondary' outline className='text-wrap' onClick={() =>
                                      toggleUnfollowModal(request)
                                    }>
                                    <Check size={15} />&nbsp; <span className='font-weight-bold'>Following</span>
                                </Button>
                            
                                <div className='d-flex align-items-end flex-column justify-content-end'>
                                    <UncontrolledButtonDropdown className='d-flex align-items-end flex-column justify-content-end px-4' direction='left'>
                                        <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle'>
                                            <MoreVertical color='#C4C4C4' size={22} />
                                        </DropdownToggle>
                                        <DropdownMenu className='Connection-dropdown'>
                                            <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={()=>{toggleUnfollowModal(request)}}><UserMinus size={15} className='mr-2' />Unfollow</DropdownItem>
                                            <DropdownItem className='border-bottom py-2 menu-hover-style' 
                                                onClick={() => { 
                                                    toggleRemoveModalOpen();
                                                    setFullName(request.fullName);
                                                    setUserId(request.id); 
                                                }}
                                            ><MinusCircle size={15} className='mr-2' />Remove Follower</DropdownItem>
                                            <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleShareOpen}><Share size={15} className='mr-2' />Share Profile</DropdownItem>
                                            <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleAddCategory}><PlusCircle size={15} className='mr-2' />Add to Category</DropdownItem>
                                            <DropdownItem className='border-bottom py-2 menu-hover-style'><Copy size={15} className='mr-2' />Copy Profile URL</DropdownItem>
                                            <DropdownItem className='border-bottom py-2 menu-hover-style'  onClick={()=>{toggleBlockModalOpen();setUserId(request.id);}}><MinusCircle size={15} className='mr-2 icon-color-style' />Block</DropdownItem>
                                            <DropdownItem className='py-2 menu-hover-style' onClick={()=>{toggleReport(request)}}>
                                                <Flag size={15} className='mr-2' />Report User
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            {/* </ul> */}
                        </div>
                    </div>
                    )
                })
            }
            {
                followingData.length <= 0 && <h3 className='text-center connection-sub-heading'>No Followers Found</h3>
            }
        {/* -------------- Add Report Option -------------- */}
        { reportOpen && (
          <ConnectionReportModal
            request={selectedRequest}
            modalOpen={reportOpen}
            reportModalOpen={toggleReport}
            setReportConfirm ={setReportConfirm}
          />
        )}
        {/*   ----------- Add CategoryModal Status ----------- */}
        <AddCategoryModal
            openModal={addCategoryOptions}
            cateModalOpen={toggleAddCategory}
        />
        {/* ----------- Remove SocioMate Modal ----------- */}
        { removeModalOpen && (
            <NewRemoveModal
                userId={userId}
                fullName={fullName}
                openModal={removeModalOpen}
                removeModalOpen={toggleRemoveModalOpen}
                setRemoveConfirm={setRemoveConfirm}
            />
        )}
        {/*------------------------- block modal----------------------- */}

        { blockModalOpen && (
            <BlockModal
                request={userId}
                openModal={blockModalOpen}
                removeModalOpen={toggleBlockModalOpen}
                setBlockConfirm={setBlockConfirm}
            />
        )}
        {/* ----------- Start Share Modal -----------*/}
        <ConnectionShareModal
            openModal={shareOpen}
            shareModalOpen={toggleShareOpen}
        />
        {/* ------------- Unfollow Modal Modal -------------*/}
        <UnfollowModal
            request={selectedRequest}
            openModal={unfollowModalOpen}
            unfollowModalOpen={toggleUnfollowModal}
        />
        </>
    )
}

