import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Button } from 'reactstrap';
import axios from 'axios';

const FrndSuggestion = () => {
  const FriendSuggestsettings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const [following, setFollowing] = useState({});

  const handleFollow = async (userId) => {
    console.log(userId);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/user/followUnFollow`,
        {
          followingToId: userId,
          isFollowed: !following[userId], // Toggle follow status
          isPrivate: true,
        },
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
          },
        }
      );
      console.log(response.data.success);
 
      if (response.data.success === true) {
        // Update the following state to reflect the current follow status
        setFollowing({ ...following, [userId]: !following[userId] });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      async (geolocation) => {
        const body = {
          lat: geolocation.coords.latitude,
          long: geolocation.coords.longitude,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_IPURL}/post/getSuggestedUsers`,
          body,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
            },
          }
        );

        if (response.data.success) {
          setSuggestedUsers(response.data.data.successResult.rows);
        }
      }
    );
  }, []);


  return (
    <Fragment>
      <div className='Group_suggestion_card groupBottom-custom-block'>
        <div className="CommonHeadingSeg d-flex justify-content-between ">
          <h3 style={{ color: "#333333" }}>Suggestion123</h3>
          <div className="settings">
            <div className="seeall-btn">
              <Link to={'/Connection'} style={{ fontSize: "12px", fontWeight: "500", color: "#808080" }}>See All</Link>
            </div>
          </div>
        </div>
        <Slider {...FriendSuggestsettings} className="default-space mt-3 GroupSSliderCustomizes">
          {suggestedUsers.map(user => (
            
            <div key={user.id}>
              <div className='Crad_Blk_section'>
                <div className='Img_Blk_section'>
                  <img 
                    src={user.coverImage || "https://sociomee-pro.s3.eu-central-1.amazonaws.com/logo/tooXKdvgUQcp9.png"} 
                    alt="cardImg"
                    className='img-fluid'
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "https://sociomee-pro.s3.eu-central-1.amazonaws.com/logo/tooXKdvgUQcp9.png"; 
                    }}
                    />
                  <div className='adminprofile-blk'>
                    <img
                      src={user.profileImage ||  "https://sociomee-pro.s3.eu-central-1.amazonaws.com/logo/O79wh8XUHQ5kv.png"}
                      className="rounded-circle"
                      alt="Profile"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "https://sociomee-pro.s3.eu-central-1.amazonaws.com/logo/O79wh8XUHQ5kv.png"; 
                      }}
                    />
                  </div>
                  <div className='Card_Content_blk'>
                  {user?.fullName && (
                    <h2>
                       {user.fullName.length > 10
                           ? user.fullName.substring(0, 10) + " ..."
                           : user.fullName
                       }
                   </h2>
                )}
                    <div className="people-likes matual-friend-sec">
                      <ul className="matual-friend-blk">
                        {/* Add AvatarGroup component */}
                      </ul>
                      <h6 className='text-nowrap' style={{ cursor: "pointer", fontSize: "12px", color: "#999999" }}  >
                        {user.mututalFriendsCount} mutual friends
                      </h6>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-center mb-2'>
                  <Button
                    color='primary'
                    className='px-4 py-2 FollowRequest_Accept_btn'
                    onClick={() => handleFollow(user.id)}
                  >
                    {following[user.id] ? 'Unfollow' : 'Follow'}
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Fragment>
  );
};

export default FrndSuggestion;



// import React, { Fragment,useState,useEffect } from 'react'
// import { Link } from 'react-router-dom'
// import Slider from 'react-slick';
// import Imgcard from '../../NewComponents/IMG/Imgcard.png'
// import GrpImg from '../../NewComponents/IMG/GrpImg.svg'
// import AvatarGroup from '../../NewComponents/AvatarGroup';
// import { Button } from 'reactstrap';
// import axios from 'axios';

// const FrndSuggestion = () => {
   

//     const FriendSuggestsettings = {
//         dots: false,
//         infinite: false,
//         speed: 300,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//     };

         
//     const [suggestedUsers, setSuggestedUsers] = useState([]);
//             // Fetch suggested users data from API
//             navigator.geolocation.getCurrentPosition(
//                 async (geolocation) => {

//             const body={
//                 lat: geolocation.coords.latitude,
//                 long: geolocation.coords.longitude,
//             }
//             const response = await axios.post( `${process.env.REACT_APP_IPURL}/post/getSuggestedUsers`,
//             body,
//             {
//             headers: {
//                 Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token}`,
//             },
//             })
//                 .then(response => {
//                     setSuggestedUsers(response.data.data.successResult.rows);
//                     // console.log(response.data.data.successResult.rows)
//                 })
//                 .catch(error => {
//                     console.log(error);
//                 });
//             }
//             )
        
// //  console.log(suggestedUsers)
//     //================ Main_return_function ========================//
//     return (
//         <Fragment>
//             <div className='Group_suggestion_card groupBottom-custom-block'>
//                 <div className="CommonHeadingSeg d-flex justify-content-between ">
//                     <h3 style={{ color: "#333333" }}>Suggestion </h3>
//                     <div className="settings">
//                         <div className="seeall-btn">
//                             <Link to={'/Connection'} style={{ fontSize: "12px", fontWeight: "500", color: "#808080" }}>See All</Link>
//                         </div>
//                     </div>
//                 </div>
//                 <Slider {...FriendSuggestsettings} className="default-space mt-3 GroupSSliderCustomizes">
//                 {suggestedUsers.map(user => (
//                         <div key={user.id}>
//                             <div className='Crad_Blk_section'>
//                                 <div className='Img_Blk_section'>
//                                     <img src={user.profileImageThumb} alt="cardImg" className='img-fluid' />
//                                     <div className='adminprofile-blk'>
//                                         <img src={user.profileImage} className="rounded-circle" alt="Profile" />
//                                     </div>
//                                     <div className='Card_Content_blk'>
//                                     {user?.fullName && (
//         <h2>
//             {user.fullName.length > 12
//                 ? user.fullName.substring(0, 12) + " ..."
//                 : user.fullName
//             }
//         </h2>
// )}
//                                         {/* <h2> {user.fullName.length > 15
//                                                  ? user.fullName.substring(0, 15) + "..."
//                                                  : user.fullName
//                                              }</h2>  */}
//                                         <div className="people-likes matual-friend-sec">
//                                             <ul className="matual-friend-blk">
//                                                 <AvatarGroup />
//                                             </ul>
                                        
//                                             <h6 className='text-nowrap' style={{ cursor: "pointer", fontSize: "12px", color: "#999999" }}  >{user.mututalFriendsCount} mutual friends</h6>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 <div className='d-flex justify-content-center mb-2'>
//                                     <Button color='primary' className='px-4 py-2 FollowRequest_Accept_btn'>Follow</Button>
//                                 </div>
//                             </div>
//                         </div>
//                     ))}
//                 </Slider>
//             </div>
//         </Fragment>
//     )
// }

// export default FrndSuggestion
