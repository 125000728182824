
import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Button } from 'react-bootstrap'
import { getActionBlockUnblock } from '../../../Services/Actions/Connection/getBlockUnblockAction'
import { useDispatch } from 'react-redux'
const BlockModal = ({request,removeModalOpen, openModal,setBlockConfirm} ) => {

    const dispatch = useDispatch();

    const handleBlock = ()=>{
        dispatch(getActionBlockUnblock(request));
        setBlockConfirm(true); 
        removeModalOpen(); 
    }

    return (    
        <>
            {
                openModal !== false &&
                <Modal isOpen={openModal} toggle={removeModalOpen} className='modal-dialog-centered'>
                    <ModalHeader  className='d-flex justify-content-center Media_header' >
                        <h3>Block </h3>
                    </ModalHeader>
                    <ModalBody className='text-center'>
                        <h4 className='py-2'>Would you like to block  and feel secure from any future interactions? It's up to you!</h4>
                    </ModalBody>
                    <ModalFooter className='d-flex border-0 align-items-center justify-content-center '>
                        <Button variant='outline-secondary' className='w-25' onClick={removeModalOpen}>
                            <span className='ml-1 w-25'>Cancel</span>
                        </Button>
                        <Button variant='danger' className='w-25' onClick={handleBlock}>
                            <span className='ml-1 w-25'>Block</span>
                        </Button>
                    </ModalFooter>
                </Modal>
            }
        </>
    )
}

export default BlockModal