import React, { useEffect, useState } from 'react';
import { CheckCircle, Copy, Flag, Info, MoreVertical, XCircle, MinusCircle } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown, UncontrolledTooltip } from 'reactstrap';
import { getUserFollowingRequests } from '../../../Services/Actions/Connection/getUserFollowingRequest';
import {getFollowUnfollowAction} from '../../../Services/Actions/Connection/getFollowUnFollowAction';
import india_svg from '../../../NewComponents/IMG/india_svg.svg'
import decline from '../../../NewComponents/IMG/Frame 2551.svg'
import accept from '../../../NewComponents/IMG/Frame 2560.svg'
import { MutualFriendList } from '../HoverComponents/MutualFriendList';
import BlockModal from '../ConnectionModal/BlockModal';
import ConnectionReportModal from '../ConnectionModal/ConnectionReportModal';
import ConnectionShareModal from '../ConnectionModal/ConnectionShareModal';
import PrivateAccount from '../ConnectionModal/PrivateAccount';
import '../Style.css'
import PublicAccount from '../ConnectionModal/PublicAccount';
import { RejectModal, SingleRejectModal } from '../ConnectionModal/RejectModal';
import { AcceptModal, SingleAcceptModal } from '../ConnectionModal/AcceptModal';
import { Checkbox, FormControlLabel } from '@mui/material';
import { NavLink } from 'react-router-dom';

export const PendingRequest = (props) => {
    
    const { userFollowingRequests } = useSelector(
            (state) => state.userFollowingRequestsData
        );
    const dispatch = useDispatch();
    const [userId,setUserId] = useState("");
        
    useEffect(() => {
            dispatch(getUserFollowingRequests())
        }, [])

    // ------------- Accept Modal State  -------------
    const [acceptModalOpen, setAcceptModalOpen] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const toggleAcceptModalOpen = ( request) => {
        setAcceptModalOpen((prevState) => !prevState);
        selectedRequest(request)
    };

    // -------------Reject Modal State  -------------
    const [rejectModalOpen, setRejectModalOpen] = useState(false);
    const toggleRejectModalOpen = (request) => {
        setRejectModalOpen((prevState) => !prevState);
        selectedRequest(request)
        
    };
    // -------------Single Accept Modal State  -------------
    const [singAcceptModalOpen, setSingAcceptModalOpen] = useState(false);
    const toggleSingAcceptModalOpen = (request) => {
        setSingAcceptModalOpen((prevState) => !prevState);
        //selectedRequest(request)
    };

    // -------------Single Reject Modal State  -------------
    const [singRejectModalOpen, setSingRejectModalOpen] = useState(false);
    const toggleSingRejectModalOpen = (request) => {
        setSingRejectModalOpen((prevState) => !prevState);
        //selectedRequest(request)
    };

    // ------------- share Modal State -------------
    const [shareOpen, setShareOpen] = useState(false);
    const toggleShareOpen = () => {
        setShareOpen((prevState) => !prevState);
    };
    //  ------------- Start ReportedOption  State -------------
    const [reportRequest, setReportRequest] = useState({ id: "" });
    const [reportOpen, setReportOpen] = useState(false);
    const toggleReport = () => {
        setReportOpen((preState) => !preState);
    };
    const[reportConfirm,setReportConfirm] = useState(false)
      useEffect(()=>{
        if(reportConfirm){
              setTimeout(() => {
                dispatch(getUserFollowingRequests());
                setReportConfirm(false);
              }, 1000);
          } 
      },[reportConfirm])

    const [isSelectActive, setIsSelectActive] = useState("");
    const [selectedShotz, setSelectedShotz] = useState([]);

    //copy user profile
    const handleCopy= (request)=>{
        
    }

    //get user block
    const [blockModalOpen, setBlockModalOpen] = useState(false)
    const toggleBlockModalOpen = () => {
        setBlockModalOpen((prevState) => !prevState);
    }
    const[blockConfirm,setBlockConfirm] = useState(false)
        useEffect(()=>{
        if(blockConfirm){
            setTimeout(() => {
                dispatch(getUserFollowingRequests());
            setBlockConfirm(false);
            }, 1000);
        } 
    },[blockConfirm])
    

    return (
        <>
            {
                userFollowingRequests && userFollowingRequests.data?.rows?.slice(0, 8).map((request, index) => {
                    return <div className={props.Check || props.CheckOne && index === 0 ? "box-selected content-mp-block nav-flex-container position-relative mx-2 p-2 mt-3" : "box-shadow-style content-mp-block position-relative nav-flex-container mx-2 p-2 mt-3"}>
                        <div class="nav-flex-container option-disable d-flex align-items-start">
                            <div class="user-img d-flex align-items-start popover___wrapper">
                                <img src={request?.profileImage}
                                    className="rounded-circle" height={50} width={50} alt="shivam singh" />
                                <span class="available-stats online"></span>
                                <PrivateAccount />
                            </div>
                            <div class="media-body d-md-block pl-1">
                                <NavLink to='/MyProfile' >
                                    <h4 className='m-0 name-style d-inline-block text-truncate'>{request?.fullName?.substring(0, 21) || 'NA'}</h4>
                                </NavLink>
                                    <div className='d-flex text-wrap'>
                                        <p className='user-name-style rem-mob-view-small text-wrap'>{request?.userName || 'NA'}  |  </p>
                                        <div className='d-flex ml-1'>
                                            <span>
                                                <img
                                                    src={request?.countryData[0]?.flagURL}
                                                    className="rem-mob-view-small"
                                                    alt="..."
                                                    width={19}
                                                    height={13}
                                                />
                                            </span>
                                            <span className='professional-style ml-1'>{request?.countryData[0]?.name}</span>
                                        </div> 
                                    </div>
                                <div className="people-likes matual-friend-sec">
                                          <MutualFriendList />
                                </div>
                                {/* <h6 className='professional-style text-wrap'>I am a freelance mobile app developer who specializes in native.</h6> */}
                            </div>
                        </div>
                        <div className='pt-2 ml-1'>
                            <ul id="counter" className='d-flex align-items-center justify-content-around '>
                                <li className='text-center'>
                                    <h3 className="counter-value green-text-style" data-count="26335">{request?.totalPostCount || 0}</h3>
                                    <h5 className='professional-style'>Post</h5>
                                </li>
                                <li className='mx-3'>
                                    <hr style={{ height: '30px', width: '1px', color: '#000000' }} />
                                </li>
                                <li className='text-center '>
                                    <h3 className="counter-value green-text-style" data-count="546">{request?.followingCount || 0}</h3>
                                    <h5 className='professional-style'>Following</h5>
                                </li>
                                <li className='mx-3'>
                                    <hr style={{ height: '30px', width: '1px', color: '#000000' }} />
                                </li>
                                <li className='text-center'>
                                    <h3 className="counter-value green-text-style" data-count="6845">{request?.followersCount || 0}</h3>
                                    <h5 className='professional-style'>Followers</h5>
                                </li>
                            </ul>
                        </div>
                        <div className='pt-2 ml-sm-4 ml-xs-4 '>
                        {(props.CheckOne || props.Check) ? 
                                <FormControlLabel control={<Checkbox checked={props.Check || (props.CheckOne && index === 0) ? true : null} />} /> :
                                (<div className="d-flex align-items-center justify-content-center ">
                                    <span role="button" className='mx-2 rem-mob-view option-disable' onClick={toggleSingAcceptModalOpen}>
                                    <img src={accept} height='30px' width="30px" className='rounded-circle' alt="" id="accept-sign"/>
                                    <UncontrolledTooltip placement='top' target='accept-sign'>
                                        Accept
                                    </UncontrolledTooltip>
                                    </span>
                                    <span role="button" className='rem-mob-view option-disable ' onClick={toggleSingRejectModalOpen}>
                                    <img src={decline} height='30px' width="30px" className='rounded-circle' alt="" id="reject-sign" />
                                    <UncontrolledTooltip placement='top' target='reject-sign'>
                                        Reject  
                                    </UncontrolledTooltip>
                                    </span>
                                    <UncontrolledButtonDropdown direction='left' className="option-disable px-4">
                                    <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle'>
                                        <MoreVertical size={22} />
                                    </DropdownToggle>
                                    <DropdownMenu className='Connection-dropdown'>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={()=>{toggleSingAcceptModalOpen(request)}}><CheckCircle size={15} className='mr-2' />Accept</DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={()=>{toggleSingRejectModalOpen(request)}}><XCircle size={15} className='mr-2' />Decline</DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style'><Copy size={15} className='mr-2' />Copy Profile URL</DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style'  
                                                        onClick={()=>{
                                                            toggleBlockModalOpen();
                                                            setUserId(request.userId);
                                                        }}
                                                    >
                                                    <MinusCircle size={15} className='mr-2 icon-color-style' />Block</DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style' 
                                                    onClick={()=>{
                                                        toggleReport();
                                                        setReportRequest({ id: request.userId });  // Store the userId as request.id
                                                    }}>
                                                        <Flag size={15} className='mr-2' />Report User
                                                    </DropdownItem>
                                                    <DropdownItem className='py-2 menu-hover-style'><Info size={15} className='mr-2' />Learn more</DropdownItem>
                                                </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                            </div>
                        )}
                            <div className="mt-3 d-flex align-items-end justify-content-end">
                                <p className="align-self-end font-weight-bold text-right rem-mob-view-small mr-2">
                                    1 Day Ago
                                </p>
                            </div>
                        </div>
                        {/* <PrivateAccount /> */}
                    </div>
                })
            }
            { reportOpen && (
                <ConnectionReportModal
                    request={reportRequest}
                    modalOpen={reportOpen}
                    reportModalOpen={toggleReport}
                    setReportConfirm ={setReportConfirm}
                />
            )}
            
            {/* ------------------ Start Share Modal  ------------------*/}
            <ConnectionShareModal
                openModal={shareOpen}
                shareModalOpen={toggleShareOpen}
            />

            {/* -------------  Accept Modal  ------------- */}
            <AcceptModal
                request={selectedRequest}
                openModal={acceptModalOpen}
                openAcceptModal={toggleAcceptModalOpen}
            />

            {/* -------------  Reject modal Modal  ------------- */}
            <RejectModal
                request={selectedRequest}
                openModal={rejectModalOpen}
                rejectModalOpen={toggleRejectModalOpen}
            />

            {/* ------------- Single Accept Modal  ------------- */}
            <SingleAcceptModal
                //request={selectedRequest}
                openModal={singAcceptModalOpen}
                openSingleAcceptModal={toggleSingAcceptModalOpen}
            />

            {/* ------------- Single Reject modal Modal  ------------- */}
            <SingleRejectModal
                //request={selectedRequest}
                openModal={singRejectModalOpen}
                singleRejectModalOpen={toggleSingRejectModalOpen}
            />
            
            {/* ------------- Block modal Modal  ------------- */}
           
            { blockModalOpen && (
                <BlockModal
                    request={userId}
                    openModal={blockModalOpen}
                    removeModalOpen={toggleBlockModalOpen}
                    setBlockConfirm={setBlockConfirm}
                />
            )}
        </>
    )
}

