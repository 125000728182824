import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { ChevronRight, XCircle } from 'react-feather'
import { Col } from 'reactstrap'
import axios from 'axios'

const ActivityNew = ({
    activityList,
    postType,
    val,
    toggle,
    activeTab,
    onSelectFeelingCategoryId,
    onSelectFeelingId,
    setfeelingSubChildCategoryId,
    setCachedDataActivity,
    cachedDataActivity,
    setShowSelectedFeeling
}) => {
    const [activeData, setActiveData] = useState([])
    const [activityData2, setActivityData2] = useState([])
    const [activityId, setActivityId] = useState("")
    const [currentActiveTab, setCurrentActiveTab] = useState(0)
    const [activeTabs, setActiveTabs] = useState(false)

    useEffect(() => {
        if (currentActiveTab !== 0) {
            getActivityChild(currentActiveTab)
        }
    }, [currentActiveTab])

    const toggles = (id) => {
        setCurrentActiveTab(id !== currentActiveTab ? id : 0)
    }

    // Fetch the child activity data
    const getActivityChild = async (id) => {
        try {
            const body = {
                searchKey: '',
                pageIndex: '',
                pageSize: '',
                ActivityId: id,
            }
            const response = await axios.post(
                `${process.env.REACT_APP_IPURL}/admin/getActivityChildById`,
                body,
                {
                    headers: {
                        Authorization: `Bearer ${
                            JSON.parse(localStorage.getItem('sociomeeUser'))?.token
                        }`,
                    }
                }
            )
            const datarecorded = response.data.data.successResult?.rows
            setActivityData2(datarecorded)
            setActiveTabs(true)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    // Handle the activity click
    const handleActivity = (id) => {
        setShowSelectedFeeling(false)
        setfeelingSubChildCategoryId(id)
        onSelectFeelingId(currentActiveTab)
       if (!val) val= 0
        switch (val) {
            case 0:
                postType = 'text'
                break
            case 7.6:
                postType = 'recomendation'
                break
            case 0.1:
                postType = 'media'
                break
            case 7.1:
                postType = 'thought'
                break
            case 7.3:
                postType = 'event'
                break
            default:
                postType = 'text'
                break
        }

        toggle(`/modal/${val}`)
    }

    // Get the name of the currently active activity tab
    let name = currentActiveTab === 0 ? '' : (activeData.length > 0 && activeData.find(x => x.id === currentActiveTab)?.name)



    
  // ===================================>> main function <<<======================================
    return (
        <Fragment>
            <div className='mx-3'>
                {/* <div className="right-setting pb-3"> {commneted search box here }
                    <div className="profile-search-blk w-100">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                        <input type="text" className="form-control Feeling-control" name="search" placeholder="Search..." />
                    </div>
                </div> */}
            </div>
            <div className='Feeling_scroll_box'>
                <div className='mx-3'>
                    <div className={`biz-about-cont ${currentActiveTab !== 0 ? 'd-none' : ''}`}>
                        <ul>
                            {activityList &&
                                activityList.map((item) => (
                                    <li key={item.id} className={currentActiveTab === item.id ? 'Activity_setting' : 'Sub_Activity'}>
                                        <div
                                            className='nave-items-bizPage d-flex justify-content-between'
                                            onClick={() => { toggles(item.id); onSelectFeelingCategoryId(item?.feelingCategoryId) }}
                                        >
                                            <div className='d-flex align-items-center'>
                                                <img src={item.iconURL} alt="emoji" width="40px" height="40px" />
                                                <span className='ml-3'>{item.name}</span>
                                            </div>
                                            <ChevronRight size={20} />
                                        </div>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className={`${currentActiveTab !== 0 ? '' : 'd-none'}`}>
                <div className='UpdateActivity_blk_section mb-3 mx-3'>
                    <h3 className='mr-3 d-flex align-items-center'>
                        {name}
                        <span className='ml-2'>
                            <XCircle onClick={() => toggles(0)} size={17} color="#C4C4C4" />
                        </span>
                    </h3>
                </div>
                <div className='Feeling_scroll_box'>
                    {activityData2 && activityData2.length > 0 ? (
                        activityData2.map((item) => (
                            <Col sm={12} className="mb-3" key={item.ActivityChildId}>
                                <div
                                    className={currentActiveTab === item.ActivityChildId ? 'Feeling_Main_blk_section' : 'Feeling_Main_blk_Subsection'}
                                    onClick={() => handleActivity(item.ActivityChildId)}
                                >
                                    <div>
                                        <img src={item.iconURL} alt="emoji" width="40px" height="40px" />
                                    </div>
                                    <h4 className='ml-3'>{item.name}</h4>
                                </div>
                            </Col>
                        ))
                    ) : (
                        <div className="text-center">
                            <h3>No child activity</h3>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    )
}

export default ActivityNew
