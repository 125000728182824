import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import timeAgo from "../../../functions/timeAgo";
import {
  addCommentOnPost,
  addLikeOnComment,
} from "../../../Services/Actions/SocialFeed/addCommentOnPost";
import { loadAllCommments } from "../../../Services/Actions/SocialFeed/getAllCommentsAction";
import SubComments from "../../post-components/display-post/SubComments";
import { Smile, Send } from "react-feather";
import { Input ,InputGroup} from "reactstrap";
import { DropdownToggle,UncontrolledButtonDropdown,DropdownItem } from "reactstrap";
import { MoreHorizontal } from "react-feather";
import { DropdownMenu } from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const Comments = ({ SetComment,postType,setCommentCount,postId, allowComments, setFetchedPost,setCommentsData,setCommentIds,postComments,setPostComments
  ,deleteComment,commentCount,setShowPostComment}) => {

    // console.log("postcomment re-rendered   ")
    useEffect(()=>{},[SetComment,commentCount])
  // async function deleteComment(comment){
  
  //   // toggle()
  //   console.log("delete comment ",comment)
  //     try {
  //       const requestBody = {
  //         commentId: comment.id,
  //       };
  
  //       const response = await axios.post(
  //         `${process.env.REACT_APP_IPURL}/post/deleteComment/`,
  //         requestBody,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${
  //               JSON.parse(localStorage.getItem("sociomeeUser"))?.token
  //             }`,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       if (response.data.success === true) {
  //         axios
  //         .post(
  //           `${process.env.REACT_APP_IPURL}/post/getComments`,
  //           { postId: postId, pageIndex: 0, pageSize: 4 },
  //           {
  //             headers: { Authorization: `Bearer ${user?.token}` },
  //           }
  //         )
  //         .then((res) => {
  //           setPostComments(res.data.data.successResult);
  //           setCommentCount(res.data.data.successResult.count)
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //         axios
  //       .post(
  //         `${process.env.REACT_APP_IPURL}/post/getComments`,
  //         { postId: postId, pageIndex: 0, pageSize: 4 },
  //         {
  //           headers: { Authorization: `Bearer ${user?.token}` },
  //         }
  //       )
  //       .then((res) => {
  //         setPostComments(res.data.data.successResult);
  //         setCommentCount(res.data.data.successResult.count)
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
          
  //         console.log("in sumit comment ",commentData)
  //         // dispatch(loadAllCommments(postId));
  //         dispatch(loadAllCommments(postId))
  //         setTimeout(()=>{
            
  //         },1000)
          
  //       }
          
        
  //     } catch (error) {
  //       console.error("Error commenting on post:", error);
  //     }
  
  // }


  const [isCommentAdd, setIsCommentAdd] = useState(false);
  const [error, setError] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [commentData, setCommentData] = useState("");
  const [commentId,setCommentId]=useState(null)
  const [commentsDataArray,setCommentsDataArray]=useState(postComments)
  const [visible,setVisible]=useState(3)



 const handleAllComments=(visible)=>{
  setVisible((pre)=>pre+3)
 }

 useEffect(()=>{
 },[commentsDataArray,commentCount])
  const commentRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("sociomeeUser"));
  const dispatch = useDispatch();
  function handelReply(){

  }useEffect(()=>{},[postComments])

  const handleCommentData = (e) => {
    
    setCommentData(e.target.value);
  };

  const commentLikeHandler = async (comment) => {
if(comment.liked===0){
  await dispatch(
    addLikeOnComment({
      commentId: comment.id,
      reactionId: "5e8592b0-ee8e-4602-8ef0-d22ad3e2b305",
      // reactionId: "f98d6c84-9968-4b1c-a695-1122c3772528",
    })
  );
  axios
    .post(
      `${process.env.REACT_APP_IPURL}/post/getComments`,
      { postId: postId, pageSize: 4, pageIndex: 0 },
      {
        headers: { Authorization: `Bearer ${user?.token}` },
      }
    )
    .then((res) => {
      setPostComments(res.data.data.successResult);
      setCommentsDataArray(res.data.data.successResult)
      // console.log(postComments,"comments in postComments.js")
      setIsCommentAdd(true);
    })
    .catch((error) => {
      console.log(error);
    });
}else if(comment.liked===1){
  await dispatch(
    addLikeOnComment({
      commentId: comment.id,
      // reactionId: "5e8592b0-ee8e-4602-8ef0-d22ad3e2b305",
      // reactionId: "f98d6c84-9968-4b1c-a695-1122c3772528",
    })
  );
  axios
    .post(
      `${process.env.REACT_APP_IPURL}/post/getComments`,
      { postId: postId, pageSize: 2, pageIndex: 0 },
      {
        headers: { Authorization: `Bearer ${user?.token}` },
      }
    )
    .then((res) => {
      setPostComments(res.data.data.successResult.rows);
      setCommentsDataArray(res.data.data.successResult.rows)
      // console.log(postComments,"comments in postComments.js")
      setIsCommentAdd(true);
    })
    .catch((error) => {
      console.log(error);
    });
}
   
  };

  // comment submit function
  // const submitComment = async () => {
  //   const id=commentId;
  //   console.log("commentId ishere  ",commentId)
  //   toggle()
  //   setError(false);
  //   const comment = commentRef.current.value;
  //   if (!comment) {
  //     setError(true);
  //   } else {
  //     await dispatch(addCommentOnPost({ postId: id, comment: comment }));
  //     await axios
  //       .post(
  //         `${process.env.REACT_APP_IPURL}/post/getComments`,
  //         { postId: id, pageSize: 4, pageIndex: 0 },
  //         {
  //           headers: { Authorization: `Bearer ${user?.token}` },
  //         }
  //       )
  //       .then((response) => {
  //         setIsCommentAdd(true);
  //         commentRef.current.value = "";
  //         axios
  //           .post(
  //             `${process.env.REACT_APP_IPURL}/post/getPostById`,
  //             { postId: postId },
  //             {
  //               headers: {
  //                 Authorization: `Bearer ${
  //                   JSON.parse(localStorage.getItem("sociomeeUser"))?.token
  //                 }`,
  //               },
  //             }
  //           )
  //           .then((res) => {
  //             setFetchedPost(res.data.data.successResult[0]);
  //             setPostComments(response.data.data.successResult);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  // };

  const timeAgoCall = useCallback(
    (time) => {
      return timeAgo(time);
    },
    [isCommentAdd]
  );
  // function handelCommentId(id){
  //   console.log("comment id ",id)
  //   setCommentId(id);
  //   handelReply()
  // }


  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_IPURL}/post/getComments`,
        { postId: postId, pageIndex: 0, pageSize: 10 },
        {
          headers: { Authorization: `Bearer ${user?.token}` },
        }
      )
      .then((res) => {
        setCommentsDataArray(res.data.data.successResult)
        setPostComments(res.data.data.successResult);
        
        // console.log("comments are as    ",res.data.data.successResult)
      })
      .catch((error) => {
        console.log(error);
      });
  }, [postId]);





async function AddReplyComment(comment){
  // console.log("reply comment",comment)
  setCommentsData(`@${comment.userName}`)
  // console.log(commentData)
  setCommentIds(comment.id)
}

  return (
    <>
      <div className={`comments ${allowComments !== 1 ? "d-none" : "d-block"}`}>
        <div className="main-comment">
          {commentsDataArray?.count > 0 ? (
            commentsDataArray?.rows &&
            commentsDataArray?.rows.slice(0,visible).map((comment) => {
              return (
                <>
                  <div key={comment.id}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center">
                        <img src={comment.profileImageThumb} className="rounded-circle border img-fluid mr-2" width={30} height={30} alt="User Profile" />
                        <h4>{comment.fullName}</h4>
                      </div>
                      <h5 className="text-muted pr-1"> {timeAgoCall(comment.createdAt)} </h5>
                       <UncontrolledButtonDropdown className='align-self-center'>
                        <DropdownToggle className='p-0 m-0' color='flat' >
                          <MoreHorizontal size={19} color='#000000' />
                        </DropdownToggle>
                          <DropdownMenu className='bizpagefordropdownmenu'>
                              {/* <DropdownItem  className='dropdownitem'  onClick={toggle} >Reply </DropdownItem> */}
                              <Modal isOpen={modal} toggle={toggle} >
                                  <ModalHeader toggle={toggle}>Modal title</ModalHeader>
                                    <ModalBody>
                                      {/* <Input value={commentData} onChange={handleCommentData} type="text" placeHolder="write your comment"/> */}
                                    </ModalBody>
                                  <ModalFooter>
                                    {/* <Button color="primary" onClick={()=>handleComment(comment)}>
                                      Reply
                                    </Button>{' '} */}
                                    <Button color="secondary" onClick={toggle}>
                                      Cancel
                                    </Button>
                                  </ModalFooter>
                                </Modal>
                                  <DropdownItem
                                      className="dropdownitem"
                                      onClick={() => deleteComment(comment, setShowPostComment)}
                                      style={{ color: 'inherit', transition: 'color 0.3s ease' }} 
                                      onMouseEnter={(e) => e.target.style.color = 'red'} 
                                      onMouseLeave={(e) => e.target.style.color = 'inherit'} >
                                      Delete
                                    </DropdownItem>                             
                          </DropdownMenu>
                                
                       </UncontrolledButtonDropdown>
                    </div>
                    <div className="p-2 pl-5">
                      <p className="text-muted">{comment?.comment}</p>
                      <div className="text-primary">
                          {!comment?.liked ? (
                          <img src="/assets/images/like1.png" alt="Like" width="20" height="20" />) 
                          : (
                          <img src="/assets/images/liked-icon.png" alt="Liked" width="20" height="20" />
                        )}
                        <button
                          className="btn text-primary"
                          onClick={() => commentLikeHandler(comment)}
                        >
                          Like ({comment?.likesCount})
                        </button>
                        <button
                          className="btn text-primary"
                          onClick={() => AddReplyComment(comment)}
                        >
                          Reply ({comment?.replyCount})
                        </button>
                        
                      </div>
                    </div>
                  </div>
                  <div style={{paddingLeft:"120px"}}>
                  <SubComments postId={postId} commentId={comment.id} comment={comment} setCommentsData={setCommentsData} setCommentIds={setCommentIds}  />
                  </div>
                </>
              );
            })
          ) : (
            <p style={{ textAlign: "center" }}>No comments available.</p>
          )}
        </div>
      </div>
      {/* {allowComments === 1 ? (
        <> */}
      {/* <div className="positionForSerachSmile">
            <span class="poll-radio-blk" id="basic-addon1">
              @
            </span> */}
      {/* <input
              type="text"
              style={{ background: "#F9FBF7", paddingRight: "33px" }}
              placeholder="Write a comment..."
              ref={commentRef}
              // placeholder="write a comment.."
              // value={commentData.comment}

              onKeyPress={(e) => e.key === "Enter" && submitComment(postId)}
            /> */}
      {/* <Smile color="#808080" strokeWidth={2} />
            <a
              onClick={() => {
                submitComment(postId);
              }}
            >
              <Send color="#808080" className="iconForcolor" strokeWidth={2} />
            </a>
             */}
      {/* </div> */}
      {/* </>
      ) : null} */}
      {/* if comments length is greater than 4 then display load more option */}{" "}
      {postComments?.count >= 2 && (
        <p
          style={{
            textAlign: "center",
            cursor: "pointer",
            color: "#F6F6F6",
          }}
          onClick={() => handleAllComments(visible)}
        >
          Load more comments ↺
        </p>
      )}
    </>
  );
};

export default Comments;
