import React, { Fragment, useEffect, useState, useRef } from "react";
import { CardBody, Input } from "reactstrap";
import { TextColorList } from "../DummyArray";

import LeftquotationIcon from "../../../NewComponents/IMG/LeftquotationIcon.svg";
import RightquotationIcon from "../../../NewComponents/IMG/RightquotationIcon.svg";
import { ChevronLeft, ChevronRight } from "react-feather";
import ColorPicker from "../Img/colorpicker.png";
import { loadProfileByUserId } from "../../../Services/Actions/UserProfile/getUserProfileByUserIdAction";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const Throught = ({
  selectColorTexts,
  selectBckColor,
  shows,
  setShows,
  handleChanges,
  handleChangeBack,
  showBackGround,
  setShowBackGround,
  textData,
  setTextData,
  postType,
}) => {
  const user = JSON.parse(localStorage.getItem("sociomeeUser"));
  const { userProfileByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [TextColor, setTextColor] = useState(TextColorList);

  const dispatch = useDispatch();
  const textColorContainerRef = useRef(null); // Ref for text color container
  const backgroundColorContainerRef = useRef(null); // Ref for background color container

  useEffect(() => {
    dispatch(loadProfileByUserId());
    if (postType && postType?.caption) {
      setTextData(postType?.caption);
    }
  }, [dispatch, postType, textData]);

  useEffect(() => {
    if (selectColorTexts === selectBckColor) {
      setErrorMessage("Text and background colors can't be the same");
    } else {
      setErrorMessage("");
    }
  }, [selectColorTexts, selectBckColor]);

  const handleTextColorChange = (color) => {
    if (color !== selectBckColor) {
      handleChanges(color);
    } else {
      setErrorMessage("Text and background colors can't be the same");
    }
  };

  const handleBackgroundColorChange = (color) => {
    if (color !== selectColorTexts) {
      handleChangeBack(color);
    } else {
      setErrorMessage("Text and background colors can't be the same");
    }
  };

  // Scroll functions
  const scrollLeft = (ref) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: -ref.current.clientWidth / 2,
        behavior: 'smooth'
      });
    }
  };

  const scrollRight = (ref) => {
    if (ref.current) {
      ref.current.scrollBy({
        left: ref.current.clientWidth / 2,
        behavior: 'smooth'
      });
    }
  };

  return (
    <Fragment>
      <CardBody>
        <div className="d-flex align-items-center py-2">
          <img
            className="rounded-circle border"
            width={30}
            height={30}
            src={userProfileByUserId.profileImage}
            alt="profileImage"
          />
          &nbsp;
          <h5 style={{ color: "#4D4D4D", fontWeight: "600", fontSize: "14px" }}>
            {userProfileByUserId.fullName}
          </h5>
        </div>
        <div style={{ position: 'relative' }}>
          <h3 style={{ color: "#FF007A" }} className="py-2">
            #thoughtOfTheDay
          </h3>
          <div>
            <img src={LeftquotationIcon} alt="icon" />
            <div className="mx-4 text-center">
              <Input
                type="textarea"
                className="d-flex positionForplaceholder inputFortext align-items-center text-center w-100"
                placeholder="Express your thoughts..."
                cols="15"
                rows="5"
                value={textData}
                onChange={(e) => {
                  if (e.target.value.length <= 200) {
                    setTextData(e.target.value);
                  }
                }}
                style={{
                  backgroundColor: selectBckColor,
                  color: selectColorTexts,
                  fontSize: "24px",
                }}
              />
              <p style={{ textAlign: "right", marginTop: "10px", color: "#FF007A" }}>
                Max 200 characters
              </p>
              {errorMessage && (
                <p style={{ color: "#f24147", fontSize: "12px", textAlign: "center", marginTop: "5px" }}>
                  {errorMessage}
                </p>
              )}
            </div>
            <div className="text-right">
              <img src={RightquotationIcon} alt="icon" />
            </div>
          </div>
          <h3>Text Color</h3>
          {!shows ? (
            <div
              onClick={() => setShows(!shows)}
              className="mt-1"
              style={{ cursor: "pointer" }}
            >
              <img src={ColorPicker} width={30} height={30} />
            </div>
          ) : (
            <div className="d-flex gap-3 align-items-center mt-2">
              <ChevronLeft
                size={30}
                onClick={() => scrollLeft(textColorContainerRef)}
                style={{ cursor: 'pointer' }}
              />
              <div
                ref={textColorContainerRef}
                style={{ display: 'flex', overflowX: 'hidden', whiteSpace: 'nowrap', width: '100%' }}
              >
                {TextColor.length > 0 && TextColor.map((color) => (
                  <div
                    key={color.colorHexCode}
                    className={
                      selectColorTexts === color.colorHexCode
                        ? "Text_onChange_active_blk"
                        : "Text_onChange_Unactive_blk"
                    }
                    active={selectColorTexts === color.colorHexCode}
                  >
                    <div
                      className="Text_onChange_section_one"
                      style={{ background: color.colorHexCode }}
                      onClick={() => handleTextColorChange(color.colorHexCode)}
                    ></div>
                  </div>
                ))}
              </div>
              <ChevronRight
                size={30}
                onClick={() => scrollRight(textColorContainerRef)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          )}
          <h3 className="mt-3">Background Color</h3>
          {!showBackGround ? (
            <div
              onClick={() => setShowBackGround(!showBackGround)}
              className="mt-1"
              style={{ cursor: "pointer" }}
            >
              <img src={ColorPicker} width={30} height={30} />
            </div>
          ) : (
            <div className="d-flex gap-3 align-items-center mt-2">
              <ChevronLeft
                size={30}
                onClick={() => scrollLeft(backgroundColorContainerRef)}
                style={{ cursor: 'pointer' }}
              />
              <div
                ref={backgroundColorContainerRef}
                style={{ display: 'flex', overflowX: 'hidden', whiteSpace: 'nowrap', width: '100%' }}
              >
                {TextColor.map((color) => (
                  <div
                    key={color.colorHexCode}
                    className={
                      selectBckColor === color.colorHexCode
                        ? "Text_onChange_active_blk"
                        : "Text_onChange_Unactive_blk"
                    }
                    active={selectBckColor === color.colorHexCode}
                  >
                    <div
                      className="Text_onChange_section_one"
                      style={{ background: color.colorHexCode }}
                      onClick={() => handleBackgroundColorChange(color.colorHexCode)}
                    ></div>
                  </div>
                ))}
              </div>
              <ChevronRight
                size={30}
                onClick={() => scrollRight(backgroundColorContainerRef)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          )}
        </div>
      </CardBody>
    </Fragment>
  );
};

export default Throught;
