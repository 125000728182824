import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Col, Row } from "reactstrap";
import axios from "axios";


const FeelingNew = ({
    setFeelingAndActvityTabActive,
    feelingList,
    postType,
    feelingActivityTab,
    setFeelingActivityTab,
    val, 
    activeTab,
    toggle,
    onSelectFeelingCategoryId,
    feelingCategoryId,
    onSelectFeelingId ,
    feelingCategories,
    selectedFeeling,
    setSelectedFeeling,
    showSelectedFeeling,
    setShowSelectedFeeling
    ,postEditValue
   }) => {


  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  // const [feelingCategoryId, setFeelingCategoryId] = useState("");
  const [feelingId, setFeelingId] = useState("");

//   useEffect(()=>{

//   },[showSelectedFeeling ,selectedFeeling])


  const handleFeeling=(id,feelingCategoryId)=>{
    onSelectFeelingCategoryId(feelingCategoryId)
    setShowSelectedFeeling(false)
    onSelectFeelingId(id)
    setFeelingAndActvityTabActive(false)
    if (!val)val=0
    if(val===0){
      postType="text";
    }else if(val===7.6){
      postType="recomendation";
    }else if(val===0.1){
      postType="media";
    }else if(val===7.1){
      postType="thought";
    }else if(val===7.3){
      postType="event"
    }
    
    if(postType==="recomendation"){
      toggle(`/modal/${7.6}`);
    }else if(postType==="thought"){
      toggle(`/modal/${7.1}`);
    }else if(postType==="text"){
      toggle(`/modal/${0}`);
    }else if(postType==='media'){
      toggle(`/modal/${0.1}`);
    }else if(postType==="text"){
      toggle(`/modal/${0}`);
    }else if(postType==="event"){
      toggle(`/modal/${7.3}`);
    }
  }




// ===============>> main function <<=====================

  return (
    <Fragment>
        <div className="Feeling_scroll_box">
          <div className="mx-3">
            <Row>
              {feelingList.length > 0 &&
                feelingList.map((item) => (
                  <Col md={4} sm={6} className="mb-3" key={item.id}>
                    <div
                      className="Feeling_Main_blk_Subsection"
                      onClick={() => {
                      handleFeeling(item.id,item?.feelingCategoryId);
                      }}
                    >
                      <div className="">
                        <img
                          src={item.iconURL}
                          alt="emoji"
                          width="40px"
                          height="40px"
                        />
                      </div>
                      <h4 className="ml-3">{item.name}</h4>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
        </div>
    </Fragment>
  );
};

export default FeelingNew;

