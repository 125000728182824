import * as types from "../../Constants/SocialFeed/index";

const initialState = {
  allUserPosts: [],
  pageIndex: 0,
  loading: true,
};

const getAllUserPosts = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_USER_POSTS:
      return {
        ...state,
        allUserPosts: action.payload.posts,
        pageIndex: action.payload.pageIndex,
        loading: false,
      };
    case types.LOAD_MORE_USER_POSTS: // Handle loading more posts
      return {
        ...state,
        allUserPosts: [...state.allUserPosts, ...action.payload.posts],
        pageIndex: action.payload.pageIndex,
        loading: false,
      };
    case types.CREATE_POST:
    case types.DELETE_POST:
      return state;
    default:
      return state;
  }
};

export default getAllUserPosts;
