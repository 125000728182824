import React, { Fragment, useEffect } from "react";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import {
  Button,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormText,
  Input,
  Row,
  FormGroup,
  Label,
  UncontrolledTooltip,
  UncontrolledButtonDropdown,
} from "reactstrap";
import LeftquotationIcon from "../../../NewComponents/IMG/LeftquotationIcon.svg";
import RightquotationIcon from "../../../NewComponents/IMG/RightquotationIcon.svg";
import {
  AlertTriangle,
  ChevronDown,
  Info,
  Circle,
  MapPin,
  Minus,
  Plus,
} from "react-feather";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadProfileByUserId } from "../../../Services/Actions/UserProfile/getUserProfileByUserIdAction";
import { loadAlertLevel } from "../../../Services/Actions/SocialFeed/getAlertDataAction";

const AlertPost = ({
  onSelectedAlert,
  onSelectAlertLevel,
  selectedAlertLevels,
  onSelectAlertRange,
  onSelectAlertLocation,
  setTextareaValue,
  textareaValue,
  alertLevelData,
  setAlertLevelData,
  setSliderValue,
  sliderValue,
  setSelectedAlertLevelName,
  selectedAlertLevelName,
  postEditValue
}) => {
// useEffect(()=>[
//   setTextareaValue(postEditValue.caption)
// ])
  
  const { userProfileByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );
  const { alertLevel } = useSelector((state) => state.getAlertData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadProfileByUserId());
    dispatch(loadAlertLevel());
    setAlertLevelData(alertLevel);
    if(postEditValue){
      setTextareaValue(postEditValue.caption)
      setSliderValue(postEditValue.alertRangeMeter)
      setSelectedAlertLevelName(postEditValue.alertLevel?.name)
      onSelectAlertLevel(postEditValue?.alertLevel?.alertLevelId);

    }
  }, []);

  // Event handler for when the slider value changes
  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };
  onSelectAlertRange(sliderValue)
  const handleTextareaChange = (event) => {
    const newValue = event.target.value;
    setTextareaValue(newValue);
    onSelectedAlert(newValue);
  };
  // Function to handle the alert level change
  const handleAlertLevelChange = (event) => {
    const selectedValue = event.target.value;
    for(let i=0;i<alertLevelData.length;i++){
      if(alertLevelData[i].id===selectedValue){
        setSelectedAlertLevelName(alertLevelData[i].name);
      }
    }
    
    onSelectAlertLevel(selectedValue);
  };

  // Event handler for increasing the slider value
  const handleIncreaseClick = () => {
    if(sliderValue < 15000){
      setSliderValue((prevValue) => prevValue + 1);
    }
  };

  // Event handler for decreasing the slider value
  const handleDecreaseClick = () => {
    if(sliderValue >1){
      setSliderValue((prevValue) => prevValue - 1);
    }
  };
  // const handelClick=(name)=>{
  //   console.log("selected name",name)
  // }

  // const PrettoSlider = styled(Slider)({
  //   color: "#81C14B",
  //   height: 4,
  //   "& .MuiSlider-track": {
  //     border: "none",
  //   },
  //   "& .MuiSlider-thumb": {
  //     height: 24,
  //     width: 24,
  //     backgroundColor: "#fff",
  //     border: "2px solid currentColor",
  //     "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
  //       boxShadow: "inherit",
  //     },
  //     "&:before": {
  //       display: "none",
  //     },
  //   },
  //   "& .MuiSlider-valueLabel": {
  //     lineHeight: 1.2,
  //     fontSize: 12,
  //     background: "unset",
  //     padding: 0,
  //     width: 32,
  //     height: 32,
  //     borderRadius: "50% 50% 50% 0",
  //     backgroundColor: "#52af77",
  //     transformOrigin: "bottom left",
  //     transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
  //     "&:before": { display: "none" },
  //     "&.MuiSlider-valueLabelOpen": {
  //       transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
  //     },
  //     "& > *": {
  //       transform: "rotate(45deg)",
  //     },
  //   },
  // });

  // ================== Main_return_function ================//
  return (
    <Fragment>
      <CardBody>
        <div className="d-flex align-items-center my-2 py-2">
          <img
            className="rounded-circle border"
            width={40}
            height={40}
            src={userProfileByUserId.profileImage}
            alt="Profile"
          />
          &nbsp;
          <h3 style={{ color: "#4D4D4D", fontWeight: "600", fontSize: "16px" }}>
            {userProfileByUserId.fullName}
          </h3>
        </div>
        <div>
          <h3 style={{ color: "#F30000" }} className="py-2">
            #creatalert
          </h3>
          <div>
            <img src={LeftquotationIcon} alt="icon" />
            <div className="mx-4 text-center">
              <Input
                type="textarea"
                className="d-flex positionForplaceholder inputFortext align-items-center text-center w-100"
                placeholder="Define the threat..."
                cols="15"
                rows="5"
                value={textareaValue}
                style={{ fontSize: '16px' }}
                onChange={handleTextareaChange}
                maxLength="320"
              />
            </div>
            <div className="text-right">
              <img src={RightquotationIcon} alt="icon" />
            </div>
            <p className="Post_creation_text_limt mt-1 text-end">
              Max 320 characters
            </p>
          </div>
        </div>
        <Row>
          <Col>
            <div className="d-flex align-items-center">
              <AlertTriangle className="mr-2" size={18} color="#FF822E" />
              <h4>Alert Level</h4>
            </div>
            <div className="my-2">
              <FormGroup>
                <Input
                  id="Alert-Level"
                  name="select"
                  type="select"
                  className="Alert_form_input_field"
                  onChange={handleAlertLevelChange}
                >
                  <option value="">{selectedAlertLevelName}</option>
                  {alertLevelData &&
                    alertLevelData.map((level) => (
                      <option key={level.id} value={level.id} >
                        <span>
                          <Circle
                            size={10}
                            fill={level.tagBackColor}
                            color={level.tagBackColor}
                          />
                          {level.name}
                        </span>
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </div>
          </Col>
          <Col>
            <div className="d-flex align-items-center">
              <MapPin className="mr-2" size={18} color="#16C31E" />
              <h4>Post Alert (km)</h4>
            </div>
            <div className="my-2">
              <div className="d-flex align-items-center flex-wrap">
                <div className="col-sm-8 p-0">
                  <Slider
                    size="small"
                    value={sliderValue}
                    onChange={handleSliderChange}
                    aria-label="Small"
                    valueLabelDisplay="auto"
                    max={15000}
                    min={1}
                    sx={{
                      color: '#81c14b',
                      '& .MuiSlider-thumb': { backgroundColor: '#81c14b' },
                      '& .MuiSlider-track': { height: 6 },
                      '& .MuiSlider-rail': { height: 6 },
                    }}
                    style={{ width: '95%' }} 
                  />
                </div>
                <div className="col-sm-4 d-flex align-items-center p-0.1 mt-2 mt-sm-0">
                 <Button className="btn-icon square p-0 ml-1" color="primary" onClick={handleDecreaseClick}>
                    <Minus size={20} />
                 </Button>
                  <span style={{ minWidth: '40px', textAlign: 'center', display: 'inline-block' }}>
                    {sliderValue}
                  </span>
                 <Button className="btn-icon square p-0 m-0" color="primary" onClick={handleIncreaseClick}>
                   <Plus size={20} />
                 </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Fragment>
  );
};

export default AlertPost;
{
               /* <UncontrolledButtonDropdown className="w-100" right>
   <DropdownToggle
                  outline
                  color="primary"
                  className="AlertPost_DropSelect_section"
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex  align-items-center">
                      <Circle size={10} fill="#FFD600" color="#FFD600" />
                      <span className="ml-2 Alert_Post_span"></span>
                    </div>
                    <ChevronDown size={20} />
                  </div>
                </DropdownToggle>
                <DropdownMenu className="Alert_menu">
                  {alertLevelData.map((item) => (
                    <DropdownItem className="Alert_item" key={item.id}>
                      <Circle
                        size={10}
                        fill={item.tagBackColor}
                        color={item.tagBackColor}
                      />
                      <span
                        onClick={() => {handleChange(item); console.log(item)}}
                        className="ml-2"
                      >
                        {item.name}
                      </span>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledButtonDropdown> */
}
