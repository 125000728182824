import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import { Calendar, Image, Info, XCircle } from "react-feather";
import axios from "axios";



const Recommendation = ({
  onRecommendationChange,
  postType,
  states,
  currState,
  recommendationValue,
  postEditValue,
  setPostButtonDisable,
  recommendationCoverImageURL,
  setRecommendationCoverImageURL

}) => {
  const [recommendation, setRecommendation] = useState(recommendationValue);
  const [Images, setImages] = useState("");

  const defaultKeyword = "I am seeking a recommendation for ";

  const { userProfileByUserId } = useSelector(
    (state) => state.getUserProfileByUserIdData
  );

  // Handle input change
  const handleRecommendationChange = (e) => {
    const inputValue = e.target.value;

    // Ensure that the default keyword is always at the start
    if (inputValue.startsWith(defaultKeyword)) {
      setRecommendation(inputValue);
      onRecommendationChange(inputValue);
    } else {
      // Revert to the default keyword + whatever user has typed
      setRecommendation(defaultKeyword);
      onRecommendationChange(defaultKeyword);
    }
  };

  useEffect(() => {
    if (postEditValue && postEditValue.caption) {
      setRecommendation(postEditValue.caption);
    } else if (recommendationValue) {
      setRecommendation(recommendationValue);
    }
  }, [recommendationValue, postEditValue]);

  const removeCoverImage = () => {
    setRecommendationCoverImageURL(null);

  }

  const toggleTheFile = (e) => {
    document.querySelector(".InputFile").click();
  };

  const handleChngImg = async (e) => {
    try {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("files", file);
      formData.append("uploadFor", "postRecommendationCoverImage");

      const response = await axios.post(
        `${process.env.REACT_APP_IPURL}/admin/uploadFile`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("sociomeeUser"))?.token
              }`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.data && response.data.data.successResult) {
        // Get the link from the API response
        const uploadedImageLink = response.data.data.successResult;
        setRecommendationCoverImageURL(uploadedImageLink[0]);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };




  // ===================Main return function====================================
  return (
    <Fragment>
      <CardBody>
        <div className="d-flex align-items-center my-2 py-2">
          <img
            className="rounded-circle border"
            width={30}
            height={30}
            src={userProfileByUserId.profileImage}
          />
          &nbsp;
          <h5 style={{ color: "#4D4D4D", fontWeight: "600", fontSize: "14px" }}>
            {userProfileByUserId.fullName}
          </h5>
        </div>
        <div>
          <div className="mx-4 text-center">
            <Input
              type="textarea"
              className="d-flex text-primary Recommendation_input_box positionForReccplaceholder align-items-center text-left w-100"
              value={recommendation}
              onChange={handleRecommendationChange}
              cols="15"
              rows="5"
              maxLength="320"
            />
            <p className="Post_creation_text_limt mt-1 text-end">
              Max 320 characters
            </p>
          </div>
        </div>
        <Label for="Biz-Catagory">
          <div className=" BizInput_heading_section ">
          &nbsp;&nbsp;&nbsp;&nbsp;<b>Upload cover image</b>&nbsp;  
            <Info id="coverId" fill="#C4C4C4" color="#fff" size={17} />
            <UncontrolledTooltip placement="right" target="coverId">
              please upload cover photo
            </UncontrolledTooltip>
          </div>
        </Label>

        <div className="AddMedia_photo_section_one">
          <Card className="Addmedia_sub_section_one">
            {!recommendationCoverImageURL ? (
              <div
                onClick={(e) => {
                  toggleTheFile(e);
                }} >
                <div className="text-center">
                  <Image />
                  <h3 className="py-2"> Add Photo</h3>
                  <p>or drag and drop</p>
                </div>
                <input type="file" name=""
                  accept="image/*" // Restrict to image files
                  onChange={handleChngImg}
                  className="InputFile d-none"
                  id=""
                />
              </div>
            ) : (

              <div className="Media_Img_section_one">
                <img src={`${process.env.REACT_APP_IMAGEURL}${recommendationCoverImageURL}`} className="img-fluid" />
                <div className="Img_right_section_one " onClick={() => setRecommendationCoverImageURL(null)} >
                  <XCircle color="#ffff" onClick={removeCoverImage} />
                </div>
              </div>
            )}

          </Card>
        </div>
      </CardBody>
    </Fragment>
  );
};

export default Recommendation;
