import React, { useState, useEffect } from "react";
import { Checkbox } from "@mui/material";
import { Button } from "react-bootstrap";
import { Check, Search } from "react-feather";
import {Card,CardBody,Col,Input,InputGroup,Modal,ModalBody,Row,} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCreatetConnectionListAction } from "../../../Services/Actions/Connection/getCreatedCategoryAction";
import axios from "axios";

const AddRemainingSociomateModal = (props) => {

  const dispatch = useDispatch();
  const [searchItem, setSearchItem] = useState("");
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkboxes, setCheckboxes] = useState({});
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const { remainingSociomateList } = useSelector((state) => state.remianingSociomateData);

  const filteredData = remainingSociomateList?.filter((item) => {
    const fullNameMatch = item.fullName?.toLowerCase().includes(searchItem.toLowerCase());
    const userNameMatch = item.userName?.toLowerCase().includes(searchItem.toLowerCase());
    return fullNameMatch || userNameMatch;
  });
  
  const addUserToConnection = async () => {
    try {
        const body = {
            followingUserIds:selectedUserIds,
            connectionIds: [props.connectionId]
        };
        let user = JSON.parse(localStorage.getItem("sociomeeUser"));
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user?.token}`,
            },
        };
        const response = await axios.post(
            `${process.env.REACT_APP_IPURL}/hamBurgerMenu/addUserToList`,
            body,
            config
        );
    } catch (error) {
        console.log("Error getting add user to connection:", error);
    }
  };
    //  API CALL ON SAVE Button
  const handleSaveClick = () => {
    addUserToConnection();
    setCheckboxes({});
    setSelectedUserIds([]);
    props.openAddUserModal();  // Ensure this closes the modal
    setTimeout(() => {
      dispatch(getCreatetConnectionListAction(props.categoryName))
    }, 1000); 
  };

  const toggleCheck = (userId) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [userId]: !prevCheckboxes[userId],
    }));

    setSelectedUserIds((prevSelectedUserIds) => {
      if (prevSelectedUserIds.includes(userId)) {
        // User is already selected, so remove them
        return prevSelectedUserIds.filter((id) => id !== userId);
      } else {
        // User is not selected, so add them
        return [...prevSelectedUserIds, userId];
      }
    });
  };

  const selectAll = (value) => {
    const updatedCheckboxes = {};
    const selectedIds = [];

    filteredData.forEach((data) => {
      updatedCheckboxes[data.id] = value;
      if (value) {
        selectedIds.push(data.id);
      }
    });
    setCheckboxes(updatedCheckboxes);
    setSelectedUserIds(selectedIds); 
  };

  useEffect(() => {
    let allChecked = true;
    for (const userId in checkboxes) {
      if (!checkboxes[userId]) {
        allChecked = false;
        break;
      }
    }
    setCheckedAll(allChecked);
  }, [checkboxes]);

  useEffect(() => {
    console.log("Selected User IDs:", selectedUserIds);
  }, [selectedUserIds]);

  return (
    <>
      {props.openModal !== false && (
        <Modal isOpen={props.openModal} className="modal-dialog-centered">
          <ModalBody className="Modal_Body_Section">
            <Row className="border-bottom d-flex align-items-center justify-content-between">
              <Col xl="6" className="d-flex align-items-start">
                <h3>Add SocioMate</h3>
              </Col>
              <Col xl="6" className="d-flex justify-content-end mb-2">
                <Button
                  variant="primary"
                  className="ml-2"
                  onClick={handleSaveClick}
                  disabled={Object.keys(checkboxes).length === 0 || !Object.values(checkboxes).includes(true)}
                  style={{
                    backgroundColor: Object.keys(checkboxes).length === 0 || !Object.values(checkboxes).includes(true) ? '#E6F3DB' : '',
                    color: Object.keys(checkboxes).length === 0 || !Object.values(checkboxes).includes(true) ? '#000' : '#fff',
                    cursor: Object.keys(checkboxes).length === 0 || !Object.values(checkboxes).includes(true) ? 'not-allowed' : 'pointer'
                  }}
                >
                Save
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    props.openAddUserModal(); 
                    setCheckboxes({}); 
                    setSelectedUserIds([]);
                  }}
                  className="ml-2"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
            <InputGroup className="input-group-merge d-flex px-3 py-1 my-3 rounded bg-light align-items-center mr-2">
              <Search color="#666666" size={16} />
              <Input
                type="search"
                placeholder=" Search..."
                className="coonection-input-style"
                value={searchItem}
                onChange={(e) => setSearchItem(e.target.value)}
              />
            </InputGroup>
            <div className="d-flex align-items-center">
              <Checkbox
                className="Check_Input_section"
                onChange={(event) => selectAll(event.target.checked)}
                checked={checkedAll}
              />
              <h5 className="ml-2">Select All</h5>
            </div>
            <div className="modal-scroll">
              {filteredData.map((data) => (
                <Card key={data.id} className="m-2 shadow-sm">
                  <CardBody className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="pr-1">
                        <Checkbox
                          {...label}
                          onChange={() => toggleCheck(data.id)}
                          checked={checkboxes[data.id] || false}
                          className="Check_Input_section"
                        />
                      </div>
                      <div className="d-flex">
                        <div className="rem-mob-view-small">
                          <img
                            src={data.profileImage}
                            className="img-fluid rounded-circle"
                            height="40px"
                            width="40px"
                            alt=""
                          />
                        </div>
                        <div className="px-1">
                          <h4>{data.fullName}</h4>
                          <p className="">{data.userName}</p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              ))}
            </div>
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default AddRemainingSociomateModal;
