import React from 'react';
import { useState,useEffect } from 'react';
import { Check, ChevronRight, Copy, Flag, MinusCircle, MoreVertical, PlusCircle, Share, UserMinus, X } from 'react-feather'
import { NavLink } from 'react-router-dom';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap';
import { loadAllUserSciomate } from '../../Services/Actions/Connection/getSociomateAction';
import india_svg from '../../NewComponents/IMG/india_svg.svg';
import BlockModal from './ConnectionModal/BlockModal';
import ConnectionReportModal from './ConnectionModal/ConnectionReportModal';
import AddCategoryModal from './ConnectionModal/AddCategoryModal';
import RemoveModal from './ConnectionModal/RemoveModal';
import ConnectionShareModal from './ConnectionModal/ConnectionShareModal';
import UnfollowModal from './ConnectionModal/UnfollowModal';
import { useSelector, useDispatch } from 'react-redux';
import PublicAccount from './ConnectionModal/PublicAccount';
import './Style.css'


const SociomateScreen = () => {
  const { getUserSociomate } = useSelector(state => state.userSociomateData)
  const [selectedRequest, setSelectedRequest] = useState(null);
 //search opration 
  const [searchItem, setSearchItem] = useState("");
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("");

  const filteredData = getUserSociomate?.rows?.filter((item) =>
     item.fullName.toLowerCase().includes(searchItem.toLowerCase()));

  useEffect(()=>{
    loadAllUserSciomate();
  },[searchItem])
  // ------------- Unfollow Modal State -------------
 
  const [unfollowModalOpen, setUnfollowModalOpen] = useState(false)
  const toggleUnfollowModal = (request) => {
    setUnfollowModalOpen((prevState) => !prevState)
    setSelectedRequest(request);
  }

  // ------------- share Modal State -------------

  const [shareOpen, setShareOpen] = useState(false)
  const toggleShareOpen = () => {
    setShareOpen((prevState) => !prevState)
  }
  // ------------- Remove Followers Modal State -------------
  const [removeModalOpen, setRemoveModalOpen] = useState(false)
  const toggleRemoveModalOpen = (request) => {
    setRemoveModalOpen((prevState) => !prevState)
    setSelectedRequest(request)
  }

  //  ------------- Start Add Category State -------------
  const [addCategoryOptions, setAddCategoryOptions] = useState(false)
  const toggleAddCategory = () => {
    setAddCategoryOptions((preState) => !preState)
  }
  //  ------------- Start Reported Option State -------------

 
  // Reported Option State
  const [reportOpen, setReportOpen] = useState(false)
  const toggleReport = (request) => {
      setReportOpen((preState) => !preState)
      setSelectedRequest(request)   
  }

  const[reportConfirm,setReportConfirm] = useState(false)
  useEffect(()=>{
      if(reportConfirm){
          setTimeout(() => {
            dispatch(loadAllUserSciomate());
            setReportConfirm(false);
          }, 1000);
       }  
  },[reportConfirm])

  //block 
  const [blockModalOpen, setBlockModalOpen] = useState(false);
  const toggleBlockModalOpen = () => {
        setBlockModalOpen((prevState) => !prevState);
    };
  const[blockConfirm,setBlockConfirm] = useState(false)
  useEffect(()=>{
      if(blockConfirm){
        setTimeout(() => {
            dispatch(loadAllUserSciomate());
            setBlockConfirm(false);
        }, 1000);
      } 
  },[blockConfirm])

  return (
    <>
      <div className="mppage-heading">
        <div className="group-custom-block">
          <div className="heading-podcast-blk">
            <h3 className='connection-sub-heading'>SocioMate ({getUserSociomate?.rows?.length})</h3>
            {getUserSociomate?.rows?.length === 0 ? null : (
                <NavLink to='/SocioMateScreens'>
                   <h5 className=''>View All <ChevronRight size={20} color='#81C14B' className='ml-1' /></h5>
                </NavLink>
            )}
 
          </div>
          <Row className='connections_PeopleThisGrid'>
            {
              filteredData && filteredData?.slice(0, 6).map((request, index) => {
                return <Col lg='4' className='w-100' >
                  <div key={index} className="connection-card  box-shadow-style content-mp-block d-flex justify-content-between">
                    <div className="media-body d-md-block">
                      <div class="media media-new d-flex align-items-start">
                        <div class="user-img d-flex align-items-start popover___wrapper " >
                          <img src={request?.profileImage}
                            className="rounded-circle " height={45} width={45} alt="shivam singh" />
                          <span class="available-stats online"></span>
                          <PublicAccount request={request} />
                        </div>
                        <div class="media-body d-md-block pl-1">
                        <NavLink to='/MyProfile' ><h4 className='m-0 name-style d-inline-block text-truncate text-wrap'>{request?.fullName?.substring(0, 21) || 'NA'}</h4></NavLink>
                          {/* <h4 className='m-0 name-style d-inline-block text-truncate' style={{ width: '120px' }}>{'name'}</h4> */}
                          <div className='d-flex'>
                            <p className='user-name-style'>{request?.userName || "NA"} |</p>
                            <span>
                                <img
                                  src={request?.countryData[0]?.flagURL}
                                  className="ml-1 rem-mob-view-small"
                                  alt="..."
                                  width={19}
                                  height={13}
                                />
                            </span>
                          </div>
                          <h6 className='professional-style my-1 rem-mob-view-small'>{request?.profession || "No Profession"}</h6>
                          {/*<NavLink to='/ConnectionMenuScreen' className='d-flex justify-content-between'>
                            <span className='green-text-style'>My colleagues</span>
                          </NavLink>*/}
                        </div>
                      </div>
                    </div>
                    <div className='d-flex align-items-end flex-column'>
                      <UncontrolledButtonDropdown className='d-flex align-items-start flex-column justify-content-end pr-0 rem-mob-view-small' direction='left'>
                        <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle pb-0 pr-0'>
                          <MoreVertical size={22} className='pr-0' />
                        </DropdownToggle>
                        <DropdownMenu className='Connection-dropdown'>
                          <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={()=>{toggleUnfollowModal(request)}}><UserMinus size={15} className='mr-2' />Unfollow</DropdownItem>
                          <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={()=>{toggleRemoveModalOpen(request)}} ><MinusCircle size={15} className='mr-2' />Remove Follower</DropdownItem>
                          <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleShareOpen}><Share size={15} className='mr-2' />Share Profile</DropdownItem>
                          <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleAddCategory}><PlusCircle size={15} className='mr-2' />Add to Category</DropdownItem>
                          <DropdownItem className='border-bottom py-2 menu-hover-style'><Copy size={15} className='mr-2' />Copy Profile URL</DropdownItem>
                          <DropdownItem className='border-bottom py-2 menu-hover-style'  
                            onClick={()=>{
                                toggleBlockModalOpen();
                                setUserId(request.id);
                              }}
                            ><MinusCircle size={15} className='mr-2 icon-color-style' />Block</DropdownItem>
                          <DropdownItem className='py-2 menu-hover-style' onClick={()=>{toggleReport(request)}}>
                            <Flag size={15} className='mr-2' />Report User
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                      <div role="button" className='mt-1 d-flex align-items-end' onClick={toggleUnfollowModal}>
                        <Button color='flat-success' className='btn-transparent mt-3 pb-0 pr-0' onClick={()=>{toggleUnfollowModal(request.id)}}>
                          <Check size={15} color='#666666' />&nbsp; <p className='font-weight-bold text-right'>Following</p>
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              })
            }
          </Row>
          <Row>
            <Col xl='12' className='d-flex justify-content-center'>
            <Col xl='12' className='d-flex justify-content-center'>
                  {getUserSociomate?.rows?.length===0 && <p className='text-center connection-sub-heading'>No Requests Found</p>}
            </Col>
            </Col>
          </Row>
        </div>
      </div>
      {/*   ----------- Add Report Option ----------- */}
      <ConnectionReportModal
        request={selectedRequest}
        modalOpen={reportOpen}
        reportModalOpen={toggleReport}
        setReportConfirm ={setReportConfirm}
      />
      {/*   ----------- Add CategoryModal Status ----------- */}
      <AddCategoryModal
        openModal={addCategoryOptions}
        cateModalOpen={toggleAddCategory}
      />
      {/* ----------- Remove SocioMate Modal ----------- */}
      <RemoveModal
         request={selectedRequest}
        openModal={removeModalOpen}
        removeModalOpen={toggleRemoveModalOpen}
      />
      {/*------------------------- block modal----------------------- */}
      { blockModalOpen && (
        <BlockModal
            request={userId}
            openModal={blockModalOpen}
            removeModalOpen={toggleBlockModalOpen}
            setBlockConfirm={setBlockConfirm}
        />
      )}
      {/* ----------- Start Share Modal -----------*/}
      <ConnectionShareModal
        openModal={shareOpen}
        shareModalOpen={toggleShareOpen}
      />
      {/* ------------- Unfollow Modal Modal -------------*/}
      <UnfollowModal
        request={selectedRequest}
        openModal={unfollowModalOpen}
        unfollowModalOpen={toggleUnfollowModal}
      />
    </>
  )
}

export default SociomateScreen;