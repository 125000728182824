import { Checkbox } from '@mui/material'
import React from 'react'
import { Search } from 'react-feather'
import { Card, CardBody, Col, Input, InputGroup, Modal, ModalBody, Row } from 'reactstrap'
import { MutualFriendList } from '../HoverComponents/MutualFriendList'
import { Button } from 'react-bootstrap'

const AddCategoryModal = (props) => {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const addCategoryOption = [
    {
      addCatOption: "School Friends",
    },
    {
      addCatOption: "College Friends",
    },
    {
      addCatOption: "School Friends",
    },
    {
      addCatOption: "Office colleagues",
    },
    {
      addCatOption: "College Friends",
    },
    {
      addCatOption: "College Friends  ",
    }
  ]

  return (
    <>
      {
        props.openModal !== false &&
        <Modal isOpen={props.openModal} toggle={props.cateModalOpen} className='modal-dialog-centered'>
          <ModalBody className='Modal_Body_Section'>
            <Row toggle={props.cateModalOpen} className='border-bottom d-flex align-items-center justify-content-between'>
              <Col xl='8'>
                <h3>Select Category</h3>
              </Col>
              <Col xl='4' className='d-flex justify-content-end'>
                <Button variant="outline-primary" className='my-2' onClick={props.cateModalOpen}>
                  Save
                </Button>
              </Col>
            </Row>
            <InputGroup className="input-group-merge d-flex px-3 py-1 my-3 rounded bg-light align-items-center mr-2">
              <Search color='#666666' size={16} />
              <Input type='search' placeholder=" Search Category..." className='coonection-input-style' />
            </InputGroup>
            <div className='modal-scroll modal_scrollconnections'>
              {addCategoryOption.map((data, index) => (
                <Card className='w-100 mt-2'>
                  <CardBody>
                    <div key={index} className='d-flex align-items-center'>
                      <div>
                        <Checkbox {...label} className='Check_Input_section' />
                      </div>
                      <div className='ml-3'>
                        <h4>{data.addCatOption}</h4>
                        <MutualFriendList />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              ))}
            </div>
          </ModalBody>
        </Modal>
      }</>
  )
}

export default AddCategoryModal