import React from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Button } from 'react-bootstrap'
import { loadAllRemoveFollower } from '../../../Services/Actions/Connection/getRemoveFollowerAction'
import { useDispatch } from 'react-redux'

const NewRemoveModal = ({userId, fullName, removeModalOpen, openModal, setRemoveConfirm}) => {
    const dispatch = useDispatch();
    const handleRemove = ()=>{
        dispatch(loadAllRemoveFollower(userId));
        removeModalOpen();
        setRemoveConfirm(true);
    }
    return (
     
        <>
            {
                openModal !== false &&
                <Modal isOpen={openModal} toggle={removeModalOpen} className='modal-dialog-centered'>
                    <ModalHeader onClick={removeModalOpen} className='d-flex justify-content-center Media_header' >
                        <h3>Remove SocioMate</h3>
                    </ModalHeader>
                    <ModalBody className='text-center'>
                        <h4 className='py-2'>Do you really want to remove {fullName}</h4>
                    </ModalBody>
                    <ModalFooter className='d-flex border-0 align-items-center justify-content-center '>
                        <Button variant='outline-secondary' className='w-25' onClick={removeModalOpen}>
                            <span className='ml-1 w-25'>Cancel</span>
                        </Button>
                        <Button variant='danger' className='w-25' onClick={handleRemove}>
                            <span className='ml-1 w-25'>Remove</span>
                        </Button>
                    </ModalFooter>
                </Modal>
            }
        </>
    )
}

export default NewRemoveModal