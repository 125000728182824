import React, { useEffect, useState } from "react";
import {
  CheckCircle,
  Copy,
  Flag,
  Grid,
  Info,
  List,
  MoreVertical,
  XCircle,
  MinusCircle,
} from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown,
  Card,
  CardBody,
  UncontrolledTooltip,
  CustomInput,
} from "reactstrap";
import { getUserFollowingRequests } from "../../../Services/Actions/Connection/getUserFollowingRequest";
import { getUserApproveFollowRequest } from "../../../Services/Actions/Connection/getUserApproveDisapprove";
import { getUserDispproveFollowRequest } from "../../../Services/Actions/Connection/getUserApproveDisapprove";
import {} from "../../../Services/Actions/Connection/getUserReportAction";
import Header from "../../Header";
import LeftSidebar from "../../LeftSidebar";
import RightSidebar from "../../RightSidebar";
import ConnectionPlaceMenu from "./ConnectionPlaceMenu";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { FollowReqestTab } from "./FollowReqestTab";
import { Button } from "react-bootstrap";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import decline from "../../../NewComponents/IMG/Frame 2551.svg";
import accept from "../../../NewComponents/IMG/Frame 2560.svg";
import india_svg from "../../../NewComponents/IMG/india_svg.svg";
import { PendingRequest } from "../ListView/PendingRequest";
import { MutualFriendList } from "../HoverComponents/MutualFriendList";
import BlockModal from "../ConnectionModal/BlockModal";
import ConnectionReportModal from "../ConnectionModal/ConnectionReportModal";
import ConnectionShareModal from "../ConnectionModal/ConnectionShareModal";
import { RejectModal, SingleRejectModal } from "../ConnectionModal/RejectModal";
import { AcceptModal, SingleAcceptModal } from "../ConnectionModal/AcceptModal";
import PrivateAccount from "../ConnectionModal/PrivateAccount";
import CommanHeader from "../CommanHeader";
import "../Style.css";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Link } from "react-router-dom";

const FollowRequestsScreen = () => {
  const { userFollowingRequests } = useSelector(
    (state) => state.userFollowingRequestsData
  );

  useEffect(() => {
    const customEvent = new CustomEvent("userRequestsCounts", {
      detail: {
        type: "followCount",
        count: userFollowingRequests.data?.rows?.length,
      },
    });

    document.dispatchEvent(customEvent);
  }, [userFollowingRequests]);

  //search opration
  const [searchItem, setSearchItem] = useState("");
  const [userId,setUserId] = useState("");
  const [selectedRequest, setSelectedRequest] = useState(null);

  const filteredData = getUserFollowingRequests?.rows?.filter((item) =>
    item.fullName.toLowerCase().includes(searchItem.toLowerCase())
  );

  const dispatch = useDispatch();
  const [Gridview, Setgridview] = useState(false);

  useEffect(() => {
    dispatch(getUserFollowingRequests());
  }, []);

  const [active, setActive] = useState("1");

  const toggle = (tab) => {
    if (active !== tab) {
      setActive(tab);
    }
  };
  // ------------- State management for selecting Single card  -------------
  const [CheckOne, setCheckOne] = useState(false);

  // ------------- State management for selecting Multiple card  -------------
  const [Check, setCheck] = useState(false);

  // ------------- Selecting all cards(State toggle)  -------------
  const SelectAll = () => {
    if (CheckOne === true) {
      setCheckOne(false);
    }
    if (Check === false) {
      setCheck(!Check);
    }
  };

  // ------------- UnSelecting all cards(State toggle)  -------------
  const unSelectAll = () => {
    if (Check === true) {
      setCheck(!Check);
    } else if (CheckOne === true) {
      setCheckOne(!CheckOne);
    }
    setIsSelectActive(!isSelectActive);
  };

  // store the id
  const [acceptModalOpen, setAcceptModalOpen] = useState(false);

  const toggleAcceptModalOpen = ( request) => {
    setAcceptModalOpen((prevState) => !prevState);
    selectedRequest(request)
  };
  //console.log("userI dhcbdchbc====>",userId)

  // -------------Reject Modal State  -------------
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const toggleRejectModalOpen = (request) => {
     setRejectModalOpen((prevState) => !prevState);
     selectedRequest(request) 
  };

  // -------------Single Accept Modal State  -------------
  const [singAcceptModalOpen, setSingAcceptModalOpen] = useState(false);
  const toggleSingAcceptModalOpen = (request) => {
    setSingAcceptModalOpen((prevState) => !prevState);
    //selectedRequest(request)
  };

  // -------------Single Reject Modal State  -------------
  const [singRejectModalOpen, setSingRejectModalOpen] = useState(false);
  const toggleSingRejectModalOpen = (request) => {
    setSingRejectModalOpen((prevState) => !prevState);
    //selectedRequest(request)
  };

  // ------------- share Modal State -------------
  const [shareOpen, setShareOpen] = useState(false);
  const toggleShareOpen = () => {
    setShareOpen((prevState) => !prevState);
  };

  //  ------------- Start ReportedOption  State -------------
  const [reportOpen, setReportOpen] = useState(false);
  const toggleReport = (request) => {
    setReportOpen((preState) => !preState);
    setSelectedRequest(request)
  };
  const [isSelectActive, setIsSelectActive] = useState("");
  const [selectedShotz, setSelectedShotz] = useState([]);

  //copy user profile
  const handleCopy= (request)=>{
  }

  const [reportRequest, setReportRequest] = useState({ id: "" });
  const[reportConfirm,setReportConfirm] = useState(false)
      useEffect(()=>{
        if(reportConfirm){
              setTimeout(() => {
                dispatch(getUserFollowingRequests());
                setReportConfirm(false);
              }, 1000);
          } 
      },[reportConfirm])

  //get user block
  const [blockModalOpen, setBlockModalOpen] = useState(false)
  const toggleBlockModalOpen = () => {
    setBlockModalOpen((prevState) => !prevState);
  }
  const[blockConfirm,setBlockConfirm] = useState(false)
  useEffect(()=>{
    if(blockConfirm){
      setTimeout(() => {
        dispatch(getUserFollowingRequests());
        setBlockConfirm(false);
      }, 1000);
    } 
  },[blockConfirm])

  const GridViewSection = () => {
    return (
      <>
        <div className="nav-flex-container">
          <div>
            {!Check ? (
              <h3 className="connection-sub-heading">
                Requests ({userFollowingRequests.data?.rows?.length})
              </h3>
            ) : (
              <h3>20 Selected</h3>
            )}
          </div>
          <div className="sub-nav-flex size-sm-mt-4">
            <div className="profile-search-blk mr-2">
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                strokeWidth="2"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="search-svg"
              >
                <circle cx="11" cy="11" r="8" />
                <line x1="21" y1="21" x2="16.65" y2="16.65" />
              </svg>
              <input
                type="text"
                className="form-control"
                name="search"
                placeholder="Find ..."
                data-bs-toggle="modal"
                data-bs-target="#peopleSearch"
              />
            </div>

            {!isSelectActive ? (
              <div className="sub-nav-flex remove-btn-padding">
                <Button
                  className="mr-2"
                  onClick={() => {
                    setIsSelectActive(!isSelectActive);
                    setCheckOne(true);
                  }}
                  variant="outline-primary"
                >
                  Select
                </Button>
              </div>
            ) : (
              <div className="sub-nav-flex">
                <Button
                  variant="outline-primary"
                  className="mr-2"
                  onClick={Check ? unSelectAll : SelectAll}
                >
                  {Check ? "Unselect All" : "Select All"}
                </Button>
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    setIsSelectActive(!isSelectActive);
                    setSelectedShotz([]);
                    setCheckOne(false);
                    setCheck(false);
                  }}
                  className="mr-2"
                >
                  Cancel
                </Button>
              </div>
            )}
            <div className="list-grid-toggle size-xs-mt-4 rem-mob-view">
              <Button
                className={Gridview ? "grid-toggle-customize-css " : ""}
                outline
                onClick={() => Setgridview(false)}
              >
                <Grid size={16} />
              </Button>
              <Button
                className={Gridview ? " " : "grid-toggle-customize-css"}
                outline
                onClick={() => Setgridview(true)}
              >
                <List size={16} />
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {/* <FollowRequestHeader /> */}
      <Header />
      <div className="page-body container-fluid profile-page">
        <LeftSidebar />
        <div className="page-center">
          <Card>
            {/* comman header calling */}
            <CommanHeader />

            <CardBody>
              {/* connection tabs calling */}
              <ConnectionPlaceMenu />
              <div className="mppage-heading mt-4">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      active={active === "1"}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      <h3>Received</h3>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={active === "2"}
                      onClick={() => {
                        toggle("2");
                      }}
                    >
                      <h3>Sent</h3>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent className="py-50 mt-4" activeTab={active}>
                  <TabPane tabId="1">
                    <div className="group-custom-block">
                      <GridViewSection />
                      <div className="heading-podcast-blk">
                        {Check ? (
                          <>
                            <div className="d-flex align-items-center  justify-content-between">
                              <Button
                                variant="primary"
                                onClick={() => {
                                  toggleAcceptModalOpen(
                                    userFollowingRequests?.data?.rows?.userId
                                  );
                                }}
                              >
                                Accept All
                              </Button>
                              <Button
                                variant="danger"
                                className="ml-2"
                                color="danger"
                                onClick={() => {
                                  toggleRejectModalOpen(
                                    userFollowingRequests?.data?.rows?.userId
                                  );
                                }}
                              >
                                {" "}
                                Reject All
                              </Button>
                            </div>
                          </>
                        ) : null}
                        {CheckOne ? (
                          <>
                            <div className="d-flex align-items-center  justify-content-between">
                              <Button
                                variant="primary"
                                onClick={toggleAcceptModalOpen}
                              >
                                Accept Selected
                              </Button>
                              {/* Accept selected button functionality */}
                              <Button
                                variant="danger"
                                className="ml-2"
                                color="danger"
                                onClick={toggleRejectModalOpen}
                              >
                                {" "}
                                Reject Selected
                              </Button>
                              {/* Reject selected button functionality */}
                            </div>
                          </>
                        ) : null}
                      </div>
                      {Gridview ? (
                        <PendingRequest CheckOne={CheckOne} Check={Check} />
                      ) : (
                        <Row className="connections_PeopleThisGrid">
                          {userFollowingRequests &&
                            userFollowingRequests?.data?.rows
                              ?.slice(0, 15)
                              .map((request, index) => {
                                return (
                                  <Col lg="4" className="w-100 mt-2">
                                    <div
                                      key={index}
                                      className={
                                        Check || (CheckOne && index === 0)
                                          ? "box-selected content-mp-block d-flex align-items-center justify-content-between"
                                          : "box-shadow-style content-mp-block d-flex align-items-center justify-content-between"
                                      }
                                    >
                                      <div className="media-body d-md-block">
                                        <div class="media media-new d-flex align-items-start">
                                          <div class="user-img d-flex align-items-start popover___wrapper option-disable">
                                            <img
                                              src={request?.profileImage}
                                              className="rounded-circle "
                                              height={45}
                                              width={45}
                                              alt="shivam singh"
                                            />
                                            <span class="available-stats online"></span>
                                            <PrivateAccount request={request} />
                                          </div>
                                          <div class="media-body d-md-block pl-1">
                                            <Link to="/MyProfile">
                                              <h4 className="m-0 name-style d-inline-block text-truncate text-wrap">
                                                {request?.fullName?.substring(0,21) || "NA"}
                                              </h4>
                                            </Link>
                                              <div className="d-flex ml-1">
                                                <p className="user-name-style">
                                                  {request?.userName || "NA"} |
                                                </p>
                                                <span>
                                                  <img src={request?.countryData[0]?.flagURL} className='ml-1 rem-mob-view-small' alt='...' width={19} height={13} />
                                                </span>
                                              </div>
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between ml-2 mt-2 option-disable">
                                          <MutualFriendList />
                                        </div>
                                      </div>
                                      <div className=" d-flex flex-column justify-content-end align-items-end">
                                        {CheckOne || Check ? (
                                          <Checkbox
                                            checked={
                                              Check || (CheckOne && index === 0)
                                                ? true
                                                : null
                                            }
                                          />
                                        ) : (
                                          <div className="d-flex align-items-center option-disable">
                                            <span
                                              role="button"
                                              className="conn-btn"
                                              onClick={() => {
                                                toggleAcceptModalOpen(request);
                                              }}
                                            >
                                              <img
                                                src={accept}
                                                className="img-fluid"
                                                alt=""
                                                id="accept-sign"
                                              />
                                              <UncontrolledTooltip
                                                placement="top"
                                                target="accept-sign"
                                              >
                                                Accept
                                              </UncontrolledTooltip>
                                            </span>

                                            <span
                                              role="button"
                                              className="conn-btn mx-2"
                                              onClick={() => {
                                                toggleRejectModalOpen(request);
                                              }}
                                            >
                                              <img
                                                src={decline}
                                                className="img-fluid"
                                                alt=""
                                                id="reject-sign"
                                              />
                                              <UncontrolledTooltip
                                                placement="top"
                                                target="reject-sign"
                                                onClick={() => {
                                                  toggleRejectModalOpen(
                                                    request
                                                  );
                                                }}
                                              >
                                                Reject
                                              </UncontrolledTooltip>
                                            </span>
                                            <UncontrolledButtonDropdown
                                              className="d-flex align-items-start flex-column justify-content-end px-2 rem-mob-view-small"
                                              direction="left"
                                            >
                                              <DropdownToggle
                                                color="light"
                                                className="connection-cutomize-dropdown-toggle"
                                              >
                                                <MoreVertical size={22} />
                                              </DropdownToggle>
                                              <DropdownMenu className="Connection-dropdown">
                                                <DropdownItem
                                                  className="border-bottom py-2 menu-hover-style"
                                                  onClick={() => {
                                                    toggleSingAcceptModalOpen(
                                                      request
                                                    );
                                                  }}
                                                >
                                                  <CheckCircle
                                                    size={15}
                                                    className="mr-2"
                                                  />
                                                  Accept
                                                </DropdownItem>
                                                <DropdownItem
                                                  className="border-bottom py-2 menu-hover-style"
                                                  onClick={() => {
                                                    toggleSingRejectModalOpen(
                                                      request
                                                    );
                                                  }}
                                                >
                                                  <XCircle
                                                    size={15}
                                                    className="mr-2"
                                                  />
                                                  Decline
                                                </DropdownItem>
                                                <DropdownItem className="border-bottom py-2 menu-hover-style">
                                                  <Copy
                                                    size={15}
                                                    className="mr-2"
                                                  />
                                                  Copy Profile URL
                                                </DropdownItem>
                                                <DropdownItem
                                                  className="border-bottom py-2 menu-hover-style"
                                                  onClick={() => {
                                                      toggleBlockModalOpen();
                                                      setUserId(request.userId);
                                                  }}
                                                >
                                                  <MinusCircle
                                                    size={15}
                                                    className="mr-2 icon-color-style"
                                                  />
                                                  Block
                                                </DropdownItem>
                                                <DropdownItem
                                                  className="border-bottom py-2 menu-hover-style"
                                                  onClick={() => {
                                                    toggleReport();
                                                    setReportRequest({ id: request.userId });
                                                  }}
                                                >
                                                  <Flag
                                                    size={15}
                                                    className="mr-2"
                                                  />
                                                  Report User
                                                </DropdownItem>
                                                <DropdownItem className="py-2 menu-hover-style">
                                                  <Info
                                                    size={15}
                                                    className="mr-2"
                                                  />
                                                  Learn more
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                          </div>
                                        )}
                                        <div className="mt-3 d-flex align-items-end justify-content-end">
                                          <p className="align-self-end font-weight-bold text-right rem-mob-view-small">
                                            1 Day Ago
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                );
                              })}
                        </Row>
                      )}
                      <Row>
                        <Col xl="12" className="d-flex justify-content-center">
                          {userFollowingRequests?.data?.rows?.length === 0 && (
                            <h3 className="text-center connection-sub-heading">
                              No Requests Found
                            </h3>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <FollowReqestTab />
                  </TabPane>
                </TabContent>
              </div>
            </CardBody>
            <div className="d-flex justify-content-center my-3">
              <Stack spacing={2}>
                <Pagination count={5} color="primary" size="large" />
              </Stack>
            </div>
          </Card>
        </div>
        <RightSidebar />
      </div>

      { reportOpen && (
                <ConnectionReportModal
                    request={reportRequest}
                    modalOpen={reportOpen}
                    reportModalOpen={toggleReport}
                    setReportConfirm ={setReportConfirm}
                />
      )}
      {/* ------------------ Start Share Modal  ------------------*/}
      <ConnectionShareModal
        openModal={shareOpen}
        shareModalOpen={toggleShareOpen}
      />
      {/* -------------  Accept Modal  ------------- */}
      <AcceptModal
        request={selectedRequest}
        openModal={acceptModalOpen}
        openAcceptModal={toggleAcceptModalOpen}
      />

      {/* -------------  Reject modal Modal  ------------- */}
      <RejectModal
        request={selectedRequest}
        openModal={rejectModalOpen}
        rejectModalOpen={toggleRejectModalOpen}
      />
      {/* ------------- Single Accept Modal  ------------- */}
      <SingleAcceptModal
        //request={selectedRequest}
        openModal={singAcceptModalOpen}
        openSingleAcceptModal={toggleSingAcceptModalOpen}
      />
      {/* ------------- Single Reject modal Modal  ------------- */}
      <SingleRejectModal
        //request={selectedRequest}
        openModal={singRejectModalOpen}
        singleRejectModalOpen={toggleSingRejectModalOpen}
      />

      {/* block  */}
      { blockModalOpen && (
          <BlockModal
            request={userId}
            openModal={blockModalOpen}
            removeModalOpen={toggleBlockModalOpen}
            setBlockConfirm={setBlockConfirm}
          />
      )}
    </>
  );
};

export default FollowRequestsScreen;
