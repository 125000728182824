import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Modal, ModalBody } from 'reactstrap'
import Snackbar from '@mui/material/Snackbar';
import { addUserConnection } from '../../../Services/Actions/Common/getUserConnectionAction';
import { Plus } from 'react-feather';
import AddUserModal from '../ConnectionModal/AddUserModal';
import { Button } from 'react-bootstrap';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CreateCategoryModal = (props) => {
    // Snackbar Code

    const [open, setOpen] = useState(false);
    const [alert, setAlert] = useState({ sev: '', content: '' });
    const [state, setState] = useState({
        connectionName: "",
    });

    const [error, setError] = useState("");
    const {connectionName} = state;
    //console.log(state)

    //FOR STORE THE CATEGORY NAME
    const [name, setName] = useState("");

    let dispatch = useDispatch();
    const onChangeHandler = (e) => {
        let { name, value } = e.target;
        setState({ ...state, [name]: value });
    };

    // FILLED ERROR
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!connectionName) {
            setOpen(true);
            setAlert({ sev: "error", content: "Please Fill connectionName !", });
        }
        else {
            // Green Snackbar
            setOpen(true);
            setAlert({ sev: "success", content: "Added Successfully", });
            setError("");
            dispatch(addUserConnection(state));
            setError("");
        }
    };

    // Cancel Snackbar
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        if (alert.sev === 'success') {
            // navigate("/Admin/privacy-policy");
        }
    };

    //    ------------- Add Users Option ------------- }
    const [addUserOptions, setAddUserOptions] = useState(false)
    const toggleAddUserOptions = () => {
        setAddUserOptions((preState) => !preState)
        setName(name);
    }

    return (
        <>
            {/* ------------- Modal ------------- */}
            {
                props.OpenModal !== false &&
                <Modal isOpen={props.OpenModal} toggle={props.openCreateModal} className='modal-dialog-centered'>
                    <div className="modal-header">
                        <h3 className="modal-title" id="exampleModalLongTitle">Add Category Name</h3>
                        <div role="button" onClick={() => { props.openCreateModal(); setName(""); }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon-dark close-btn"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                        </div>
                    </div>
                    <ModalBody className='mt-2'>
                        <div className="ed-model-block-">
                            <form className="theme-form">
                                <div className="form-group">
                                    <input type="text" className="form-control" name="connectionName" placeholder="Category name" maxLength={25} onChange={(e)=>setName(e.target.value)} />
                                    <div className="col-lg-12 d-flex">
                                        <Stack spacing={2} className='w-100 d-flex' id="stack">
                                            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={1500} onClose={handleClose}>
                                                <Alert onClose={handleClose} severity={alert.sev} sx={{ width: '100%' }}>
                                                    {alert.content}
                                                </Alert>
                                            </Snackbar>
                                            <div className='d-flex w-100 justify-content-between mt-3'>
                                                {/* <Button variant='outline-primary' className='w-100 m-1' onClick={handleSubmit}>Save</Button> */}
                                                <Button 
                                                    variant='primary' 
                                                    className='border-0 w-100 m-1' 
                                                    onClick={() => { toggleAddUserOptions(name) }} 
                                                    disabled={!name} 
                                                    style={{ 
                                                        backgroundColor: name ? '' : '#E6F3DB', 
                                                        color: name ? '#fff' : '#000', 
                                                        cursor: name ? 'pointer' : 'not-allowed' 
                                                    }}
                                                    > 
                                                    <Plus size={20} /> Add SocioMate
                                                </Button>
                                            </div>
                                        </Stack>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </ModalBody>
                </Modal>
            }
            {/*  ------------- Add Users Option Modal ------------- */}
            <AddUserModal
                openModal={addUserOptions}
                openAddUserModal={toggleAddUserOptions}
                closeAdUserModal={props.openCreateModal}
                name={name}
                setName={setName}
            />

        </>
    )
}

export default CreateCategoryModal