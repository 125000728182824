import React, { useEffect, useState } from 'react';
import { ChevronRight, Copy, Flag, MoreVertical, Share, UserMinus, MinusCircle } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledButtonDropdown } from 'reactstrap';
import { loadAllUserFollowing } from '../../Services/Actions/Common/getUserFollowingAction';
import { loadAllFollowUnFollow } from '../../Services/Actions/Connection/getFollowUnFollowAction';
import india_svg from '../../NewComponents/IMG/india_svg.svg';
import BlockModal from './ConnectionModal/BlockModal';
import { NavLink } from 'react-router-dom';
import AddCategoryModal from './ConnectionModal/AddCategoryModal';
import ConnectionReportModal from './ConnectionModal/ConnectionReportModal';
import ConnectionShareModal from './ConnectionModal/ConnectionShareModal';
import UnfollowModal from './ConnectionModal/UnfollowModal';
import { Button } from 'react-bootstrap';
import PublicAccount from './ConnectionModal/PublicAccount';
import './Style.css'

const FollowingScreen = () => {
    // GET FOLLOWING DATA
    const { getUserFollowing } = useSelector(state => state.GetUserFollowingData)
    const [selectedRequest, setSelectedRequest] = useState(null);
    const dispatch = useDispatch();
    // search opration
    const [searchItem, setSearchItem] = useState("")
    const filteredData = getUserFollowing?.rows?.filter((item) =>
        item.fullName.toLowerCase().includes(searchItem.toLowerCase()));
        
    useEffect(() => {
        dispatch(loadAllUserFollowing())
    }, [searchItem])

    // ------------- share Modal State -------------U
    const [userId, setUserId] =useState("")
    const [shareOpen, setShareOpen] = useState(false)
    const toggleShareOpen = (id) => {
        setShareOpen((prevState) => !prevState)
        setUserId(id)
    }
    //  ------------- Start Add Category State -------------
    const [addCategoryOptions, setAddCategoryOptions] = useState(false)
    const toggleAddCategory = () => {
        setAddCategoryOptions((preState) => !preState)
    }
    // ------------- Unfollow Modal State -------------

    const [unfollowModalOpen, setUnfollowModalOpen] = useState(false);
    const toggleUnfollowModal = (request) => {
        setUnfollowModalOpen((prevState) => !prevState);
        setSelectedRequest(request);      
    };

    //copy prorofile url
    const copyProfileURL = (userId) => {
        
        const profileURL = window.location.host + `/user/profileinfo?id=${userId}`;
    
        navigator.clipboard.writeText(profileURL)
            .then(() => {
                console.log('Profile URL copied to clipboard:', profileURL);
                alert("copied profile url new ",profileURL);
            })
            .catch((error) => {
                console.error('Failed to copy profile URL:', error);
            });
    };

    //  ------------- Start Reported Option State  -------------
     // Reported Option State
     const [reportOpen, setReportOpen] = useState(false)
     const toggleReport = (request) => {
         setReportOpen((preState) => !preState)
         setSelectedRequest(request)      
     }

    const[reportConfirm,setReportConfirm] = useState(false)
    useEffect(()=>{
        if(reportConfirm){
                setTimeout(() =>{
                    dispatch(loadAllUserFollowing());
                    setReportConfirm(false);
                }, 1000);
           } 
    },[reportConfirm])

    //block
    const [blockModalOpen, setBlockModalOpen] = useState(false);
    const toggleBlockModalOpen = () => {
          setBlockModalOpen((prevState) => !prevState);
      };
    const[blockConfirm,setBlockConfirm] = useState(false)
    useEffect(()=>{
        if(blockConfirm){
          setTimeout(() => {
              dispatch(loadAllUserFollowing());
              setBlockConfirm(false);
          }, 1000);
        } 
    },[blockConfirm])

    return (
        <>
            <div className="mppage-heading">
                <div className="group-custom-block">
                    <div className="heading-podcast-blk">
                        <h3 className='connection-sub-heading'>I'm Following ({getUserFollowing?.rows?.length})</h3>
                        {getUserFollowing?.rows?.length === 0 ? null : (
                           <NavLink to='/FollowingScreen'>
                              <h5 className=''>View All <ChevronRight size={20} color='#81C14B' className='ml-1' /></h5>
                           </NavLink>
                        )}
                    </div>
                    <Row className='connections_PeopleThisGrid'>
                        {
                            filteredData && filteredData.slice(0, 6).map((request, index) => {
                                return <Col className='w-100' >
                                    <div key={index} className="connection-card box-shadow-style  content-mp-block d-flex justify-content-between" >
                                        <div className="media-body d-md-block">
                                            <div class="media media-new d-flex align-items-start">
                                                <div class="user-img d-flex align-items-start popover___wrapper " >
                                                    <img src={request?.profileImage}
                                                        className="rounded-circle " height={45} width={45} alt="shivam singh" />
                                                    <span class="available-stats online"></span>
                                                    <PublicAccount request={request} />
                                                </div>
                                                <div class="media-body d-md-block pl-1">
                                                    {/* <h4 className='m-0 name-style d-inline-block text-truncate' style={{ width: '120px' }}>{request.fullName?.slice(0, 15) || 'name'}</h4> */}
                                                    <NavLink to='/MyProfile' ><h4 className='m-0 name-style d-inline-block text-truncate text-wrap'>{request.fullName?.substring(0, 21) || 'NA'}</h4></NavLink>
                                                    <div className='d-flex my-1 text-wrap'>
                                                        <p className='user-name-style rem-mob-view-small'>{request.userName || 'NA'}  |  </p>
                                                        <span>
                                                            <img
                                                                src={request?.countryData[0]?.flagURL}
                                                                className="ml-1 rem-mob-view-small"
                                                                alt="..."
                                                                width={19}
                                                                height={13}
                                                            />
                                                        </span>
                                                    </div>
                                                    <p className='professional-style rem-mob-view-small text-wrap'>{request?.profession || "No Profession"}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-end flex-column'>
                                            <UncontrolledButtonDropdown className='d-flex align-items-start flex-column justify-content-end pr-0 rem-mob-view-small' direction='left'>
                                                <DropdownToggle color='light' className='connection-cutomize-dropdown-toggle pb-0 pr-0'>
                                                    <MoreVertical size={22} className='pr-0' />
                                                </DropdownToggle>
                                                <DropdownMenu className='Connection-dropdown'>
                                                    <DropdownItem onClick={() => toggleUnfollowModal(request)} className='border-bottom py-2 menu-hover-style'>
                                                        <UserMinus size={15} className='mr-2' />Unfollow
                                                    </DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style' onClick={toggleShareOpen}><Share size={15} className='mr-2' />Share Profile</DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style'><Copy size={15} className='mr-2' onClick={()=>copyProfileURL(request.id)}/>Copy Profile URL</DropdownItem>
                                                    <DropdownItem className='border-bottom py-2 menu-hover-style'  
                                                    onClick={()=>{
                                                            toggleBlockModalOpen();
                                                            setUserId(request.id);
                                                        }}
                                                    ><MinusCircle size={15} className='mr-2 icon-color-style' />Block</DropdownItem>
                                                    <DropdownItem className='py-2 menu-hover-style' onClick={()=>{toggleReport(request)}}><Flag size={15} className='mr-2' />Report User
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            <div role="button" className='mt-3 d-flex align-items-end' onClick={()=>{toggleUnfollowModal(request)}}>
                                                <Button color='flat-success' className='btn-transparent mt-1 pb-0 pr-0'>
                                                    <UserMinus size={15} color='#FF0000' />&nbsp;&nbsp;<p className='connection-red-text'>Unfollow</p>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                    <Row>
                        <Col xl='12' className='d-flex justify-content-center'>
                            {
                                getUserFollowing?.rows?.length.data?.rows?.length===0  && <h3 className='text-center connection-sub-heading'>No Requests Found</h3>
                            }
                        </Col>
                    </Row>
                </div>
            </div>

            {/*  ------------------ Add Category Status  ------------------ */}
            <AddCategoryModal
                openModal={addCategoryOptions}
                cateModalOpen={toggleAddCategory}
            />
            {/*  ------------------ Add Report Option  ------------------ */}
            <ConnectionReportModal
                request={selectedRequest}
                modalOpen={reportOpen}
                reportModalOpen={toggleReport}
                setReportConfirm ={setReportConfirm}
            />

            {/*------------------------- block modal----------------------- */}
            { blockModalOpen && (
                <BlockModal
                    request={userId}
                    openModal={blockModalOpen}
                    removeModalOpen={toggleBlockModalOpen}
                    setBlockConfirm={setBlockConfirm}
                />
            )}

            {/* ------------------ Start Share Modal  ------------------*/}
            <ConnectionShareModal
                openModal={shareOpen}
                shareModalOpen={toggleShareOpen}
            />
            {/* ------------- Unfollow Modal Modal  -------------*/}
            <UnfollowModal
                request={selectedRequest}
                openModal={unfollowModalOpen}
                unfollowModalOpen={toggleUnfollowModal}
            />
        </>
    )
}

export default FollowingScreen