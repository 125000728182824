import React, { Fragment, useState, useEffect } from "react";
import { ArrowLeft, MapPin } from "react-feather";
import { Col, Row } from "reactstrap";

const CheckIn = ({ postType,activeTab, toggle, onLocationSelect ,postEditValue}) => {
  // console.log("check in :select location",postEditValue)
  const [selectedLocation, setSelectedLocation] = useState({
    name: "",
    address: "",
    latitude: "",
    longitude: "",
  });
  const [searchQuery, setSearchQuery] = useState(postEditValue?.displayLocation || "");
  const [autocomplete, setAutocomplete] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const api_key="AIzaSyBSM_DBO4T0oZtxyWGlO65EQ3sA2EXVwZI";

  // Function to handle location selection
  const handleLocationSelect = (location,setSearchQuery) => {
    console.log(location,setSearchQuery,"locationselected")
    setSelectedLocation(location);
    onLocationSelect(location);
    setSearchQuery(location);
    
    // Close the modal or perform other actions as needed
    if(postType==="recomendation"){
      toggle(`/modal/${7.6}`);
    }else if(postType==="thought"){
      toggle(`/modal/${7.1}`);
    }else if(postType==="text"){
      toggle(`/modal/${0}`);
    }else if(postType==="alert"){
      toggle(`/modal/${7.7}`);
    }else if(postType==="event"){
      toggle(`/modal/${7.3}`);
    }else if(postType==="media"){
      toggle(`/modal/${0.1}`);
    }
   
  };

  const handleSearchClick = () => {
    if (autocomplete) {
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (!place.geometry) {
          alert("Place details not found for the input.");
          return;
        }

        // Handle the selected place here (you can set state, etc.)
        setSelectedLocation({
          name: place.name,
          address: place.formatted_address,
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
        });

        // Clear suggestions when a place is selected
        setSuggestions([]);
      });
    }
  };

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Fetch and update suggestions using Google Places AutocompleteService
    if (autocomplete && query) {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions({ input: query }, (predictions) => {
        if (predictions) {
          setSuggestions(predictions);
        } else {
          setSuggestions([]);
        }
      });
    } else {
      // Clear suggestions when the input is empty
      setSuggestions([]);
    }
  };

  useEffect(() => {
    // Load the Google Maps API script
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${api_key}&libraries=places&callback=initMap`;
    document.head.appendChild(script);

    script.onload = () => {
      // Initialize the Autocomplete once the Google Maps API is loaded
      const locationInput = document.getElementById("locationInput");
      const newAutocomplete = new window.google.maps.places.Autocomplete(
        locationInput,
        {
          types: ["geocode"],
        }
      );
      setAutocomplete(newAutocomplete);
    };
  }, []);

  return (
    <Fragment>
      <div className="border-bottom w-100">
        <div className={`mx-3 py-3 FellingActivity_main_blk `}>
          <div
            onClick={() =>{     if(postType==="recomendation"){
              toggle(`/modal/${7.6}`);
            }else if(postType==="thought"){
              toggle(`/modal/${7.1}`);
            }else if(postType==="text"){
              toggle(`/modal/${0}`);
            }else if(postType==='alert'){
              toggle(`/modal/${7.7}`)
            }  else if(postType==="event"){
              toggle(`/modal/${7.3}`);
            }else if(postType==="media"){
              toggle(`/modal/${0.1}`);
            }
            
            ;  setSelectedLocation({});
            onLocationSelect(""); }}
            style={{ cursor: "pointer" }}
            className="Arrow_main_blk_section rounded-circle"
          >
            <ArrowLeft />
          </div>
          <div>
            <h3>Search for location</h3>
          </div>
          <div></div>
        </div>
      </div>
      <div className="mx-3">
        <div className="right-setting pb-3 pt-3 ">
          <div className="profile-search-blk  w-100 ">
            <svg
              viewBox="0 0 24 24"
              width="24"
              height="24"
              stroke="currentColor"
              strokeWidth="2"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="search-svg"
            >
              <circle cx="11" cy="11" r="8"></circle>
              <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
            </svg>
            <input
              type="text"
              className="form-control Feeling-control"
              id="locationInput"
              name="search"
              placeholder="Where are you?"
              value={searchQuery}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      <div className="Feeling_scroll_box">
        <div className="mx-3">
          <Row>
            {suggestions.map((item, index) => (
              <Col sm={12} className="mb-1" key={item.place_id}>
                <div
                  className="Check_in_location_blk"
                  onClick={() => handleLocationSelect(item.description,setSearchQuery)} // Call the handleLocationSelect function when a location is clicked
                >
                  <div className="Map_icon_blk">
                    <MapPin color=" #81C14B" />
                  </div>
                  <h3 className="ml-3">{item.description}</h3>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
      {/* </div> */}
    </Fragment>
  );
};

export default CheckIn;

// import React, { Fragment, useState, useEffect } from "react";
// import { ArrowLeft, MapPin } from "react-feather";
// import { Col, Row } from "reactstrap";
// import { CheckInData } from "../DummyArray";

// const CheckIn = ({ activeTab, toggle, onLocationSelect }) => {
//   const [selectedLocation, setSelectedLocation] = useState("");
//   const [searchQuery, setSearchQuery] = useState(""); // Add searchQuery state

//   const [autocomplete, setAutocomplete] = useState(null);
//   // Filter the locations based on the searchQuery
//   const filteredLocations = CheckInData.filter((item) =>
//     item.location.toLowerCase().includes(searchQuery.toLowerCase())
//   );

//   const handleSearchClick = () => {
//     if (autocomplete) {
//       autocomplete.addListener("place_changed", () => {
//         const place = autocomplete.getPlace();
//         if (!place.geometry) {
//           alert("Place details not found for the input.");
//           return;
//         }

//         // Handle the selected place here (you can set state, etc.)
//         setSelectedLocation({
//           name: place.name,
//           address: place.formatted_address,
//           latitude: place.geometry.location.lat(),
//           longitude: place.geometry.location.lng(),
//         });
//       });
//     }
//   };

//   // Function to handle location selection
//   const handleLocationSelect = (location) => {
//     setSelectedLocation(location);
//     onLocationSelect(location);
//     // Close the modal or perform other actions as needed
//     toggle(`/modal/${99}`);
//   };

//   useEffect(() => {
//     // Load the Google Maps API script
//     const script = document.createElement("script");
//     script.async = true;
//     script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBSM_DBO4T0oZtxyWGlO65EQ3sA2EXVwZI&libraries=places&callback=initMap`;
//     document.head.appendChild(script);

//     script.onload = () => {
//       // Initialize the Autocomplete once the Google Maps API is loaded
//       const locationInput = document.getElementById("locationInput");
//       const newAutocomplete = new window.google.maps.places.Autocomplete(
//         locationInput,
//         {
//           types: ["geocode"],
//         }
//       );
//       setAutocomplete(newAutocomplete);
//     };
//   }, []);
//   useEffect(() => {
//     if (autocomplete) {
//       autocomplete.addListener("place_changed", () => {
//         const place = autocomplete.getPlace();
//         if (!place.geometry) {
//           alert("Place details not found for the input.");
//           return;
//         }

//         // Handle the selected place here (you can set state, etc.)
//         setSelectedLocation({
//           name: place.name,
//           address: place.formatted_address,
//           latitude: place.geometry.location.lat(),
//           longitude: place.geometry.location.lng(),
//         });
//       });
//     }
//   }, [autocomplete]);

//   return (
//     <Fragment>
//       <div className="border-bottom w-100">
//         <div className={`mx-3 py-3 FellingActivity_main_blk `}>
//           <div
//             onClick={() => toggle(`/modal/${99}`)}
//             style={{ cursor: "pointer" }}
//             className="Arrow_main_blk_section rounded-circle"
//           >
//             <ArrowLeft />
//           </div>
//           <div>
//             <h3>Search for location</h3>
//           </div>
//           <div></div>
//         </div>
//       </div>
//       <div className="mx-3">
//         <div className="right-setting pb-3 pt-3 ">
//           <div className="profile-search-blk  w-100 ">
//             <svg
//               viewBox="0 0 24 24"
//               width="24"
//               height="24"
//               stroke="currentColor"
//               strokeWidth="2"
//               fill="none"
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               className="search-svg"
//             >
//               <circle cx="11" cy="11" r="8"></circle>
//               <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
//             </svg>
//             <input
//               type="text"
//               className="form-control Feeling-control"
//               id="locationInput"
//               name="search"
//               placeholder="Where are you?"
//               data-bs-toggle="modal"
//               data-bs-target="#peopleSearch"
//               value={searchQuery}
//               onChange={(e) => setSearchQuery(e.target.value)} // Update the searchQuery state
//             />
//             <button onClick={handleSearchClick}>Search</button>
//             <div>
//               <strong>Name:</strong> {selectedLocation.name}
//               <br />
//               <strong>Address:</strong> {selectedLocation.address}
//               <br />
//               <strong>Latitude:</strong> {selectedLocation.latitude}
//               <br />
//               <strong>Longitude:</strong> {selectedLocation.longitude}
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* <div className='Feeling_scroll_box'>
//         <div className='mx-3'>
//           <Row>
//             {filteredLocations.map((item, index) => (
//               <Col sm={12} className="mb-1" key={index}>
//                 <div
//                   className='Check_in_location_blk'
//                   onClick={() => handleLocationSelect(item.location)} // Call the handleLocationSelect function when a location is clicked
//                 >
//                   <div className='Map_icon_blk'>
//                     <MapPin color=' #81C14B' />
//                   </div>
//                   <h3 className='ml-3'>{item.location}</h3>
//                 </div>
//               </Col>
//             ))}
//           </Row>
//         </div>
//       </div> */}
//     </Fragment>
//   );
// };

// export default CheckIn;
