import React, { useEffect, useState, useRef, Fragment } from 'react';
import { Checkbox } from '@mui/material';
import { Sliders } from 'react-feather';
import { Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, Row, UncontrolledButtonDropdown } from 'reactstrap';
import axios from 'axios';
import "../biz.css";
import { sweetAlertConfirmation } from '../../group-components/AllGroupsSections/SweetAlert'
import { SweetErrorAlert } from '../../group-components/AllGroupsSections/SweetErrorAlert';


const InviteModal = (props) => {

    const [usersList, setUsersList] = useState([]);
    const [usersCount, setUsersCount] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [searchKey, setSearchKey] = useState("");
    const debounceTimeout = useRef(null);
    const [checkboxes, setCheckboxes] = useState([]);
    const [selectedUserIds, setSelectedUserIds] = useState([]);

    const fetchUsers = async (searchValue) => {
        try {
            const body = {
                searchKey: searchValue,
                pageIndex: pageIndex,
                pageSize: 15,
                sortBy: "fullName",
                filteredBy: "All"
            };
            let user = JSON.parse(localStorage.getItem("sociomeeUser"));
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                },
            };
            const response = await axios.post(
                `${process.env.REACT_APP_IPURL}/hamBurgerMenu/getAllConnectionList`,
                body,
                config
            );
            if (response?.data?.data?.successResult) {
                const userData = response.data.data.successResult.data.rows;
                setUsersList(userData);
                setUsersCount(response.data.data.successResult.data.count);
                setCheckboxes(new Array(userData.length).fill(false)); // Initialize checkboxes for dynamic data
            }
        } catch (error) {
            console.log("Error getting people listing:", error);
        }
    };

    useEffect(() => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        debounceTimeout.current = setTimeout(() => {
            fetchUsers(searchKey);
        }, 200); // 200 ms debounce delay
        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, [searchKey, pageIndex]);

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchKey(value);
    };

    const toggleCheckbox = (index) => {
        const updatedCheckboxes = [...checkboxes];
        updatedCheckboxes[index] = !updatedCheckboxes[index];
        setCheckboxes(updatedCheckboxes);

        const selectedIds = updatedCheckboxes
            .map((checked, i) => (checked ? usersList[i].id : null))
            .filter(id => id !== null);

        setSelectedUserIds(selectedIds);
    };

    const toggleSelectAll = () => {
        const allSelected = checkboxes.every(checkbox => checkbox);
        const updatedCheckboxes = checkboxes.map(() => !allSelected);
        setCheckboxes(updatedCheckboxes);

        const selectedIds = updatedCheckboxes
            .map((checked, i) => (checked ? usersList[i].id : null))
            .filter(id => id !== null);

        setSelectedUserIds(selectedIds);
    };

 // ===================>>>section for the invite people api<<<<========================
    const invitePeoplesEvent = async () => {
        try { 
            if (selectedUserIds.length > 0) {
                let user = JSON.parse(localStorage.getItem("sociomeeUser"));
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${user?.token}`,
                    },
                };
                const body = {
                    inviteTo: selectedUserIds,
                    eventId: props?.eventId
                };
                const response = await axios.post(
                    `${process.env.REACT_APP_IPURL}/post/inviteFriendsToEvent`,
                    body,
                    config
                );
                if (response.data.data.successResult) {
                    sweetAlertConfirmation("Invited Successfully")
                    setCheckboxes([])
                    setSelectedUserIds([])
                }
                if (response.data.data.errorResult) {
                    console.log(response?.data?.data?.errorResult)
                    SweetErrorAlert(response?.data?.data?.errorResult)
                    setCheckboxes([])
                    setSelectedUserIds([])
                }
          }    

        } catch (error) {
            console.log("Error submitting selected users:", error);
        }

    }


    // =================submit(save) button style======================
    // (handling if user will not select any  user so button will not clickable)
        const buttonStyle = {
            cursor: selectedUserIds.length > 0 ? "pointer" : "not-allowed",
            opacity: selectedUserIds.length > 0 ? 1 : 0.5
        };

    // ===========================>>> main function <<<=================================

    return (
        <Fragment>
            {props.isOpen !== false && (
                <Modal isOpen={props.isOpen} className='modal-dialog-centered invitemodal' toggle={props.updateOpen}>
                    <div className='w-100 border-bottom'>
                        <div className='mx-3 py-3 d-flex justify-content-between Heading_Invite_Section'>
                            <h4>Invite People</h4>
                            <span   style={buttonStyle} onClick={() => { props.updateOpen(); invitePeoplesEvent(); }}>Done</span>
                        </div>
                    </div>

                    <ModalBody>
                        <Row className="mt-2">
                            <Col md="8" className='Like_search'>
                                <div className="profile-search-blk w-100 ">
                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="search-svg">
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                    </svg>
                                    <input type="search" className="form-control invitrinput" name="search1" placeholder="Search..." value={searchKey} onChange={handleSearchChange} />
                                </div>
                            </Col>
                            <Col md="4" className="d-flex justify-content-end filterinvite">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle color='primary' outline className="border border-muted ml-3">
                                        <Sliders className='Slider_Filter' size={12} />&nbsp;&nbsp;Filter
                                    </DropdownToggle>
                                    <DropdownMenu className="DropDown_Share_item droup">
                                        <DropdownItem>SocioMate</DropdownItem>
                                        <DropdownItem>Following</DropdownItem>
                                        <DropdownItem>Colleagues</DropdownItem>
                                        <DropdownItem>Family</DropdownItem>
                                        <DropdownItem>Relatives</DropdownItem>
                                        <DropdownItem>Work</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </Col>
                            <Col md="6" className="SubHeading_Invite_Section">
                                <h4 className='invitesocio' style={{ position: "absolute", top: "4px" }}>Count ({usersCount || 0})</h4>
                            </Col>
                            <Col md="6" className="d-flex justify-content-end SubHeading_Invite_Section">
                                <p className="inviteallsocio" style={{ cursor: 'pointer' }} onClick={toggleSelectAll}>Invite all</p>
                            </Col>
                        </Row>
                        <Row className="mt-3 scrollForNotification">
                            {usersList.length > 0 ? (
                                usersList.map((user, index) => (
                                    <Fragment key={user.id || index}>
                                        <div className="group-detail-page-blk">
                                            <div className="Follower-memberslist-block">
                                                <div className="group-member-img-cont-blk">
                                                    <img src={user?.profileImage} alt="img" className='img-fluid imginvite' />
                                                    <div className="member-cont-blk">
                                                        <h4 className='inviteheading'>{user?.fullName}</h4>
                                                        <p className='text-muted'>{user?.userName}</p>
                                                        <div className="Post_Count">
                                                            {/* {data.status} */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='Block-btns-blk'>
                                                    <Checkbox
                                                        checked={checkboxes[index]}
                                                        onChange={() => toggleCheckbox(index)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>
                                ))
                            ) : (
                                <Col>
                                    <center><h3>No user list available</h3></center>
                                </Col>
                            )}
                        </Row>
                    </ModalBody>
                </Modal>
            )}
        </Fragment>
    );
};

export default InviteModal;
