import Happy from './Img/happy.svg'
import Loved from './Img/loved.svg'
import Sad from './Img/sad.svg'
import Excited from './Img/excited.svg'
import Celeb from './Img/celebration.svg'
import Eat from './Img/eat.svg'
import Drink from './Img/drink.svg'
import Watch from './Img/watch.svg'
import Frnd from './Img/frndship.svg'
import Brth from './Img/birthday.svg'
import Spcl from './Img/spcl.svg'
import Chrms from './Img/chrms.svg'
import { Globe, Lock, User, Users } from 'react-feather'
import PenIcon from "../../NewComponents/IMG/PenIcon.svg"
import Throught from '../../NewComponents/IMG/ThroughtIcon.svg'
import GoLive from '../../NewComponents/IMG/GoLiveIcon.svg'
import Podcast from '../../NewComponents/IMG/PodcastIconS.svg'
import Articles from '../../NewComponents/IMG/ArticleIcon.svg'
import Recommendation from '../../NewComponents/IMG/RecommendationIcon.svg'
import Alert from '../../NewComponents/IMG/ThreatIcon.svg'
import Poll from '../../NewComponents/IMG/BPollIcon.svg'
import Sell from '../../NewComponents/IMG/SellIcon.svg'
import Event from '../../NewComponents/IMG/EventIcon.svg'
import UserImg from '../../NewComponents/IMG/img.svg'
import usr1 from './Img/usr1.svg'
import usr2 from './Img/usr2.svg'
import usr3 from './Img/usr3.svg'

export const PublicDropItem = [
    {
        id: 2,
        privacy: "Public",
        desp: "Anyone on or off Sociomee",
        icon: <Globe color=' #808080' size={16} />,
    },
    {
        id: 3,
        privacy: "Friends",
        desp: "Your Friends on Sociomee",
        icon: <Users color=' #808080' size={16} />,
    },
    {
        id: 4,
        privacy: "Friends Except",
        desp: "Don't show to some Sociomee Friends",
        icon: <Users color=' #808080' size={16} />,
    },
    {
        id: 5,
        privacy: "Specific Friends",
        desp: "Only show to some Sociomate",
        icon: <User color=' #808080' size={16} />,
    },
    {
        id: 6,
        privacy: "Only Me",
        icon: <Lock color=' #808080' size={16} />,
    }
]
export const FellingData = [
    {
        id: "1",
        img: Sad,
        name: "Happy"
    },
    {
        id: "2",
        img: Loved,
        name: "Loved"
    },
    {
        id: "3",
        img: Happy,
        name: "Sad"
    },
    {
        id: "4",
        img: Excited,
        name: "Excited"
    },
    {
        id: "5",
        img: Sad,
        name: "Happy"
    },
    {
        id: "6",
        img: Loved,
        name: "Loved"
    },
    {
        id: "7",
        img: Happy,
        name: "Sad"
    },
    {
        id: "8",
        img: Excited,
        name: "Excited"
    },
    {
        id: "9",
        img: Sad,
        name: "Happy"
    },
    {
        id: "10",
        img: Loved,
        name: "Loved"
    },
    {
        id: "11",
        img: Happy,
        name: "Sad"
    },
    {
        id: "12",
        img: Excited,
        name: "Excited"
    },
    {
        id: "13",
        img: Sad,
        name: "Happy"
    },
    {
        id: "14",
        img: Loved,
        name: "Loved"
    },
    {
        id: "15",
        img: Happy,
        name: "Sad"
    },
    {
        id: "16",
        img: Excited,
        name: "Excited"
    }

]

export const ActivityData = [
    {
        id: "1",
        name: "Celebrating",
        img: Celeb
    },
    {
        id: "2",
        name: "Eating",
        img: Eat
    },
    {
        id: "3",
        name: "Drinking",
        img: Drink
    },
    {
        id: "4",
        name: "Watching",
        img: Watch
    },
]

export const CelebratingData = [
    {
        name: "Friendship",
        img: Frnd
    },
    {
        name: "Birthday",
        img: Brth
    },
    {
        name: "Friendship",
        img: Frnd
    },
    {
        name: "Your special day",
        img: Spcl
    },
    {
        name: "Christmas",
        img: Chrms
    },
    {
        name: "Friendship",
        img: Frnd
    },
    {
        name: "Birthday",
        img: Brth
    },
    {
        name: "Friendship",
        img: Frnd
    },
    {
        name: "Your special day",
        img: Spcl
    },
    {
        name: "Christmas",
        img: Chrms
    },
]

export const AllPostTypeData = [
    {
        id: '0',
        img: PenIcon,
        postType: 'Text',
        tab: true
    },
    {
        id: '7.1',
        img: Throught,
        postType: 'Thought',
        tab: true

    },
    {
        id: '7.2',
        img: GoLive,
        postType: 'GoLive',
        tab: false
    },
    {
        id: '7.3',
        img: Event,
        postType: 'Event',
        tab: true
    },
    {
        id: '7.4',
        img: Podcast,
        postType: 'Podcast',
        tab: false
    },
    {
        id: '7.5',
        img: Articles,
        postType: 'Articles',
        tab: false
    },
    {
        id: '7.6',
        img: Recommendation,
        postType: 'Recommendation',
        tab: true
    },
    {
        id: '7.7',
        img: Alert,
        postType: 'Alert',
        tab: true
    },
    {
        id: '7.8',
        img: Poll,
        postType: 'Poll',
        tab: true
    },
    {
        id: '7.9',
        img: Sell,
        postType: 'Sell',
        tab: false
    },
]

export const TagSuggestData = [
    {
        img: usr1,
        name: "Esther Howard",
        userType: "SocioMate"
    },
    {
        img: usr2,
        name: "Savannah Nguyen",
        userType: "Follower "
    },
    {
        img: usr3,
        name: "Courtney Henry",
        userType: "Following "
    },
    {
        img: usr1,
        name: "Esther Howard",
        userType: "SocioMate"
    },
    {
        img: usr1,
        name: "Esther Howard",
        userType: "SocioMate"
    },
    {
        img: usr2,
        name: "Savannah Nguyen",
        userType: "Follower "
    },
    {
        img: usr3,
        name: "Courtney Henry",
        userType: "Following "
    },
    {
        img: usr1,
        name: "Esther Howard",
        userType: "SocioMate"
    }
]


export const HashTagData = [
    {
        hashName: "#NewDay",
        usedPpl: "used in 254 post"
    },
    {
        hashName: "#NewMe",
        usedPpl: "used in 250 post"
    },
    {
        hashName: "#LoveMe",
        usedPpl: "used in 112 post"
    },
    {
        hashName: "#EarthQuake",
        usedPpl: "used in 242 post"
    },
    {
        hashName: "#HateYou",
        usedPpl: "used in 214 post"
    },
    {
        hashName: "#DodoMee",
        usedPpl: "used in 444 post"
    },
    {
        hashName: "#NewDay",
        usedPpl: "used in 254 post"
    },
    {
        hashName: "#NewMe",
        usedPpl: "used in 250 post"
    },
    {
        hashName: "#LoveMe",
        usedPpl: "used in 112 post"
    },
    {
        hashName: "#EarthQuake",
        usedPpl: "used in 242 post"
    },
    {
        hashName: "#HateYou",
        usedPpl: "used in 214 post"
    },
    {
        hashName: "#DodoMee",
        usedPpl: "used in 444 post"
    }
]

export const CheckInData = [
    {
        location: "8502 Preston Rd. Inglewood, Maine 98380"
    },
    {
        location: "2715 Ash Dr. San Jose, South Dakota 83475"
    },
    {
        location: "2972 Westheimer Rd. Santa Ana, Illinois 85486 "
    },
    {
        location: "4140 Parker Rd. Allentown, New Mexico 31134"
    },
    {
        location: "3517 W. Gray St. Utica, Pennsylvania 57867"
    },
    {
        location: "3891 Ranchview Dr. Richardson, California 62639"
    },
    {
        location: "8502 Preston Rd. Inglewood, Maine 98380"
    },
    {
        location: "2715 Ash Dr. San Jose, South Dakota 83475"
    },
    {
        location: "2972 Westheimer Rd. Santa Ana, Illinois 85486 "
    },
    {
        location: "4140 Parker Rd. Allentown, New Mexico 31134"
    },
    {
        location: "3517 W. Gray St. Utica, Pennsylvania 57867"
    },
    {
        location: "3891 Ranchview Dr. Richardson, California 62639"
    }
]

export const ExceptFrndData = [

    {
        img: usr1,
        name: "Esther Howard",
        userType: "SocioMate"
    },
    {
        img: usr2,
        name: "Savannah Nguyen",
        userType: "Follower "
    },
    {
        img: usr3,
        name: "Courtney Henry",
        userType: "Following "
    },
    {
        img: usr1,
        name: "Esther Howard",
        userType: "SocioMate"
    },
    {
        img: usr1,
        name: "Esther Howard",
        userType: "SocioMate"
    },
    {
        img: usr2,
        name: "Savannah Nguyen",
        userType: "Follower "
    },
    {
        img: usr3,
        name: "Courtney Henry",
        userType: "Following "
    },
    {
        img: usr1,
        name: "Esther Howard",
        userType: "SocioMate"
    }

]


export const TextColor = [
    {
        id: '1',
        text: '#FB6E6E'
    },
    {
        id: '2',
        text: '#A8FFAC'
    },
    {
        id: '3',
        text: '#7DC1FF'
    },
    {
        id: '4',
        text: '#A49494'
    },
    {
        id: '5',
        text: '#FD98FF'
    },
    {
        id: '6',
        text: '#F8DF88'
    },
    {
        id: '7',
        text: '#60715C'
    },
    {
        id: '8',
        text: '#353535'
    },
    {
        id: '9',
        text: '#FFFFFF'
    },
    {
        id: '10',
        text: '#FFA800'
    },
    {
        id: '11',
        text: '#622727'
    },
    {
        id: '12',
        text: '#91BC81'
    },
    {
        id: '13',
        text: '#50466C'
    },

]

export const TextColorList=[
    {
        "colorHexCode": "#008B8B",
        "id": "0a52ddd2-14a8-4258-9dc1-11886ffeff8a"
    },
    {
        "colorHexCode": "#FFFFFF",
        "id": "0a53ddd3-14a8-4258-9dc1-11386ffeff8a"
    },
    {
        "colorHexCode": "#FF4500",
        "id": "1336603d-faaf-44f5-a062-42b590822ec6"
    },
    {
        "colorHexCode": "#32CD32",
        "id": "153778f7-52f0-48bc-90b5-027954427157"
    },
    {
        "colorHexCode": "#1E90FF",
        "id": "17d5dad8-6dcf-452d-bf17-925897d7bbe9"
    },
    {
        "colorHexCode": "#9400D3",
        "id": "18907934-fbe4-4189-858f-8f913bdfee2e"
    },
    {
        "colorHexCode": "#7B68EE",
        "id": "2407db92-16fe-4718-a42c-d00e0a0ce6fc"
    },
    {
        "colorHexCode": "#9ACD32",
        "id": "2fda0144-a54b-4f6d-aff3-428af654b963"
    },
    {
        "colorHexCode": "#4169E1",
        "id": "51337b44-b1d9-4f83-bc3a-6018a5dc6058"
    },
    {
        "colorHexCode": "#C71585",
        "id": "52c8be93-4873-4219-816d-9dcf07f10e14"
    },
    {
        "colorHexCode": "#000080",
        "id": "542de05d-135c-4aba-8a6f-a59b28a09e72"
    },
    {
        "colorHexCode": "#00CED1",
        "id": "5a8bdb90-8554-4512-a795-98ba417f4051"
    },
    {
        "colorHexCode": "#2F4F4F",
        "id": "5a8c93b2-3e4e-47ed-842e-7eba48458e51"
    },
    {
        "colorHexCode": "#FFD700",
        "id": "5d0da2a1-7a59-482a-b1a5-04dc4f5b0ae1"
    },
    {
        "colorHexCode": "#708090",
        "id": "665eb618-5985-4f81-93e7-7d7fdc5feb29"
    },
    {
        "colorHexCode": "#DAA520",
        "id": "688c6f6b-fc12-4754-8ce3-91f895942b63"
    },
    {
        "colorHexCode": "#4682B4",
        "id": "6b4101b8-cada-4159-a031-23b05e0a8548"
    },
    {
        "colorHexCode": "#006400",
        "id": "7154227a-2ed0-4363-a1f3-364b5693dab0"
    },
    {
        "colorHexCode": "#FFA500",
        "id": "73c73dd9-7b6f-4929-be84-438e346200b6"
    },
    {
        "colorHexCode": "#FF1493",
        "id": "79ddee98-bad0-4b2f-b878-88dfb4265a11"
    },
    {
        "colorHexCode": "#8B4513",
        "id": "7b31120d-d981-44bf-962e-5c0dd32c2456"
    },
    {
        "colorHexCode": "#BDB76B",
        "id": "7e6f60cb-a377-49e9-8d78-b2ef0d7929b9"
    },
    {
        "colorHexCode": "#800080",
        "id": "866906df-725a-42d1-9742-b3c58523f9e6"
    },
    {
        "colorHexCode": "#FF00FF",
        "id": "90d50242-8f48-4460-b0ab-a554030d1354"
    },
    {
        "colorHexCode": "#696969",
        "id": "9eb9be58-3836-41c9-977b-f91e57f7dcae"
    },
    {
        "colorHexCode": "#BC8F8F",
        "id": "a865c57d-855a-47ed-b2e0-4e72c6c7e1ce"
    },
    {
        "colorHexCode": "#CD853F",
        "id": "aed4a1c9-929b-48a4-a70a-5e2bc1ee6acf"
    },
    {
        "colorHexCode": "#6B8E23",
        "id": "f76210ca-a6cc-437e-acb7-36ead424dba1"
    },
    {
        "colorHexCode": "#8A2BE2",
        "id": "fa854ac9-0ba3-4e8f-8c57-b69171a63f58"
    },
    {
        "colorHexCode": "#808000",
        "id": "fb43b1b6-5aba-4c03-8043-82686f976a78"
    }
]